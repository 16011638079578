import React                                             from 'react';
import {withStyles}                                      from '@material-ui/core/styles';
import AroaParentComponent                               from "../../../AroaParentComponent";
import Typography                                        from "@material-ui/core/es/Typography";
import {Button, Grid, Select} from "@material-ui/core";
import Notifier, {showNotify}                            from "../../../../atoms/AroaNotifier";
import Paper                                             from "@material-ui/core/es/Paper";
import FormControl                                       from "@material-ui/core/es/FormControl";
import TextField                                         from "@material-ui/core/TextField";
import {__ADD_DSP_TO_USER, __RETRIEVE_DSP_PLATFORM_LIST} from '../../../../services/endpoint-manager';
import sessionStorage, {__CREATE_ADD_DSP_DSPID_FIELD}    from '../../../../services/storage-manager';
import {__ACTION_BUTTON, __SECONDARY_BUTTON} from "../../../../atoms/ButtonsStyles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme => ({
	paper               : {
		marginTop    : theme.spacing(1) * 8,
		display      : 'flex',
		flexDirection: 'column',
		maxWidth     : 350,
		alignItems   : 'center',
		padding      : `${theme.spacing(1) * 2}px ${theme.spacing(1) * 3}px ${theme.spacing(1) * 3}px`,
	},
	form                : {
		width    : 250, // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit              : {
		marginTop: theme.spacing(1) * 3,
	},
	aroaShortButton     : {
		backgroundColor: "#9CAFC3",
		color          : "#FFFFFF",
		width          : 180
	},
	aroaShortClearButton: {
		color: "#FFFFFF",
		width: 180
	}
});

const ColorButton = withStyles(() => (__ACTION_BUTTON))(Button);

const SecondaryButton = withStyles(() => (__SECONDARY_BUTTON))(Button);

class AddDspStep1Form extends AroaParentComponent
{
	constructor(props)
	{
		super(props);
		this.state           = {
			dspPlatforms: [],
			seatName    : "",
			seatID      : "",
			platformID  : -1,
			active      : 1
		};
		this.getDSPPlatforms = this.getDSPPlatforms.bind(this);
		this.handleChange    = this.handleChange.bind(this);
		this.handleSubmit    = this.handleSubmit.bind(this);
		this.clearForm    = this.clearForm.bind(this);
	}
	
	getDSPPlatforms()
	{
		let _self = this;
		this.getXHRHandler().launchGet(__RETRIEVE_DSP_PLATFORM_LIST, (response) => {
			_self.setState({dspPlatforms: this.getXHRHandler().getSuccessData(response)});
		}, (error) => {
			showNotify({message: this.getXHRHandler().getErrorDescription(error)});
		});
	}
	
	componentDidMount()
	{
		this.getDSPPlatforms();
	}
	
	componentWillUnmount()
	{
		this.state = {
			dspPlatforms: [],
			seatName    : "",
			seatID      : "",
			platformID  : -1,
			active      : 1,
		};
	}
	
	clearForm()
	{
		this.setState({
			seatName    : "",
			seatID      : "",
			platformID  : -1,
			active      : 1,
		});
	}
	
	handleChange(event)
	{
		this.setState({[event.target.name]: event.target.value});
	}
	
	handleSubmit(event)
	{
		event.preventDefault();
		let _selfDspID    = this;
		this.setState({active: this.state.active === 1 ? this.state.active : 0});
		if(this.state.seatName && this.state.seatID && (this.state.platformID > 0)){
			this.getXHRHandler().launchPost(__ADD_DSP_TO_USER, this.state, (response) => {
				showNotify({message: this.getXHRHandler().getSuccessMessage(response)});
				_selfDspID.setState({dspid: this.getXHRHandler().getSuccessData(response)});
				sessionStorage.setValue(__CREATE_ADD_DSP_DSPID_FIELD, this.state.dspid.dspid);
				this.gotoPath('/createdsp/step2');
			}, (error) => {
				showNotify({message: this.getXHRHandler().getErrorDescription(error)});
			})
		}else{
			showNotify({message: "Please make sure you have fill all required fields"});
		}
	}
	
	render()
	{
		const {dspPlatforms} = this.state;
		
		return (
			<React.Fragment>
				<Grid container justify={"center"}>
					<Notifier/>
					<form onSubmit={this.handleSubmit}>
						<Grid item>
							<Grid className={"margin-top-30"} style={{textAlign: "center"}}>
								<Paper elevation={0}>
									<Grid className="margin-bottom-30 margin-left-15">
										<Typography className="welcome-title text--align--left">
											Start doing a Dsp list
										</Typography>
									</Grid>
									<Grid container className="margin-bottom-30 margin-left-15">
										<FormControl variant="outlined" required>
											<Select value={this.state.platformID}
													input={<OutlinedInput labelWidth={0}/>}
													onChange={this.handleChange}
													name="platformID"
													id="platformID"
													inputProps={{
														name: 'platformID',
														id  : 'platformID',
													}}
											>
												<MenuItem disabled value={-1}>Select DSP Platform</MenuItem>
												{ dspPlatforms.map((currentPlatform) =>
													<MenuItem value={currentPlatform.dspplatformid} key={currentPlatform.dspplatformid}>
														{currentPlatform.platformname}
													</MenuItem>
												)}
											</Select>
										</FormControl>
									</Grid>
									<Grid container>
										<Grid className="margin-5 margin-left-20">
											<FormControl required>
												<TextField
													id="seatName"
													name="seatName"
													type={"text"}
													onChange={this.handleChange}
													label="Seat name"
													autoComplete="seatName"
													autoFocus
													className="textfield-Create"
													placeholder={"Seat name"}
												/>
											</FormControl>
										</Grid>
										<Grid className="margin-5 margin-left-20">
											<FormControl required>
												<TextField
													id="seatID"
													name="seatID"
													type={"number"}
													onChange={this.handleChange}
													label="Seat ID"
													autoComplete="seatID"
													className="textfield-Create"
													placeholder={"Seat ID"}
													onInput={(e)=>{
														e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
													}}
												/>
											</FormControl>
										</Grid>
									</Grid>
									<Grid className="margin-top-50 flex flex--end">
										<SecondaryButton
											type="reset"
											onClick={this.clearForm}
											aria-label="Create User" className="margin-right-20"
										>
											CLEAR
										</SecondaryButton>
										<ColorButton type="submit" aria-label="Create User"
											 onClick={this.handleSubmit}
											 className="margin-15"
										>
											SAVE
										</ColorButton>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					</form>
				</Grid>
			</React.Fragment>
		)
	}
}

export default withStyles(styles)(AddDspStep1Form);
