const styles = theme => ({
	'@global'                      : {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	appBar                         : {
		/*position : 'relative',
		height   : 48,
		minHeight: 48*/
	},
	toolbarTitle                   : {
		flex: 1,
	},
	card                           : {
		minWidth       : 400,
		backgroundColor: "#FFF",
		color          : "#9CAFC3"
	},
	cardContent: {
		paddingTop: 24
	},
	cardText: {
		backgroundImage: 'linear-gradient(to top, #25b7ab, #2099d8)',
		fontSize: 12,
		backgroundClip: 'text',
		'-webkit-text-fill-color': 'transparent',
		'-webkit-background-clip': 'text',
	},
	cardData: {
		backgroundImage: 'linear-gradient(to top, #25b7ab, #2099d8)',
		fontSize: 30,
		fontWeight: "bold",
		backgroundClip: 'text',
		'-webkit-text-fill-color': 'transparent',
		'-webkit-background-clip': 'text',
	},
	layout                         : {
		width                                                    : 'auto',
		marginLeft                                               : theme.spacing(1) * 3,
		marginRight                                              : theme.spacing(1) * 3,
		[theme.breakpoints.up(1024 + theme.spacing(1) * 3 * 2)]: {
			width      : 1024,
			marginLeft : 'auto',
			marginRight: 'auto',
		},
	},
	heroContent                    : {
		maxWidth: 600,
		margin  : '0 auto',
		padding : `${theme.spacing(1) * 8}px 0 ${theme.spacing(1) * 6}px`,
	},
	footer                         : {
		width: 'auto',
		marginTop: theme.spacing(1) * 8,
		/*borderTop: `1px solid ${theme.palette.divider}`,*/
		padding  : `${theme.spacing(1) * 6}px 0`,
	},
	aroaButton                     : {
		backgroundColor: "#9CAFC3",
		color          : "#FFFFFF",
	},
	aroaLongButton                 : {
		backgroundColor: "#9CAFC3",
		color          : "#FFFFFF",
		width          : 300
	},
	aroaLongButtonBlack            : {
		backgroundColor: "#000",
		color          : "#FFFFFF",
		width          : 300
	},
	/*//////////////MarketLanding Styles/////////////Page*/
	paperMarketLanding             : {
		display      : 'flex',
		flexDirection: 'column',
		alignItems   : 'center',
	},
	containerSearchMarketLanding   : {
		display : 'flex',
		flexWrap: 'wrap',
		minWidth: 300,
	},
	aroaMarketLandingSearchAudience: {
		'& fieldset': {
			borderRadius: 20
		}
	},
	searchInput                    : {
		'& fieldset': {
			borderRadius     : 20,
			height           : 32,
			borderColor      : '#23AABF',
			/*Comentado para implementar  borderRadius*/
			/*borderImageSource: 'linear-gradient(to top, #25b7ab, #2099d8)',
			borderImageSlice : 1*/
		},
		'& div'     :
			{
			height: 30,
			}
	},
	/*///////////////AutenticathedButtons styles////////////////////*/
	gridAutenticatedButtons        : {
		display : 'flex',
		flexWrap: 'wrap',
	},
	containerSearchAroabar         : {
		margin  : 0,
		padding : 0,
		display : 'flex',
		flexWrap: 'wrap',
	},
	textfieldSearchAroaBar         : {
		'& fieldset': {
			/*height: 40,*/
			borderRadius: 25,
		},
	},
	/*///////////////////////Create user, Dsp, advertiser styles//////////////////////*/
	aroaLongButtonWhite            : {
		backgroundColor: "#FFFFFF",
		color          : "#9CAFC3",
		width          : 300,
	},
	/*///////////////////////ComposerSegmentCard////////////////////////////////////////////*/
	media                          : {
		height    : 0,
		paddingTop: '56.25%', // 16:9
	},
	expand                         : {
		transform : 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen                     : {
		transform: 'rotate(180deg)',
	},
	/*///////////////////////Cart Pages styles////////////////////////////////////////////*/
	dropdown: {
		color: "#9CAFC3",
	},
	aroaColor: {
		color: "#9CAFC3",
		fontWeight: 'bold'
	}

});

export default (styles);
