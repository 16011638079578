import React            from 'react';
import Typography       from '@material-ui/core/Typography';
import Grid             from "@material-ui/core/Grid";
import {withStyles}     from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const __DEFAULT_CPM = 1.5;

const TotalCookiesLoader = withStyles(theme => ({
	root: {
		progress: {
			margin: theme.spacing(2),
		},
		color   : "#ffffff"
	},
}))(CircularProgress);

export default function LookalikeSlider(props){
	const {users, loading} = props;
	return (
		<div className="container-look-alike">
			{/*			<Typography id="discrete-slider" className="text-lookalike-slider" gutterBottom>
				LOOK ALIKE
			</Typography>
			<Slider
				className="margin-top-30 slider-bar"
				defaultValue={100000}
				disabled={true}
				getAriaValueText={valuetext}
				aria-labelledby="lookalike-slider"
				valueLabelDisplay="off"
				step={10}
				min={0}
				max={450000}
			/>*/}
			{/*si necesitamos valueLabelDisplay="on" cambiar tambien las clase css .slider-bar para mostrar valor*/}
			{/*			<Grid className="flex flex--justify--spacebetween">
				<Grid>
					<Typography className="text-lookalike-slider">
						+
					</Typography>
				</Grid>
				<Grid>
					<Typography className="text-lookalike-slider">
						-
					</Typography>
				</Grid>
			</Grid>*/}
			<Grid className="flex flex--justify--spacebetween">
				<Grid>
					<Typography className="text-lookalike-slider">
						CPM: {__DEFAULT_CPM}
					</Typography>
				</Grid>
				<Grid>
					{loading ? <TotalCookiesLoader size={20}/> :
						<Typography className="text-lookalike-slider">
							Users : {users}
						</Typography>
					}
				</Grid>
			</Grid>
		</div>
	);
}