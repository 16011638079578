import { connect } from 'react-redux';
import CartStep4 from "../../../screens/Cart/CartStep4";
import {setAudiences, changeCartStep} from "../../actions";

const getAudienceAttributes = (audienceAttributes) => {
    return audienceAttributes;
};

const mapStateToProps = (state) => {
    return {
        audienceAttributes: getAudienceAttributes(state.audienceAttributes)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCartStep: (cartStep) => {
            dispatch(changeCartStep(cartStep))
        },
        setAudiences: (audiences) => {
            dispatch(setAudiences(audiences));
        }
    }
};

const CartStepContainer4 = connect(
    mapStateToProps,
    mapDispatchToProps
)(CartStep4);

export default CartStepContainer4;
