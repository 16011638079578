import {
	ATTRIBUTE_CHANGE,
	CHANGE_CART_STEP,
	DELETE_AUDIENCE,
	SET_AUDIENCES,
	SET_CART_ITEMS_COUNT,
	SET_SEARCH_LOADING,
	SET_SEARCH_RESULTS,
	SET_SEARCH_TERM,
	SET_TAXONOMY_INFO
}                                                              from "../constants/action-types";
import sessionManager, {__CURRENT_NUMBER_OF_AUDIENCES_IN_CART} from "../../services/storage-manager";

const initialState = {
	audienceAttributes: [],
	currentAudience   : null,
	cartStep          : null,
	cartItemsCount    : 0,
	searchTerms       : "",
	searchLoading     : false,
	searchResults     : [],
	taxonomyInfo      : {
		taxonomyID   : null,
		transactionID: null,
		audiences    : []
	}
};

function rootReducer(state = initialState, action)
{
	
	if(action.type === ATTRIBUTE_CHANGE){
		return Object.assign({}, state, {
			audienceAttributes: state.audienceAttributes.map((audience) => {
				if(audience.audienceID === action.payload.audienceId){
					return {
						...audience,
						...action.payload
					}
				}
				
				return audience;
			})
		});
	}
	
	if(action.type === SET_AUDIENCES){
		return Object.assign({}, state, {
			audienceAttributes: state.audienceAttributes.length > 0 ? state.audienceAttributes : action.payload
		});
	}
	
	if(action.type === SET_TAXONOMY_INFO){
		return Object.assign({}, state, {
			taxonomyInfo: action.payload
		});
	}
	
	if(action.type === DELETE_AUDIENCE){
		return Object.assign({}, state, {
			audienceAttributes: state.audienceAttributes.filter((audience) => {
				return audience.audienceID !== action.payload.audienceId
			})
		});
	}
	
	if(action.type === CHANGE_CART_STEP){
		return Object.assign({}, state, {
			cartStep: action.payload
		});
	}
	
	if(action.type === SET_CART_ITEMS_COUNT){
		if(!action.payload){
			let cartItemsCount = sessionManager.getValue(__CURRENT_NUMBER_OF_AUDIENCES_IN_CART);
			return Object.assign({}, state, {
				cartItemsCount: cartItemsCount
			});
		}else{
			sessionManager.setValue(__CURRENT_NUMBER_OF_AUDIENCES_IN_CART, action.payload);
			return Object.assign({}, state, {
				cartItemsCount: action.payload
			});
		}
	}
	
	if(action.type === SET_SEARCH_TERM){
		return Object.assign({}, state, {
			searchTerms: action.payload
		});
	}
	
	if(action.type === SET_SEARCH_LOADING){
		return Object.assign({}, state, {
			searchLoading: action.payload
		});
	}
	
	if(action.type === SET_SEARCH_RESULTS){
		return Object.assign({}, state, {
			searchResults: action.payload
		});
	}
	
	return state;
}

export default rootReducer;
