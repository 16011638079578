import React               from "react";
import AroaParentComponent from "../../components/AroaParentComponent";
import AppBar              from "@material-ui/core/AppBar";
import Toolbar             from "@material-ui/core/Toolbar";
import Grid                from "@material-ui/core/Grid";
import AroaLogo            from "../AroaLogo";

class SimpleAroaBar extends AroaParentComponent
{
	render()
	{
		const {classes} = this.props;
		return (
			<AppBar position="static" color="default" className={classes.appBar}>
				<Toolbar>
					<Grid container justify={"flex-start"} direction={"row"} spacing={0}>
						<Grid style={{alignItems: "flex-start", display: "flex"}}>
							<AroaLogo width={"100rem"}/>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		);
	}
}

export default SimpleAroaBar;