import React from 'react';
import {Grid} from "@material-ui/core";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/es/Checkbox/Checkbox";
import TextField from '@material-ui/core/TextField';
import Notifier, {showNotify} from "../../../atoms/AroaNotifier";
import AroaParentComponent from "../../AroaParentComponent";
import {__LOGIN} from '../../../services/endpoint-manager';
import sessionManager, {__API_TOKEN, localStorageManager} from "../../../services/storage-manager";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {Typography}          from "@material-ui/core";

class LandingLogin extends AroaParentComponent {
    constructor(props) {
        super(props);
        this.state = {
            usr: "",
            pwd: "",
            rememberMe: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.state[event.target.id] = event.target.value;
    }

    handleSubmit(event) {
        event.preventDefault();
        let self = this;
        this.state.isLogged = false;
        if (this.state.usr && this.state.pwd) {
            this.getXHRHandler().launchPost(__LOGIN, this.state, (response) => {
                /** guardamos el apiToken que viene del back */
                localStorageManager.setValue(__API_TOKEN, this.getXHRHandler().getSuccessData(response).apiToken);

                /** Limpiamos la posible info del carrito que pudiera haber */
                sessionManager.clearCartInfo();
                self.gotoPath('/');
            }, (error) => {
                showNotify({message: this.getXHRHandler().getErrorDescription(error)});
            });
        } else {
            if (!this.state.usr && this.state.pwd) {
                showNotify({message: "Please make sure you have fill Email field"});
            } else if (this.state.usr && !this.state.pwd) {
                showNotify({message: "Please make sure you have fill Password field"});
            } else {
                showNotify({message: "Please make sure you have fill all required fields"});
            }

        }
    }

    render() {
        return (
            <React.Fragment>
                <Grid className="flex  flex--centered">
                    <Notifier/>
                    <form style={{width : "80%"}} onSubmit={this.handleSubmit}>
                        <Grid className="container--slider--form margin-top-10">
                            <Grid container >
                                <Grid item xl={6} sm={6} xs={12} className="margin-5">
                                    <FormControl required>
                                        <TextField className="loginLand name-texfield-landingLogin"
                                                   id="usr"
                                                   name="usr"
                                                   type={"text"}
                                                   onChange={this.handleChange}
                                                   autoComplete="email"
                                                   autoFocus
                                                   placeholder={"Your email"}
                                                   helperText=""
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={6} sm={6} xs={12} className="margin-5">
                                    <FormControl required>
                                        <TextField className="loginLand name-texfield-landingLogin"
                                                   id="pwd"
                                                   name="pwd"
                                                   type="Password"
                                                   onChange={this.handleChange}
                                                   autoComplete="current-password"
                                                   placeholder="Your password"

                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid className="flex margin-top-10 flex--justify--center">
                                <Grid>
                                    <Grid>
                                        <FormControlLabel className="Lorem-ipsum-dolor-si-12px"
                                                          control={<Checkbox color="primary" value="true"
                                                                             name="rememberMe"
                                                                             id="rememberMe"
                                                                             onChange={this.handleChange}/>}
                                                          label="Keep me logged in"/>

                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className="margin-top-15">
                            <Button
                                className="button-signup-login"
                                type="submit" aria-label="Log in"
                                onClick={this.handleSubmit}
                                variant="contained"
                            >
                                LOGIN
                            </Button>
                        </Grid>

                             <Grid className="margin-top-10">
                             <Link className="forgot-password-link" to={"/password/remember"}>
                             <Typography className="forgot-password">
                             Forgot your password?
                             </Typography>
                             </Link>
                             </Grid>
                    </form>
                </Grid>
            </React.Fragment>
        );
    }
}

export default LandingLogin;
