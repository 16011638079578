import React                 from 'react';
import styles                from '../../../components/AroaPage/aroatheme';
import {withStyles}          from '@material-ui/core/styles';
import AroaParentComponent   from "../../../components/AroaParentComponent";
import AroaStepper		     from "../../../atoms/AroaStepper";
import AroaBar               from "../../../components/AroaBar";
import AroaPage              from "../../../components/AroaPage";
import Grid                  from "@material-ui/core/Grid";
import {ErrorIcon} from "../../../atoms/AroaIcons";
import AroaMessageScreen from "../../../atoms/AroaMessageScreen";

class CartStep5ErrorBody extends React.Component
{
	render()
	{
		return (
			<Grid>
				<AroaStepper
					steps={['Check audiences', 'Audience attributes', 'Audience update', 'Confirm your audiences']}
					activeState={3}
					optionals={[]}
					completed={[0, 1, 2, 3]}
				/>
				<AroaMessageScreen
					buttonText="RETRY"
					showButton={true}
					linkTo="/"
					icon={<ErrorIcon classes="message-screen__icon" active={true}/>}
					title="Something went wrong..."
					message={"Please contact us"}
				/>
			</Grid>
		)
	}
}

class CartStep5Error extends AroaParentComponent
{
	render()
	{
		let props = {
			about   : false,
			platform: false,
			login   : false
		};
		return (
			
			<AroaPage header={(new AroaBar({...this.props, ...props}).render())}
					  body={(new CartStep5ErrorBody(this.props).render())}/>
		)
	}
}

export default withStyles(styles)(CartStep5Error);
