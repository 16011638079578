import React                  from 'react';
import AroaParentComponent    from "../../components/AroaParentComponent";
import {Grid, withStyles}     from "@material-ui/core";
import Paper                  from "@material-ui/core/es/Paper";
import Typography             from "@material-ui/core/es/Typography";
import FormControl            from "@material-ui/core/es/FormControl";
import TextField              from "@material-ui/core/TextField";
import AroaPage               from "../../components/AroaPage";
import AroaBar                from "../../components/AroaBar";
import Notifier, {showNotify} from "../../atoms/AroaNotifier";
import PropTypes              from "prop-types";
import {__RESETPASSWORD}      from '../../services/endpoint-manager';
import Button                 from "@material-ui/core/Button";
import {tokenManager}         from "../../services/token-manager";

const ResetPasswordButton = withStyles(() => ({
	root: {
		color          : "#fff",
		backgroundColor: '#015783',
		borderRadius   : 30,
		height         : 44,
		width          : 255,
		fontWeight     : "bold",
		fontSize       : 12
	}
}))(Button);

const styles = theme => ({
	paper : {
		marginTop    : theme.spacing(1) * 8,
		display      : 'flex',
		flexDirection: 'column',
		maxWidth     : 350,
		alignItems   : 'center',
		padding      : `${theme.spacing(1) * 2}px ${theme.spacing(1) * 3}px ${theme.spacing(1) * 3}px`,
	},
	form  : {
		width    : 250, // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		marginTop: theme.spacing(1) * 3,
	},
});

class ResetPasswordBody extends AroaParentComponent
{
	constructor(props)
	{
		super(props);
		this.state               = {
			newPassword        : "",
			token              : this.props.match.params.token,
			newRepeatedPassword: ""
		};
		this.handleSubmit        = this.handleSubmit.bind(this);
		this.handleChange        = this.handleChange.bind(this);
		this.validateNewPassword = this.validateNewPassword.bind(this);
	}
	
	validateNewPassword()
	{
		if(this.state.newPassword.length === 0){
			showNotify({message: "New password field is empty"});
			return false;
		}
		
		if(this.state.newRepeatedPassword.length === 0){
			showNotify({message: "New confirm password field is empty"});
			return false;
		}
		
		if(this.state.newPassword !== this.state.newRepeatedPassword){
			showNotify({message: "New passwords mismatch"});
			return false;
		}
		
		return this.state.newPassword === this.state.newRepeatedPassword;
	}
	
	validateTokenIsPresentOnURL()
	{
		return this.state.token.length > 0;
	}
	
	handleSubmit = (event) => {
		event.preventDefault();
		
		if(this.validateNewPassword() && this.validateTokenIsPresentOnURL()){
			let _self = this;
			this.getXHRHandler().launchPost(__RESETPASSWORD, this.state, (response) => {
				tokenManager.removeToken() ;
				_self.gotoPath('/password/changed');
			}, (error) => {
				showNotify({message: _self.getXHRHandler().getErrorDescription(error)});
			});
		}
	};
	
	handleChange = (e) => {
		this.state[e.target.id] = e.target.value;
	};
	
	render()
	{
		return (
			<React.Fragment>
				<Grid container justify={"center"}>
					<Notifier/>
					<form onSubmit={this.handleSubmit}>
						<Grid item>
							<Grid>
								<Typography className="margin-top-30 welcome-title">
									Create a new password
								</Typography>
							</Grid>
							<div className="margin-top-50" style={{textAlign: "center"}}>
								
								
								<Paper className="flex flex--direction--column" elevation={0}>
									<Grid className="margin-top-20">
										<FormControl required>
											<TextField
												className="textfield-password"
												id="newPassword"
												name="newPassword"
												type={"Password"}
												onChange={this.handleChange}
												label="NEW PASSWORD"
											/>
										</FormControl>
									</Grid>
									<Grid className="margin-top-20">
										<FormControl required>
											<TextField
												className="textfield-password"
												id="newRepeatedPassword"
												name="newRepeatedPassword"
												type={"Password"}
												onChange={this.handleChange}
												label="CONFIRM PASSWORD"
											/>
										</FormControl>
									</Grid>
									<Grid className="margin-top-30">
										<ResetPasswordButton type="submit" aria-label="Change password"
															 onClick={this.handleSubmit}>
											Change Password
										</ResetPasswordButton>
									</Grid>
								</Paper>
							</div>
						</Grid>
					</form>
				</Grid>
			</React.Fragment>
		);
	}
}

class ResetPassword extends AroaParentComponent
{
	render()
	{
		return <AroaPage header={(new AroaBar(this.props).render())}
						 body={(new ResetPasswordBody(this.props).render())}/>;
	}
}

ResetPassword.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(styles)(ResetPassword);