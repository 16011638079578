import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { withRouter } from "react-router";
import { showNotify } from "../../atoms/AroaNotifier";
import { __ACTION_BUTTON } from "../../atoms/ButtonsStyles";
import AroaBar from "../../components/AroaBar";
import AroaPage from "../../components/AroaPage";
import styles from "../../components/AroaPage/aroatheme";
import AroaParentComponent from "../../components/AroaParentComponent";
import {
  __GET_AVAILABLE_MONTHS,
  __GET_BILLING_MONTH_DATA,
} from "../../services/endpoint-manager";
import { userRoles } from "../../services/userRoles-manager";
import XLSX from "xlsx";

const ColorButton = withStyles(() => __ACTION_BUTTON)(Button);

class BillingBody extends AroaParentComponent {
  constructor() {
    super();

    this.state = {
      hideZeros: true,
      selectedMonth: new Date().getMonth() + 1,
      months: Array.from({ length: 12 }, (_, i) =>
        new Date(0, i).toLocaleString("en-US", { month: "long" })
      ),
    };

    this.axiosHandler = this.getXHRHandler();
    this.dataGridColumns = [
      { field: "dataBuyerName", headerName: "DataBuyer", flex: 0.6 },
      { field: "dataPartner", headerName: "DataPartner", flex: 0.75 },
      { field: "advertiserName", headerName: "Advertiser", flex: 0.75 },
      { field: "costDate", headerName: "Date", flex: 0.55 },
      { field: "name", headerName: "DataActivator", flex: 0.5 },
      { field: "audienceID", headerName: "Audience", flex: 1 },
      { field: "id", headerName: "Segment", flex: 1 },
      { field: "segmentCost", headerName: "Cost", flex: 0.4 },
      { field: "partnerRevenue", headerName: "Partner REV", flex: 0.4 },
      { field: "datmeanRevenue", headerName: "Datmean REV", flex: 0.4 },
    ];
  }

  componentDidMount = () => {
    this.getAvailableMonths();
    this.refreshMonth();
  };

  handleCostsUploaderClick = () => this.gotoPath("/costs/upload");

  handleDownloadClick = () => {
    const formattedData = this.state.showingMonthData.map((row) => {
      const newRow = {
        ...row,
        segment: row.id,
        dataActivator: row.name,
        segmentCost: parseFloat(row.segmentCost),
        partnerRevenue: parseFloat(row.partnerRevenue),
        datmeanRevenue: parseFloat(row.datmeanRevenue),
      };
      delete newRow.id;

      return newRow;
    });
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    const month = new Date(0, this.state.selectedMonth - 1).toLocaleString(
      "en-US",
      { month: "long" }
    );
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, `${month}.xlsx`);
  };

  handleChangeHide = (e) => {
    this.setState({
      hideZeros: e.target.checked,
      showingMonthData: e.target.checked
        ? this.state.monthData.filter((row) => row.segmentCost > 0)
        : this.state.monthData,
    });
  };

  refreshMonth = () => {
    this.axiosHandler.launchGet(
      __GET_BILLING_MONTH_DATA(this.state.selectedMonth),
      (response) => {
        const monthData = this.axiosHandler.getSuccessData(response).monthData;
        this.setState({
          monthData: monthData,
          showingMonthData: this.state.hideZeros
            ? monthData.filter((row) => row.segmentCost > 0)
            : monthData,
        });
      },
      (error) =>
        showNotify({ message: this.axiosHandler.getErrorDescription(error) })
    );
  };

  onMonthChange = (e) => {
    this.setState({ selectedMonth: e.target.value }, this.refreshMonth);
  };

  getAvailableMonths = () => {
    this.axiosHandler.launchGet(
      __GET_AVAILABLE_MONTHS("consolidatedSegments"),
      (response) => {
        const data = this.axiosHandler.getSuccessData(response);
        const availableMonths = data.map((x) => parseInt(x.month));
        this.setState({
          months: this.state.months.filter((month, index) =>
            availableMonths.includes(index + 1)
          ),
          selectedMonth: availableMonths[availableMonths.length - 1],
        });
      }
    );
  };

  render() {
    return (
      <Grid>
        <Grid className="flex flex__full flex--justify--spacebetween">
          <Grid>
            <FormControl variant="outlined">
              <Select
                value={this.state.selectedMonth}
                input={<OutlinedInput labelWidth={0} />}
                onChange={this.onMonthChange}
                name="state"
                className="activity__state-filter"
              >
                {this.state.months.map((month, index) => (
                  <MenuItem value={index + 1} key={index + 1}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <ColorButton color="secondary" onClick={this.handleDownloadClick}>
              <GetAppIcon style={{ fill: "white" }} />
              <Typography variant="overline" className="top-bar__text-link">
                Download
              </Typography>
            </ColorButton>
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.hideZeros}
                onChange={this.handleChangeHide}
                name="checkedA"
              />
            }
            label="Hide Zeros"
          />
        </Grid>
        <Grid className="flex flex__full flex--end"></Grid>
        {this.state.showingMonthData ? (
          <Grid
            style={{
              height: 800,
              marginTop: 10,
              width: "120%",
              marginLeft: "-10%",
            }}
          >
            <DataGrid
              pageSize={100}
              rowsPerPageOptions={[
                25,
                50,
                100,
                this.state.showingMonthData.length,
              ]}
              columns={this.dataGridColumns}
              rows={this.state.showingMonthData}
            ></DataGrid>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    );
  }
}

class Billing extends AroaParentComponent {
  render() {
    if (this.isUserLogged() && userRoles.isBilling(this.userEmail)) {
      return (
        <AroaPage
          header={new AroaBar(this.props).render()}
          body={<BillingBody props={this.props} />}
        />
      );
    } else {
      this.gotoPath("/");
      return "";
    }
  }
}

export default withRouter(withStyles(styles)(Billing));
