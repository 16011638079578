import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from 'date-fns';

let date = new Date();
const minExpirationDate = new Date(date.setTime( date.getTime() + 15 * 86400000 ));

class AroaCartAudienceDateRow extends React.Component {
    
    constructor(props) {
        super(props);
        this.handleDateChange = this.handleDateChange.bind(this);
        let audiencesAttributes = this.props.audienceAttributes;
        let audienceAttributes = audiencesAttributes.filter(audience => audience.audienceID === this.props.audience.audienceID);
        
        if (audienceAttributes.length > 0) {
            this.state = {
                typeID: 2,
                expirationDate: audienceAttributes[0].expirationDate ? audienceAttributes[0].expirationDate : null,
                typeName: "LIVE",
                audienceId: this.props.audience.audienceID
            };
            
        } else {
            this.state = {
                typeID: 2,
                expirationDate: null,
                audienceId: this.props.audience.audienceID
            };
        }
    }
    
    handleDateChange(date) {
        date = format(new Date(date), 'yyyy-MM-dd');
        this.setState({expirationDate: date},
            function () {
                this.props.onAttributeChange(this.state);
            }.bind(this)
        );
    }
    
    render() {
        const {audience, transactions} = this.props;
        
        return (
            <TableRow key={audience.audienceName} style={{height: 100}}>
                <TableCell component="th" scope="row" className="aroaColor">
                    {audience.audienceName}
                </TableCell>
                <TableCell className="aroaColor">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            format="yyyy-MM-dd"
                            name="expirationDate"
                            minDate={minExpirationDate}
                            value={this.state.expirationDate}
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </TableCell>
                <TableCell className="aroaColor">
                    <FormControl variant="outlined" disabled>
                        <Select value={this.state.typeID}
                                input={<OutlinedInput labelWidth={0}/>}
                                name="typeID"
                                onChange={this.handleSelectTypeChange}
                        >
                            <MenuItem disabled value={"types"}>Types</MenuItem>
                            {transactions.map((types) =>
                                <MenuItem value={types.typeID}
                                          key={types.typeID}>{types.typeOption}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </TableCell>
            </TableRow>
        )
    }
}

export default AroaCartAudienceDateRow;
