import React                 from 'react';
import {withStyles}          from '@material-ui/core/styles';
import AroaParentComponent   from "../../../components/AroaParentComponent";
import AroaPage              from "../../../components/AroaPage";
import SimpleAroaBar         from "../../../atoms/AroaSimpleBar";
import AddDspStep1Form       from "../../../components/AroaForms/RegisterForms/AddDspStep1Form";
import styles                from '../../../components/AroaPage/aroatheme';
import AroaStepper from "../../../atoms/AroaStepper";

class CreateUserDSPBody extends AroaParentComponent
{
	render()
	{
		return (
			<React.Fragment>
				<AroaStepper
					steps={['Create an account', 'Add users', 'Add DSP', 'Add advertiser']}
					completed={[0, 1]}
					activeState={2}
				/>
				<AddDspStep1Form/>
			</React.Fragment>
		)
	}
}

class CreateUserDspStep1 extends AroaParentComponent
{
	render()
	{
		if(this.isUserLogged()){
			return <AroaPage header={(new SimpleAroaBar(this.props).render())}
							 body={(new CreateUserDSPBody(this.props).render())}/>;
		}else{
			this.gotoPath('/');
			return '';
		}
	}
}

export default withStyles(styles)(CreateUserDspStep1);
