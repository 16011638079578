import React from "react";
import PropTypes from "prop-types";
import { Grid, withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/es/Paper/Paper";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/es/Checkbox/Checkbox";
import Typography from "@material-ui/core/es/Typography/Typography";
import TextField from "@material-ui/core/TextField";
import Notifier, { showNotify } from "../../atoms/AroaNotifier";
import AroaParentComponent from "../../components/AroaParentComponent";
import AroaPage from "../../components/AroaPage";
import AroaBar from "../../components/AroaBar";
import { __LOGIN } from "../../services/endpoint-manager";
import sessionManager, {
  __API_TOKEN,
  localStorageManager,
} from "../../services/storage-manager";
import Button from "@material-ui/core/Button";
import styles from "../../components/AroaPage/aroatheme";

class LogInBody extends AroaParentComponent {
  constructor(props) {
    super(props);
    this.state = {
      usr: "",
      pwd: "",
      rememberMe: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.state[event.target.id] = event.target.value;
  }

  handleSubmit(event) {
    event.preventDefault();
    this.state.isLogged = false;
    if (this.state.usr && this.state.pwd) {
      this.getXHRHandler().launchPost(
        __LOGIN,
        this.state,
        (response) => {
          /** guardamos el apiToken que viene del back */
          localStorageManager.setValue(
            __API_TOKEN,
            this.getXHRHandler().getSuccessData(response).apiToken
          );

          /** Limpiamos la posible info del carrito que pudiera haber */
          sessionManager.clearCartInfo();
          window.location.href = "/";
        },
        (error) => {
          showNotify({
            message: this.getXHRHandler().getErrorDescription(error),
          });
        }
      );
    } else {
      if (!this.state.usr && this.state.pwd) {
        showNotify({ message: "Please make sure you have fill Email field" });
      } else if (this.state.usr && !this.state.pwd) {
        showNotify({
          message: "Please make sure you have fill Password field",
        });
      } else {
        showNotify({
          message: "Please make sure you have fill all required fields",
        });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Grid className="flex paper-login flex--centered">
          <Notifier />
          <form onSubmit={this.handleSubmit}>
            <Grid className="flex">
              <div className={"margin-top-30"}>
                <Paper className=" margin-left-30" elevation={0}>
                  <Typography className="Lorem-ipsum-dolor-si">
                    Log in
                  </Typography>
                  <Grid className="flex">
                    <Grid className="margin-top-40">
                      <FormControl required>
                        <TextField
                          className="login name-texfield-Login"
                          id="usr"
                          name="usr"
                          type={"text"}
                          onChange={this.handleChange}
                          label="EMAIL"
                          autoComplete="email"
                          autoFocus
                          placeholder={"Your email"}
                          helperText=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid className="margin-left-30 margin-top-40">
                      <FormControl required>
                        <TextField
                          className="login password-texfield-Login"
                          id="pwd"
                          name="pwd"
                          type="Password"
                          onChange={this.handleChange}
                          label="PASSWORD"
                          autoComplete="current-password"
                          placeholder="Your password"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid className="flex margin-top-40 flex--justify--spacebetween">
                    <Grid>
                      <Grid>
                        <FormControlLabel
                          className="Lorem-ipsum-dolor-si-12px"
                          control={
                            <Checkbox
                              color="primary"
                              value="true"
                              name="rememberMe"
                              id="rememberMe"
                              onChange={this.handleChange}
                            />
                          }
                          label="Keep me logged in"
                        />
                        {/* <Grid className="margin-top-20">
                                                <Link to={"/"}>
                                                    <Typography style={{float: "left"}}>
                                                        Forgot password
                                                    </Typography>
                                                </Link>
                                            </Grid>*/}
                      </Grid>
                    </Grid>
                    <Button
                      className="button-signup-login"
                      type="submit"
                      aria-label="Log in"
                      onClick={this.handleSubmit}
                      variant="contained"
                    >
                      SIGN IN
                    </Button>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          </form>
        </Grid>
      </React.Fragment>
    );
  }
}

class LogIn extends AroaParentComponent {
  render() {
    /** En la pantalla de login no mostramos los botones de about,platform y login */
    let props = {
      textAroaBar: false,
      about: false,
      trial: false,
      signup: false,
    };

    if (this.isUserLogged()) {
      window.location.href = "/";
    } else {
      return (
        <AroaPage
          header={new AroaBar({ ...this.props, ...props }).render()}
          body={new LogInBody(this.props).render()}
        />
      );
    }
  }
}

LogIn.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(LogIn);
