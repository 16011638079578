import React from 'react';
import Grid  from "@material-ui/core/Grid";

export const ShoppingCartIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container " + props.classes}>
				<span className={props.active ? "icon icon-cart icon--active" : "icon icon-cart"}/>
			</Grid>
		</React.Fragment>
	);
};

export const CopyIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container-no-dimensions " + props.classes}>
				<span className={props.active ? "icon icon-copy icon--active" : "icon-to-clipboard icon-copy"}/>
			</Grid>
		</React.Fragment>
	);
};

export const ApolloCartIcon = (props) => {
    return (
        <React.Fragment>
            <Grid className={"icon-container " + props.classes}>
                <span className={props.active ? "iconForApolloBar icon-rocket icon--active" : "iconForApolloBar icon-rocket"}/>
            </Grid>
        </React.Fragment>
    );
};
export const ApolloReportIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container " + props.classes}>
				<span className={props.active ? "iconForApolloBar icon-planet icon--active" : "iconForApolloBar icon-planet"}/>
			</Grid>
		</React.Fragment>
	);
};
export const NounSearchIcon = () => {
	return (
		<React.Fragment>
			<Grid className="icon-container">
				<span className="icon icon-search"/>
			</Grid>
		</React.Fragment>
	);
};

export const GraphIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container " + props.classes}>
				<span className={props.active ? "icon icon-graph icon--active" : "icon icon-graph"}/>
			</Grid>
		</React.Fragment>
	);
};

export const CalendarIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container " + props.classes}>
				<span className={props.active ? "icon icon-calendar icon--active" : "icon icon-calendar"}/>
			</Grid>
		</React.Fragment>
	);
};

export const BellIcon = () => {
	return (
		<React.Fragment>
			<Grid className="icon-container">
				<span className="icon icon-bell"/>
			</Grid>
		</React.Fragment>
	);
};

export const ReportIcon = () => {
	return (
		<React.Fragment>
			<Grid className="icon-container">
				<span className="icon icon-report"/>
			</Grid>
		</React.Fragment>
	);
};

export const ProfileIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container " + props.classes}>
				<span className={props.active ? "icon icon-profile icon--active" : "icon icon-profile"}/>
			</Grid>
		</React.Fragment>
	);
};

export const WrongIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className="">
				<span className={"big-icon icon-wrong"}/>
			</Grid>
		</React.Fragment>
	);
};

export const ArrowIcon = (props) => {
	const {height, width, alt} = props;
	return (
		<React.Fragment>
			<Grid>
				<img src={require('../../assets/icons/noun_chevron arrow_2074151.png')}
					 alt={alt}
					 height={height}
					 width={width}
				/>
			</Grid>
		</React.Fragment>
	);
};

ArrowIcon.defaultProps = {
	height: 40,
	width : 40,
	alt   : 'arrow'
};

export const DevicesIcon = (props) => {
	const {height, width, alt} = props;
	return (
		<React.Fragment>
			<Grid>
				<img src={require('../../assets/icons/noun_devices_188516.png')}
					 alt={alt}
					 height={height}
					 width={width}
				/>
			</Grid>
		</React.Fragment>
	);
};

DevicesIcon.defaultProps = {
	height: 40,
	width : 40,
	alt   : 'devices'
};

export const DesktopIcon = (props) => {
	const {height, width, alt} = props;
	return (
		<React.Fragment>
			<Grid>
				<img src={require('../../assets/icons/noun_Monitor_38377.png')}
					 alt={alt}
					 height={height}
					 width={width}
				/>
			</Grid>
		</React.Fragment>
	);
};

DesktopIcon.defaultProps = {
	height: 40,
	width : 40,
	alt   : 'desktop'
};

export const MobileIcon = (props) => {
	const {height, width, alt} = props;
	return (
		<React.Fragment>
			<Grid>
				<img src={require('../../assets/icons/noun_mobile phone_2820915.png')}
					 alt={alt}
					 height={height}
					 width={width}
				/>
			</Grid>
		</React.Fragment>
	);
};

MobileIcon.defaultProps = {
	height: 40,
	width : 40,
	alt   : 'mobile'
};

export const ExclamationIcon = (props) => {
	const {height, width, alt} = props;
	return (
		<React.Fragment>
			<Grid>
				<img src={require('../../assets/icons/noun_exclamation_138562.png')}
					 alt={alt}
					 height={height}
					 width={width}
				/>
			</Grid>
		</React.Fragment>
	);
};

ExclamationIcon.defaultProps = {
	height: 40,
	width : 40,
	alt   : 'exclamation'
};

export const MoveIcon = (props) => {
	const {height, width, alt} = props;
	return (
		<React.Fragment>
			<Grid>
				<img src={require('../../assets/icons/noun_Move_458657.svg')}
					 alt={alt}
					 height={height}
					 width={width}
				/>
			</Grid>
		</React.Fragment>
	);
};

MoveIcon.defaultProps        = {
	height: 40,
	width : 40,
	alt   : 'move'
};
export const SegmentMoveIcon = () => {
	return (
		<React.Fragment>
			<Grid >
				<span className="iconDraggable segment-move-icon"/>
			</Grid>
		</React.Fragment>
	);
};

export const DragIcon = () => {
	return (
		<React.Fragment>
			<Grid >
				<span className="iconDropable drag-icon"/>
			</Grid>
		</React.Fragment>
	);
};
export const ExclamationIconOrange = (props) => {
	const {height, width, alt} = props;
	return (
		<React.Fragment>
			<Grid>
				<img src={require('../../assets/icons/noun_exclamation_orange_138562.png')}
					 alt={alt}
					 height={height}
					 width={width}
				/>
			</Grid>
		</React.Fragment>
	);
};

ExclamationIconOrange.defaultProps = {
	height: 40,
	width : 40,
	alt   : 'exclamation'
};

export const SettingsIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container " + props.classes}>
				<span className={props.active ? "icon icon-settings icon--active" : "icon icon-settings"}/>
			</Grid>
		</React.Fragment>
	);
};

export const SuccessIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container " + props.classes}>
				<span className={props.active ? "icon icon-success icon--active" : "icon icon-success"}/>
			</Grid>
		</React.Fragment>
	);
};

export const ErrorIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container " + props.classes}>
				<span className={props.active ? "icon icon-error icon--active" : "icon icon-error"}/>
			</Grid>
		</React.Fragment>
	);
};

export const NotFoundIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container " + props.classes} >
				<span className={props.active ? "icon icon-notfound icon--active"  : "icon icon-notfound"}/>
			</Grid>
		</React.Fragment>
	);
};

export const NotFileIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container " + props.classes} >
				<span className={props.active ? "icon icon-notfile icon--active"  : "icon icon-notfile"}/>
			</Grid>
		</React.Fragment>
	);
};

export const PlayIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container " + props.classes}>
				<span className={props.active ? "icon icon-play icon--active" : "icon icon-play"}/>
			</Grid>
		</React.Fragment>
	);
};

export const PauseIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container " + props.classes}>
				<span className={props.active ? "icon icon-pause icon--active" : "icon icon-pause"}/>
			</Grid>
		</React.Fragment>
	);
};

export const ImageUnavailableIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container " + props.classes}>
				<span className="icon icon--big icon-image-unavailable"/>
			</Grid>
		</React.Fragment>
	);
};

export const LogoDatmeanWhite = (props) => {
	const { width, alt} = props;
	return (
		<React.Fragment>
			<Grid >
				<img src={require('../../assets/images/RT-Group-blanco.png')}
					 alt={alt}
					 width={width}
				/>
			</Grid>
		</React.Fragment>
	);
};

LogoDatmeanWhite.defaultProps = {
	width: 100,
	alt   : 'DatMean'
};


export const TwoDevices = () => {
	return (
		<React.Fragment>
			<Grid className="icon-container-landing-two-devices">
				<span className="icon-landing-devices-container icon-two-devices"/>
			</Grid>
		</React.Fragment>
	);
};

export const HelpIcon = (props) => {
	return (
		<React.Fragment>
			<Grid className={"icon-container-faq__help" + props.classes}>
				<span className={props.active ? "icon icon-help icon--active" : "icon icon-help"}/>
			</Grid>
		</React.Fragment>
	);
};


