import { connect } from 'react-redux';
import CartStep1 from "../../../screens/Cart/CartStep1";
import {setAudiences, changeCartStep, deleteAudience, setCartItemsCount} from "../../actions";

const getCartItemsCount = (cartItemsCount) => {
    return cartItemsCount;
};

const mapStateToProps = (state) => {
    return {
        cartItemsCount: getCartItemsCount(state.cartItemsCount)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCartStep: (cartStep) => {
            dispatch(changeCartStep(cartStep))
        },
        setAudiences: (audiences) => {
            dispatch(setAudiences(audiences));
        },
        deleteAudience: (audienceId) => {
            dispatch(deleteAudience(audienceId));
        },
        setCartItemsCount: (cartItemCount) => {
            dispatch(setCartItemsCount(cartItemCount))
        }
    }
};

const CartStepContainer1 = connect(
    mapStateToProps,
    mapDispatchToProps
)(CartStep1);

export default CartStepContainer1;
