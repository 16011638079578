import React               from 'react';
import PropTypes           from "prop-types";
import {Grid, withStyles}  from "@material-ui/core";
import {Link}              from "react-router-dom";
import styles              from '../../components/AroaPage/aroatheme';
import AroaCounterUU       from "../../atoms/AroaCounterUU";
import AroaLogo            from "../../atoms/AroaLogo";
import AroaPage            from "../../components/AroaPage";
import AroaParentComponent from "../../components/AroaParentComponent";
import Button              from "@material-ui/core/Button";

const LoginButton = withStyles(() => ({
	root: {
		color          : "#fff",
		backgroundImage: 'linear-gradient(to top, #25b7ab, #2099d8)',
		height         : 44,
		width          : 255,
		borderRadius   : 30,
		fontWeight	   : "bold",
		fontSize       : 12
	}
}))(Button);

const SignInButton = withStyles(() => ({
	root: {
		color          : '#fff',
		backgroundColor: '#015783',
		borderRadius   : 30,
		height         : 44,
		width          : 255,
		fontWeight	   : "bold",
		fontSize       : 12
	},

}))(Button);

const SignUpBody = () => {
	return (
		<Grid className="sign-up-page">
			<AroaLogo width={255}/>
			<div className={"margin-top-30"}>
				<Grid container spacing={2} justify="space-evenly">
					<Grid item xs={12} xl={12} md={12}>
						<Link to={"/login"}>
							<LoginButton aria-label="LOG IN">
								LOG IN
							</LoginButton>
						</Link>
					</Grid>
					<Grid item xs={12} xl={12} md={12}>
						<Link to={"/createuser/step1"}>
							<SignInButton aria-label="SIGN IN">
								SIGN UP
							</SignInButton>
						</Link>
					</Grid>
					<Grid item xs={12} xl={12} md={12} hidden={true}>
						<div>
							<Link to={"/remeberpassword"}>
								Forgot password
							</Link>
						</div>
					</Grid>
					<AroaCounterUU/>
				</Grid>
			</div>
		</Grid>
	);
};

class SignUp extends AroaParentComponent
{
	render()
	{
		if(!this.isUserLogged()){
			return <AroaPage body={SignUpBody(this.props)}/>
		}else{
			this.gotoPath('/');
			return '';
		}
	}
}

SignUp.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(styles)(SignUp);