class dndManager
{
	/** a little function to help us with reordering the result
	 *
	 * @param list
	 * @param startIndex
	 * @param endIndex
	 * @returns Array
	 */
	static reorder = (list, startIndex, endIndex) => {
		const result    = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		
		return result;
	};
	
	/**
	 * Moves an item from one list to another list.
	 * @param source
	 * @param destination
	 * @param droppableSource
	 * @param droppableDestination
	 */
	static move = (source, destination, droppableSource, droppableDestination) => {
		const sourceClone = Array.from(source);
		const destClone   = Array.from(destination);
		const [removed]   = sourceClone.splice(droppableSource.index, 1);
		
		destClone.splice(droppableDestination.index, 0, removed);
		
		const result                             = {};
		result[droppableSource.droppableId]      = sourceClone;
		result[droppableDestination.droppableId] = destClone;
		
		return result;
	};
	
	/**
	 * getItemStyle
	 * @param isDragging
	 * @param draggableStyle
	 * @returns {{border: (string), padding: string, userSelect: string}}
	 */
	static getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: "none",
		
		// change background colour if dragging
		//background: isDragging ? "lightgreen" : "",
		
		border : isDragging ? "2px dashed #25b7ab" : "",
		padding: "4px",
		
		// styles we need to apply on draggables
		...draggableStyle
	});
	
	/**
	 * getListStyle
	 * @param isDraggingOver
	 * @returns {{minHeight: number, background: string, marginTop: number}}
	 */
	static getListStyle = isDraggingOver => ({
		background: isDraggingOver ? "" : "",
		marginTop : 0,
		minHeight : 50
	});
	
	/**
	 * getAudiencesListStyle
	 * @param isDraggingOver
	 * @returns {{border: string, minHeight: number, background: string, marginTop: number}}
	 */
	static getAudiencesListStyle = isDraggingOver => ({
		background: isDraggingOver ? "" : "",
		border    : "1px dashed black",
		marginTop : 40,
		minHeight : 50,
		minWidth  : 100
	});
	
	static removeItem(source, position){
		let sourceClone = Array.from(source);
		sourceClone.splice(position, 1) ;
		return sourceClone ;
	}
}

export const booleanOperations = {
	OR : "or",
	AND: "and",
	NOT: "not"
};

export default dndManager;