import React from 'react';
import AroaParentComponent from "../../components/AroaParentComponent";
import {Grid, withStyles} from "@material-ui/core";
import styles from '../../components/AroaPage/aroatheme';
import Paper from "@material-ui/core/es/Paper";
import Typography from "@material-ui/core/es/Typography";
import FormControl from "@material-ui/core/es/FormControl";
import TextField from "@material-ui/core/TextField";
import AroaPage from "../../components/AroaPage";
import AroaBar from "../../components/AroaBar";
import Notifier, {showNotify} from "../../atoms/AroaNotifier";
import {__FORGOT_PASSWORD} from '../../services/endpoint-manager';
import Button from "@material-ui/core/Button";
import {__ACTION_BUTTON} from "../../atoms/ButtonsStyles";
import {Link} from "react-router-dom";

const ColorButton = withStyles(() => (__ACTION_BUTTON))(Button);

class ForgotPasswordBody extends AroaParentComponent {
    constructor(props) {
        super(props);
        this.state = {
            userMail: "",
            errorEmail: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    handleSubmit = (event) => {
        event.preventDefault();
        
        if (this.state.userMail) {
            let _self = this;
            this.getXHRHandler().launchPost(__FORGOT_PASSWORD, this.state, (response) => {
                this.setState({errorEmail: false});
                _self.gotoPath('/password/mail/sent');
            }, (error) => {
                showNotify({message: _self.getXHRHandler().getErrorDescription(error)});
            });
        } else {
            this.setState({errorEmail: true});
        }
    };
    
    handleChange = (e) => {
        this.state[e.target.id] = e.target.value;
    };
    
    render() {
        return (
            <React.Fragment>
                <Grid container justify={"center"}>
                    <Notifier/>
                    <form onSubmit={this.handleSubmit}>
                        <Grid item className="forgot-password__container">
                            <Grid>
                                <Typography className="margin-top-30 welcome-title">
                                    Forgot your password?
                                </Typography>
                                <Typography className="margin-top-30 title--big" variant={'h6'} color={"inherit"}>
                                    Hey, things happen. <br/>
                                    Just provide us your email address that you created the account with,
                                    and we will send out an email with instructions to set your new password
                                </Typography>
                            </Grid>
                            <div className="margin-top-40" style={{textAlign: "center"}}>
                                <Paper className="flex flex--direction--column" elevation={0}>
                                    <Grid className="margin-top-20">
                                        <FormControl required className="width--full">
                                            <TextField
                                                className=""
                                                id="userMail"
                                                name="userMail"
                                                required
                                                error={this.state.errorEmail}
                                                type="email"
                                                onChange={this.handleChange}
                                                label="Email"
                                                autoFocus
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid className="margin-top-30">
                                        <ColorButton type="submit" aria-label="Change password"
                                                     onClick={this.handleSubmit} className="button--big">
                                            RESET MY PASSWORD
                                        </ColorButton>
                                    </Grid>
                                    <Grid className="margin-top-30">
                                        <Link to="/">
                                            {'<'} Back to front page
                                        </Link>
                                    </Grid>
                                </Paper>
                            </div>
                        </Grid>
                    </form>
                </Grid>
            </React.Fragment>
        );
    }
}

class ForgotPassword extends AroaParentComponent {
    render() {
        return <AroaPage header={(new AroaBar(this.props).render())}
                         body={(<ForgotPasswordBody/>)}/>;
    }
}

export default withStyles(styles)(ForgotPassword);
