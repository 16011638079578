import React from "react";
import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core/index";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

class AroaInfoDialog extends React.Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.EscapePress = this.EscapePress.bind(this);
        this.cancel = this.cancel.bind(this);

        this.state = {
            open: props.open || false,
            title: props.title || "",
            text: props.text || "",
        };
    }

    componentDidMount(){
        document.addEventListener("keydown", this.EscapePress, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.EscapePress, false);
    }

    close() {
        this.setState({open: false});
    }

    EscapePress = (event) => {
        if (event.keyCode === 27) {
            this.cancel();
        }
    }

     cancel() {
         if (this.props.cancelHandler) this.props.cancelHandler();
         this.close();
     }

    render() {
        return (
            <Dialog
                open={this.state.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                id="dialog">
                <DialogTitle id="alert-dialog-title" className="flex flex--direction--column flex--justify--spacebetween" >

                    <span>{this.state.title}</span>

                    <IconButton
                        style={{float: "right"}}
                        aria-label="close"
                        onClick={this.cancel}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.text}
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        );
    }
}

export default AroaInfoDialog;
