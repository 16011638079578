import React from "react";

class DataPartnerLiveButtons extends React.Component {
  render() {
    const { text, clicked, onClick} = this.props;

    return (
      <div
        className={
          clicked ? "dataPartnerLiveButton-clicked" : "dataPartnerLiveButton"
        }
        onClick={() => onClick(text)}
      >
        {text}
      </div>
    );
  }
}

export default DataPartnerLiveButtons;
