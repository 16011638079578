import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {format} from "date-fns";

const DialogContent = withStyles(theme => ({
    root: {
        width: 240,
        borderRadius: 8
    },
}))(MuiDialogContent);

let date = new Date();
const minExpirationDate = new Date(date.setTime( date.getTime() + 15 * 86400000 ));

class AroaActivityCalendarDialog extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            open: props.open || false,
            expirationDate: format(new Date(minExpirationDate), 'yyyy-MM-dd')
        };
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount() {
    }

    handleClose = () => {
        this.props.onSelectExpirationDate(this.state.expirationDate);
    };
    
    handleDateChange(date) {
        date = format(new Date(date), 'yyyy-MM-dd');
        this.setState({expirationDate: date});
    }

    render() {
        const {open} = this.state;
        return (
            <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={open}
                    disableEscapeKeyDown={true} disableBackdropClick={true}>
                <DialogContent className="text--align--center name-dialog-container">
                    <Grid className="margin-top-30">
                        <span className="text-prompt-operations">Please introduce the new expiration date</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                format="yyyy-MM-dd"
                                name="expirationDate"
                                minDate={minExpirationDate}
                                value={this.state.expirationDate}
                                onChange={this.handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <Grid className="margin-top-20 flex flex--justify--center">
                            <Button className="button-market-name-dialog"
                                    onClick={this.handleClose}
                                    color="primary"
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default AroaActivityCalendarDialog;
