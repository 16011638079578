import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import AroaParentComponent from "../../components/AroaParentComponent";
import {__ADD_ADVERTISERS_LIST, __ADD_ADVERTISERS_TO_USER} from "../../services/endpoint-manager";
import {showNotify} from "../AroaNotifier";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons/";
import AddEditAdvertiser from "../../components/AroaForms/AddEditAdvertiser";
import FormModal from "../../components/AroaModals/FormModal";
import AroaAdvertiserListItem from "../AroaAdvertiserListItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Scrollbar from "react-scrollbars-custom";

class AroaAdvertiserList extends AroaParentComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dspID: this.props.dspid,
            advertiserList: [],
            openAdvertiserAddDialog: false,
            openAdvertiserEditDialog: false
        };
        this.getAdvertiserList = this.getAdvertiserList.bind(this);
        this.deleteAdvertiser = this.deleteAdvertiser.bind(this);
        this.handleCloseAddAdvertiser = this.handleCloseAddAdvertiser.bind(this);
    }

    getAdvertiserList() {
        let _self = this;
        this.getXHRHandler().launchPost(__ADD_ADVERTISERS_LIST, {dspID: _self.state.dspID},
            (response) => {
                _self.setState({advertiserList: this.getXHRHandler().getSuccessData(response), loading: false});
            }, (error) => {
                showNotify({message: this.getXHRHandler().getErrorDescription(error)});
            });
    }

    componentDidMount() {
        this.getAdvertiserList();
    }

    deleteAdvertiser = (advertiserid) => {
        this.getXHRHandler()
            .launchDelete(__ADD_ADVERTISERS_TO_USER, {advertiserID: advertiserid}, (response) => {
                this.setState({advertiserList: []});
                this.getAdvertiserList();
            }, (error) => {
                showNotify({message: this.getXHRHandler().getErrorDescription(error)});
            });
    };

    handleCloseAddAdvertiser = () => {
        this.setState({openAdvertiserAddDialog:false});
        this.setState({openAdvertiserEditDialog:false});
        this.getAdvertiserList();
    };

    editAdvertiser = (advertiser) => {
        this.setState({openAdvertiserEditDialog: true, selectedAdvertiser: advertiser});
    };

    render() {
        let advertiserList = this.state.advertiserList;

        const loader = (
            <Grid container justify={"center"}>
                <CircularProgress/>
            </Grid>
        );

        const empty = (
            <Grid className="flex margin-top-30 margin-bottom-50">
                    <Typography className="text--no--advertisers">There is no associated advertiser to this DSP</Typography>
            </Grid>
        );

        return (
            <Grid>
                <Grid className="margin-bottom-20 flex flex--content-start">
                    <Typography className="advertisers-title">
                        Advertiser List
                    </Typography>
                    <Grid className="margin-left-30">
                    <IconButton
                        onClick={() => this.setState({openAdvertiserAddDialog: true})}
                    >
                    <FontAwesomeIcon
                        icon={faPlusCircle} size='2x'
                        color={"#015783"}
                    />
                    </IconButton>
                    </Grid>
                </Grid>
                <Grid className="advertisers-body">
                    <Scrollbar style={{height: 200}}>
                        {
                            this.state.loading ? loader :
                                advertiserList.length > 0
                                    ? advertiserList.map((advertiser) =>
                                        <AroaAdvertiserListItem
                                            advertiser={advertiser}
                                            key={advertiser.advertiserid}
                                            onDelete={this.deleteAdvertiser}
                                            onEdit={this.editAdvertiser}
                                        />)
                                    : empty
                        }
                    </Scrollbar>
                </Grid>
                <FormModal opened={this.state.openAdvertiserAddDialog}
                           title="Add advertisers to your list"
                           closeIcon={true}
                           className="advertiser-modal"
                           onClose={() => this.setState({openAdvertiserAddDialog: false})}
                >
                    <AddEditAdvertiser onAccept={this.handleCloseAddAdvertiser} dspID={this.state.dspID}/>
                </FormModal>
                <FormModal opened={this.state.openAdvertiserEditDialog}
                           title="Edit advertiser"
                           closeIcon={true}
                           className="advertiser-modal"
                           onClose={() => this.setState({openAdvertiserEditDialog: false})}
                >
                    <AddEditAdvertiser onAccept={this.handleCloseAddAdvertiser} dspID={this.state.dspID} advertiser={this.state.selectedAdvertiser}/>
                </FormModal>
            </Grid>
        );
    }
}

export default AroaAdvertiserList;
