import React                                           from "react";
import PubSub                                          from "pubsub-js";
import {
    __AROA_AUDIENCE_NAME_INPUT,
    __AROA_DATABUYER_IS_APOLLO_CHANGE_ON_PANEL,
    __AROA_SEARCH_EVENT,
    __AROA_SELECT_BINARY_OPERATION,
    __AROA_SELECT_GROUP_BINARY_OPERATION,
    __AROA_SHOW_SEARCHCOMPO_ON_BAR
} from "../../services/event-subscriber";
import {
    Grid,
    LinearProgress
}                                                      from "@material-ui/core";
import {withStyles}                                    from '@material-ui/core/styles';
import SegmentCard, {getReadableNumberOfUniqueUsersFn} from "../AroaCard/SegmentCard";
import Tabs                                            from "@material-ui/core/Tabs";
import Tab                                             from "@material-ui/core/Tab";
import Typography                                      from "@material-ui/core/Typography";
import {
    DragDropContext,
    Draggable,
    Droppable
}                                                      from "react-beautiful-dnd";
import Paper                                           from "@material-ui/core/Paper";
import dndManager                                      from "../../services/dnd-manager";
import AroaMarketOperationDialog, {
    __AROA_SEGMENT_COMPOSER_GROUP,
    openAroaMarketOperationDialog
}                                                      from "../../atoms/AroaMarketOperationDialog";

import AroaMarketGroupOperationDialog, {
    __AROA_GROUP_SEGMENT_COMPOSER,
    openAroaMarketGroupOperationDialog
}                                                                       from "../../atoms/AroaMarketGroupOperationDialog";
import LookalikeSlider                                                  from "../LookalikeSlider";
import Button                                                           from "@material-ui/core/Button";
import Card                                                             from "@material-ui/core/Card";
import {DragIcon, NotFileIcon}                                          from "../../atoms/AroaIcons";
import {__SEGMENT_PRUNE_LIMIT}                                          from '../../services/consts';
import xhrHandler                                                       from "../../services/xhr-handler";
import {__AUDIENCE_TOTAL_COOKIES, __BUY_AUDIENCE}                       from "../../services/endpoint-manager";
import Notifier, {showNotify}                                           from "../../atoms/AroaNotifier";
import AroaMarketAudienceNameDialog, {openAroaMarketAudienceNameDialog} from "../../atoms/AroaMarketAudienceNameDialog";
import sessionManager, {
    __CURRENT_TRANSACTION,
    __SELECTED_DATABUYER,
    __SELECTED_DATABUYER_IS_APOLLO
} from "../../services/storage-manager";
import AroaParentComponent                                              from "../AroaParentComponent";
import Scrollbar                                                        from "react-scrollbars-custom";
import {setCartItemsCount}                                              from "../../redux/actions";
import {connect}                                                        from "react-redux";
import AroaMessageScreen                                                from "../../atoms/AroaMessageScreen";
import {userRoles}                                                      from "../../services/userRoles-manager";

const styles = {
    root: {
        flexGrow: 1,
        marginTop: 10,
        width: 100
    },
};
const DATABUYER_IS_APOLLO = "1";

const __AROA_ADD_SEGMENTS_TO_COMPOSER = 'AroaAddSegmentsToComposer';

/**
 * Helper functions to add/remove segments to/from composer region
 * */
let addSegmentToComposerFn;
let removeSegmentFromComposerFn;

/**
 * Hay tres areas droppables
 * 1.- El area de segmentos
 * 2.- El primer grupo de segmentos de audiencias
 * 3.- El segundo grupo de segmentos de audiencias
 *
 * Se ha establecido (por simplificar el desarrollo) que hayan unicamente dos grupos de segmentos
 * para componer una audiencia
 * Cada grupo de segmentos puede estar compuesto (eso si) por N segmentos
 * */
const droppableAreas = {
    segments: "segments",
    segmentComposerG1: "segmentComposerG1",
    segmentComposerG2: "segmentComposerG2"
};

class AroaSearchResults extends AroaParentComponent {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            value: 0,
            segments: [],
            segmentComposerG1: [],
            segmentComposerG2: [],
            segmentsOperationG1: "",
            segmentsOperationG2: "",
            segmentsG1G2Operation: "",
            audienceTotalCookies: 0,
            audienceQuery: "",
            dataPartnerList: [],
            loadingTotalCookies: false,
            noResults: false,
            prunedSegments: 0,
            showApollo: false
        };
        this.apolloAudienceNameTimeStampRecord = this.apolloAudienceNameTimeStampRecord.bind(this);
        this.myApolloSubscriber = PubSub.subscribe(__AROA_DATABUYER_IS_APOLLO_CHANGE_ON_PANEL, this.showApolloBuyButton);
        this.mySearchSubscriber = this.mySearchSubscriber.bind(this);
        this.myBinaryOperationSubscriber = this.myBinaryOperationSubscriber.bind(this);
        this.segmentDestructuring = this.segmentDestructuring.bind(this);
        this.segmentPruneComply = this.segmentPruneComply.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.renderDraggableArea = this.renderDraggableArea.bind(this);
        this.renderNoResults = this.renderNoResults.bind(this);
        this.searchToken = PubSub.subscribe(__AROA_SEARCH_EVENT,
            this.mySearchSubscriber);
        this.binaryOperationToken = PubSub.subscribe(__AROA_SELECT_BINARY_OPERATION,
            this.myBinaryOperationSubscriber);
        this.groupBinaryOperationToken = PubSub.subscribe(__AROA_SELECT_GROUP_BINARY_OPERATION,
            this.myGroupBinaryOperationSubscriber);
        this.audienceQueryEvent = PubSub.subscribe(__AROA_ADD_SEGMENTS_TO_COMPOSER,
            this.audienceQuerySubscriber);
        this.audienceNameEvent = PubSub.subscribe(__AROA_AUDIENCE_NAME_INPUT,
            this.audienceNameSubscriber);
        this.getBooleanOperationBetweenGroupsOfSegments = this.getBooleanOperationBetweenGroupsOfSegments.bind(this);
        this.getBooleanOperationBetweenSegmentsGroup = this.getBooleanOperationBetweenSegmentsGroup.bind(this);
        this.getAudienceTotalCookies = this.getAudienceTotalCookies.bind(this);
        this.addSegmentToComposer = this.addSegmentToComposer.bind(this);
        this.removeSegmentFromComposer = this.removeSegmentFromComposer.bind(this);
    }

    /**
     * Devuelve uno de los siguientes arrays
     * - this.state.segments|this.state.segmentComposerG1|this.state.segmentComposerG2
     * @param id
     * @returns Array
     */
    getList = id => this.state[droppableAreas[id]];

    componentDidMount() {
        addSegmentToComposerFn = this.addSegmentToComposer;
        removeSegmentFromComposerFn = this.removeSegmentFromComposer;
        this.showApolloBuyButton();

    }

    addSegmentToComposer = (segment, segmentIndex) => {
        let onDragEndParameter = {
            combine: null,
            destination: {droppableId: droppableAreas.segmentComposerG1, index: 0},
            draggableId: segment.id,
            mode: "FLUID",
            reason: "DROP",
            source: {index: segmentIndex, droppableId: droppableAreas.segments},
            type: "DEFAULT"
        };
        this.onDragEnd(onDragEndParameter);
    };

    removeSegmentFromComposer = (segment, segmentIndex) => {
        let onDragEndParameter = {
            combine: null,
            destination: {droppableId: droppableAreas.segments, index: segmentIndex},
            draggableId: segment.id,
            mode: "FLUID",
            reason: "DROP",
            source: {index: segment.originalResultPosition, droppableId: droppableAreas.segmentComposerG2},
            type: "DEFAULT"
        };

        /** Comprobamos si el segmento a borrar pertenece al grupo 1 */
        for (let counter = 0; counter < this.state.segmentComposerG1.length; counter++) {
            const current = this.state.segmentComposerG1[counter];
            if (segment.id === current.id) {
                onDragEndParameter.source = {index: counter, droppableId: droppableAreas.segmentComposerG1};
                this.onDragEnd(onDragEndParameter);
                return;
            }
        }

        /** Comprobamos si el segmento a borrar pertenece al grupo 2 */
        for (let counter = 0; counter < this.state.segmentComposerG2.length; counter++) {
            const current = this.state.segmentComposerG2[counter];
            if (segment.id === current.id) {
                onDragEndParameter.source = {index: counter, droppableId: droppableAreas.segmentComposerG2};
                this.onDragEnd(onDragEndParameter);
                return;
            }
        }
    };

    onDragEnd(result) {
        const {source, destination} = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            let state = {};
            state[source.droppableId] = dndManager.reorder(
                this.getList(source.droppableId),
                result.source.index,
                result.destination.index
            );

            this.setState(state);
        } else {

            /**
             * No se puede vaciar el primer grupo de segmentos habiendo segmentos en el segundo grupo
             * */
            if (source.droppableId === droppableAreas.segmentComposerG1) {
                if (this.getList(source.droppableId).length === 1) {
                    if (this.getList(droppableAreas.segmentComposerG2).length > 0) {
                        showNotify({message: "You can not empty this group having segments on the other group"});
                        return;
                    } else {
                        if (destination.droppableId === droppableAreas.segmentComposerG2 && this.getList(
                            droppableAreas.segmentComposerG2).length === 0) {
                            return;
                        }
                    }
                }
            }

            const result = dndManager.move(
                this.getList(source.droppableId), this.getList(destination.droppableId), source, destination);

            /**
             * Establecemos la relacion entre los segmentos del subgrupo siempre que haya al menos un segmento
             * */
            if ([droppableAreas.segmentComposerG1, droppableAreas.segmentComposerG2].indexOf(
                destination.droppableId) >= 0) {
                this.promptNewSegmentOperation(destination.droppableId);
            }

            /**
             * Establecemos la relacion entre los grupos de segmentos siempre que haya al menos un segmento en el G2
             *  */
            if (destination.droppableId === droppableAreas.segmentComposerG2) {
                this.promptNewGroupsOperation();
            }

            let state = {};
            state[source.droppableId] = result[source.droppableId];
            state[destination.droppableId] = result[destination.droppableId];
            this.setState(state);

            /** Avisamos de que han cambiado los segmentos */
            PubSub.publish(__AROA_ADD_SEGMENTS_TO_COMPOSER, this.state);
        }
    }

    initComponent = () => {
        const state = {
            results: [],
            value: 1,
            segmentComposerG1: [],
            segmentComposerG2: [],
            segmentsOperationG1: "",
            segmentsOperationG2: "",
            segmentsG1G2Operation: "",
            audienceTotalCookies: 0,
            audienceQuery: "",
            loadingTotalCookies: false,
            noResults: false,
        };
        this.setState(state);
    };

    mySearchSubscriber = (msg, data) => {
        this.setState({segments: this.segmentDestructuring(data)});
        /** TODO: Vaciar los grupos de segmentos del composer */
        //this.initComponent();
    };

    componentWillUnmount() {
        this.props.setSearchResults([]);
        this.props.setSearchTerm("");
    }

    audienceNameSubscriber = (msg, data) => {
        this.setState({audienceName: data.audienceName});
        this.buyAudience();
    };

    apolloAudienceNameTimeStampRecord = () => {
        this.setState({audienceName: Date.now()}, ()=> this.buyAudience());
    };

    myBinaryOperationSubscriber = (msg, data) => {
        switch (data.segmentComposerGroup) {
            case "segmentComposerG1" :
                this.setState({segmentsOperationG1: data.operation});
                break;

            case "segmentComposerG2" :
                this.setState({segmentsOperationG2: data.operation});
                break;

            default:
                break;
        }

        /** Avisamos de que han cambiado los segmentos */
        PubSub.publish(__AROA_ADD_SEGMENTS_TO_COMPOSER, this.state);
    };

    promptNewSegmentOperation = (destinationDroppableID) => {
        if (this.getList(destinationDroppableID).length === 1) {
            let dialogProps = {};
            dialogProps[__AROA_SEGMENT_COMPOSER_GROUP] = destinationDroppableID;
            openAroaMarketOperationDialog(dialogProps);
        }
    };

    promptChangeSegmentOperation = (destinationDroppableID) => {
        if (this.getList(destinationDroppableID).length > 1) {
            let dialogProps = {};
            dialogProps[__AROA_SEGMENT_COMPOSER_GROUP] = destinationDroppableID;
            openAroaMarketOperationDialog(dialogProps);
        }
    };

    promptNewGroupsOperation = () => {
        if (this.state.segmentComposerG2.length === 0) {
            let dialogProps = {};
            dialogProps[__AROA_GROUP_SEGMENT_COMPOSER] = __AROA_GROUP_SEGMENT_COMPOSER;
            openAroaMarketGroupOperationDialog(dialogProps);
        }
    };

    promptChangeGroupsOperation = () => {
        if (this.state.segmentComposerG2.length > 0) {
            let dialogProps = {};
            dialogProps[__AROA_GROUP_SEGMENT_COMPOSER] = __AROA_GROUP_SEGMENT_COMPOSER;
            openAroaMarketGroupOperationDialog(dialogProps);
        }
    };

    myGroupBinaryOperationSubscriber = (msg, data) => {
        this.setState({segmentsG1G2Operation: data.operation});

        /** Avisamos de que han cambiado los segmentos */
        PubSub.publish(__AROA_ADD_SEGMENTS_TO_COMPOSER, this.state);
    };

    audienceQuerySubscriber = (msg, data) => {
        this.setAudienceQuery();
    };

    handleChange = (event, value) => {
        this.setState({value});
    };

    getRndInteger = (min, max) => {
        return Math.floor(Math.random() * (max - min)) + min;
    };

    segmentDestructuring = (dataPartnerData) => {
        let segmentResults = [];
        const composeQueryFragment = (segment) => {
            const jsonKey = segment.keyName === 'act' ? `json.ev.${segment.keyName}` : `json.tp.${segment.keyName}`;
            return `(json.dp.nm:${segment.dataPartnerName} AND ${jsonKey}:"${segment.segmentData.key}")`;
        };
        let prunedSegments = 0;

        dataPartnerData.map((currentDataPartner) => {
            Object.keys(currentDataPartner).map((currentJsonKey) => {
                if (['key', 'doc_count'].indexOf(currentJsonKey) < 0) {
                    if (currentDataPartner[currentJsonKey].buckets.length > 0) {
                        currentDataPartner[currentJsonKey].buckets.map((currentBucket) => {
                            if (this.segmentPruneComply(currentBucket)) {
                                const currentSegment = {
                                    dataPartnerName: currentDataPartner.key,
                                    keyName: currentJsonKey,
                                    originalResultPosition: segmentResults.length,
                                    segmentData: currentBucket,
                                    query: "",
                                    id: this.getRndInteger(10000, 100000)
                                };

                                /** build current segment query */
                                currentSegment.query = `( ( ${this.props.searchTerms} ) AND ( ${composeQueryFragment(
                                    currentSegment)} ) )`;
                                
                                /**
                                 * Se limpian tambien los segmentos con key null
                                 * */
                                if (currentSegment.segmentData.key.length > 0){
                                    segmentResults.push(currentSegment);
                                    prunedSegments++;
                                }
                            } else {
                                prunedSegments++;
                            }
                            return true;
                        });
                    }
                }
                return true;
            });
            return true;
        });

        if (segmentResults.length > 0) {
            PubSub.publish(__AROA_SHOW_SEARCHCOMPO_ON_BAR,
                {showSearchBar: true, searchTerms: this.props.searchTerms});
        }

        this.setState({noResults: segmentResults.length === 0, prunedSegments});
        return segmentResults;
    };

    /**
     * Se prunan los segmentos con un numero minimo de cookies
     * y aquellos cuya "key" sea nula => No tiene sentido comprar algo que no este especificado
     *  */
    segmentPruneComply = segment => {
        return segment.datmean_ids.value + segment.mobile_ids.value >= __SEGMENT_PRUNE_LIMIT ;
    };

    /**
     * Devuelve la operacion booleana entre los segmentos del mismo grupo (el grupo se pasa por parametro)
     * @return string AND|OR|NOT
     */
    getBooleanOperationBetweenSegmentsGroup = (nameOfGroup) => {
        let operation = "";

        switch (nameOfGroup) {
            case "segmentComposerG1":
                if (this.state.segmentComposerG1.length > 1)
                    operation = this.state.segmentsOperationG1;

                break;

            case "segmentComposerG2":
                if (this.state.segmentComposerG2.length > 1)
                    operation = this.state.segmentsOperationG2;

                break;

            default:
                operation = "Error!";
                console.error("Incorrect groupName " + nameOfGroup);
                break;
        }

        return operation;
    };

    /**
     * Devuelve la operacion booleana entre los dos grupos de segmentos
     * @return string AND|OR|NOT
     */
    getBooleanOperationBetweenGroupsOfSegments = () => {
        if (this.state.segmentComposerG2.length > 0)
            return this.state.segmentsG1G2Operation;
    };

    buyAudience = () => {
        const actualDatabuyerIsApollo = sessionManager.getValue(__SELECTED_DATABUYER_IS_APOLLO);
        let toApollo = this.state.showApollo;

        if (!actualDatabuyerIsApollo)
            showNotify({message: "Please select first a databuyer in your profile"});
        else {
            /**apollo almacenamos la variable que marcara las condiciones de la audiencia propia de apollo */
            let apolloValue;
            if (toApollo && actualDatabuyerIsApollo===DATABUYER_IS_APOLLO)
            {
                apolloValue=1;
            }else{
                apolloValue=0;
            }

            const data = {
                audiences: [
                    {
                        dataBuyerID: sessionManager.getValue(__SELECTED_DATABUYER),
                        query: this.state.audienceQuery,
                        audienceName: this.state.audienceName,
                        recommended: 0,
                        apollo : apolloValue,
                        segments: []
                    }
                ]
            };


            if (sessionManager.keyExists(__CURRENT_TRANSACTION)) {
                data.transactionID = sessionManager.getValue(__CURRENT_TRANSACTION);
            } else {
                this.props.setCartItemsCount(0);
            }

            const buildSegment = (segment) => {
                return {
                    dataPartnerName: segment.dataPartnerName,
                    type: segment.keyName,
                    value: segment.segmentData.key,
                    numCookies: segment.segmentData.datmean_ids.value + segment.segmentData.mobile_ids.value
                }
            };

            const buildSegmentList = (arrayOfGroup) => {
                arrayOfGroup.map((currentSegment) => {
                    data.audiences[0].segments.push(buildSegment(currentSegment));
                    return true;
                });
            };

            const checkAudienceSegments = () => {
                if (data.audiences[0].segments.length < 1) {
                    throw new Error("There is no segments");
                }
                return true;
            };

            /*** Recorremos los segmentos del G1* */
            buildSegmentList(this.state.segmentComposerG1);
            buildSegmentList(this.state.segmentComposerG2);


            try {
                /** En algunas ocasiones sucede que pasa por aqui varias veces y no hay segmentos!!!! */
                if (checkAudienceSegments()) {
                    this.getXHRHandler().launchPost(__BUY_AUDIENCE, data, (response) => {
                        const transactionData = this.getXHRHandler().getSuccessData(response);
                        sessionManager.setValue(__CURRENT_TRANSACTION, transactionData.transactionID);
                        this.props.setCartItemsCount(transactionData.audiences.length);
                    }, (error) => {
                        showNotify({message: this.getXHRHandler().getErrorDescription(error)});
                    });
                }
            } catch (e) {
                //showNotify({message: 'There is no segments on this audience!'});
                console.info('there is no segments on this audience yet');
            }
        }
    };

    canBuyAudience = () => {
        return this.state.audienceTotalCookies > 0;
    };

    /**Funciones relativas a Apollo */

    showApolloBuyButton = () => {
        const actualDatabuyerIsApollo = sessionManager.getValue(__SELECTED_DATABUYER_IS_APOLLO);
        const rolApollo = userRoles.isApollo();
        if (actualDatabuyerIsApollo === DATABUYER_IS_APOLLO && rolApollo === true) {
           this.setState({showApollo: true});
        } else {
            this.setState({showApollo: false});
        }
    };

    renderDraggableArea = props => {
        const {items} = props;
        const segments = this.state[items];

        return segments.map((item, segmentIndex) => (
            <Draggable key={item.id} draggableId={item.id} index={segmentIndex}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={dndManager.getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}>
                        <SegmentCard key={segmentIndex} segmentIndex={segmentIndex} segment={item}
                                     hideActions={[droppableAreas.segmentComposerG1,
                                         droppableAreas.segmentComposerG2].indexOf(
                                         items) >= 0}/>
                    </div>
                )}
            </Draggable>
        ))
    };

    /**
     * Devuelve el numero total de cookies
     */
    getAudienceTotalCookies = () => {
        /** El back le agrega los slash automaticamente a la query */

        if (this.state.audienceQuery.length > 0) {
            this.setState({loadingTotalCookies: true});
            let axiosHandler = new xhrHandler();
            axiosHandler.launchPost(__AUDIENCE_TOTAL_COOKIES, {query: this.state.audienceQuery, dpList:this.state.dataPartnerList}, (response) => {
                const totalCookies = axiosHandler.getSuccessData(response).totalCookies;
                this.setState({audienceTotalCookies: totalCookies});
                this.setState({loadingTotalCookies: false});
            }, (error) => {
                console.error(error);
            });
        }
    };

    renderNoResults = props => {
        if (this.state.noResults) {
            return (
                <AroaMessageScreen title={"No results"}
                                   icon={<NotFileIcon classes="message-screen__icon" active={true}/>}/>
            );
        } else {
            return '';
        }
    };

    /**
     * Establece la pseudo query de segmentos que componen la audiencia
     */
    setAudienceQuery = () => {
        let audienceQuery = "";
        let queryG1 = "";
        let queryG2 = "";
        let dataPartnerList = [] ;
        
        /** Local function to add an element to an array if this not exists */
        const addElementIfNotExist = (myArray, element)  =>{
           if (myArray.indexOf(element) === -1){
               myArray.push(element) ;
           }
           
           return myArray ;
        } ;

        if (this.state.segmentComposerG1.length > 0) {
            this.state.segmentComposerG1.map((segment, index) => {
                const fragment = segment.query;
                addElementIfNotExist(dataPartnerList, segment.dataPartnerName) ;
                if (index === 0) {
                    queryG1 += fragment
                } else {
                    queryG1 += ' ' + this.state.segmentsOperationG1.toLocaleUpperCase() + ' ' + fragment;
                }
                return true;
            });

            audienceQuery = `( ${queryG1} )`;
        }

        if (this.state.segmentComposerG2.length > 0) {
            this.state.segmentComposerG2.map((segment, index) => {
                const fragment = segment.query;
                //dataPartnerList.push(segment.dataPartnerName) ;
                addElementIfNotExist(dataPartnerList, segment.dataPartnerName) ;
                if (index === 0) {
                    queryG2 += fragment
                } else {
                    queryG2 += ' ' + this.state.segmentsOperationG2.toLocaleUpperCase() + ' ' + fragment;
                }

                return true;
            });

            audienceQuery += ` ${this.state.segmentsG1G2Operation.toLocaleUpperCase()} ( ${queryG2} )`
        }

        audienceQuery = `( ${audienceQuery} )`;
        this.setState({audienceQuery});
        this.setState({dataPartnerList}) ;
        this.getAudienceTotalCookies();
    };

    dropRegionsShouldBeShown = () => {
        return ((this.state.segments.length > 0) || (this.state.segmentComposerG1.length > 0 || this.state.segmentComposerG2.length > 0));
    };

    render() {
        const {value, segments, prunedSegments} = this.state;
        const label = (

            <Grid container>
                <Typography className={"My-Certificates"}>
                    {segments.length}
                </Typography>
                <Typography className={"My-Certificates"}>
                    &nbsp;segments found
                </Typography>
            </Grid>
        );
        const pruneSegmentLabelTab = (
            <Grid container>
                <Typography className="Manhattan-Mall-Times-Square-gris">
                    We have prune
                </Typography>
                <Typography className="Manhattan-Mall-Times-Square-gris">
                    &nbsp;{prunedSegments}&nbsp;segments
                </Typography>
            </Grid>
        );

        const groupOperator = this.getBooleanOperationBetweenGroupsOfSegments();
        const segment1 = this.getBooleanOperationBetweenSegmentsGroup("segmentComposerG1");
        const segment2 = this.getBooleanOperationBetweenSegmentsGroup("segmentComposerG2");

        if (this.props.searchLoading) {
            return (
                <div className={"linearProgress"}>
                    <LinearProgress variant="query"/>
                </div>
            );
        } else {
            if (this.dropRegionsShouldBeShown()) {
                return (
                    <div>
                        <Notifier/>
                        <AroaMarketAudienceNameDialog/>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Grid container>
                                <Grid item>
                                    <Droppable droppableId={droppableAreas.segments}>
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={dndManager.getListStyle(snapshot.isDraggingOver)}>
                                                <Grid className="menuTabs ">
                                                    <Tabs
                                                        name={"value"}
                                                        id={"value"}
                                                        value={value}
                                                        onChange={this.handleChange}
                                                        indicatorColor="primary"
                                                        textColor="primary" className="market__segment--tab">
                                                        <Tab className={"Rectangle My-Certificates"} label={label}/>
                                                        <Tab disabled label={pruneSegmentLabelTab}/>
                                                    </Tabs>
                                                    {value === 0 ?
                                                        <Paper className='overflow-scrollbar margin-bottom-50'
                                                               elevation={3}>
                                                            <Scrollbar
                                                                style={{width: 485, height: 485}}>
                                                                {this.renderDraggableArea(
                                                                    {items: droppableAreas.segments})}
                                                            </Scrollbar>
                                                        </Paper> : ""}

                                                </Grid>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </Grid>
                                <Grid item>
                                    <AroaMarketOperationDialog/>
                                    <AroaMarketGroupOperationDialog/>

                                    <Paper className="flex container-composer-audience margin-bottom-50 margin-left-10"
                                           elevation={0}>

                                        <Grid className="margin-bottom-15">
                                            <Typography className=" title-audience-composer"> Audience
                                                Composer</Typography>
                                        </Grid>

                                        <Grid>
                                            <Grid className="flex flex--centered margin-top-20">
                                                {
                                                    segment1
                                                        ?
                                                        <Grid style={{marginRight: 5}}>
                                                            <Button
                                                                variant="contained"
                                                                droppableid={droppableAreas.segmentComposerG1}
                                                                className={"operator-button--vertical operator--" + segment1}
                                                                onClick={() => this.promptChangeSegmentOperation(
                                                                    droppableAreas.segmentComposerG1)}
                                                            >
                                                                {segment1}
                                                            </Button>
                                                        </Grid>
                                                        : null
                                                }
                                                <Grid>
                                                    <Droppable droppableId={droppableAreas.segmentComposerG1}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}>
                                                                <Card className="drop-card-base">
                                                                    <Grid>
                                                                        <Grid>
                                                                            <DragIcon/>
                                                                        </Grid>
                                                                        <Grid>
                                                                            <Typography className="text-dropable-card">
                                                                                DROP HERE YOUR AUDIENCE
                                                                            </Typography>
                                                                            {this.renderDraggableArea(
                                                                                {items: droppableAreas.segmentComposerG1})}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Card>
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{margin: '10px 0'}}>
                                                {groupOperator
                                                    ? <Button
                                                        variant="contained"
                                                        className={"operator-button operator--" + groupOperator}
                                                        style={{padding: 0, width: 50}}
                                                        onClick={this.promptChangeGroupsOperation}
                                                    >
                                                        {groupOperator}
                                                    </Button>
                                                    : null
                                                }

                                            </Grid>
                                            <Grid className="flex flex--centered">
                                                {
                                                    segment2
                                                        ?
                                                        <Grid style={{marginRight: 5}}>
                                                            <Button
                                                                variant="contained"
                                                                droppableid={droppableAreas.segmentComposerG2}
                                                                className={"operator-button--vertical operator--" + segment2}
                                                                onClick={() => this.promptChangeSegmentOperation(
                                                                    droppableAreas.segmentComposerG2)}
                                                            >
                                                                {segment2}
                                                            </Button>
                                                        </Grid>
                                                        : null
                                                }
                                                {
                                                    this.state.segmentComposerG1.length > 0
                                                        ?
                                                        <Grid>
                                                            <Droppable droppableId={droppableAreas.segmentComposerG2}>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}>
                                                                        <Card className="drop-card-base">
                                                                            <Grid>
                                                                                <Grid>
                                                                                    <DragIcon/>
                                                                                </Grid>
                                                                                <Grid>
                                                                                    <Typography
                                                                                        className="text-dropable-card">
                                                                                        &nbsp;
                                                                                    </Typography>
                                                                                    {this.renderDraggableArea(
                                                                                        {items: droppableAreas.segmentComposerG2})}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Card>
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </Grid>
                                                        : null
                                                }
                                            </Grid>
                                            <Grid className="margin-top-20">
                                                <LookalikeSlider loading={this.state.loadingTotalCookies}
                                                                 users={getReadableNumberOfUniqueUsersFn(
                                                                     this.state.audienceTotalCookies)}/>
                                            </Grid>
                                            <Grid
                                                className="flex flex--end margin-top-20 margin-right-20 margin-bottom-20">
                                                <Grid className="margin-left-20">
                                                    {this.state.showApollo ?
                                                        <Button
                                                            className="button-buy"
                                                            type="submit" aria-label="buy"
                                                            disableFocusRipple={true}
                                                            disableRipple={true}
                                                            disabled={!this.canBuyAudience()}
                                                            onClick={()=> this.apolloAudienceNameTimeStampRecord()}
                                                            variant="contained">
                                                            Apollo Buy
                                                        </Button>
                                                        : ''
                                                    }

                                                </Grid>
                                                <Grid>
                                                    {
                                                        !this.state.showApollo ?
                                                            <Button
                                                                className="button-buy"
                                                                type="submit" aria-label="buy"
                                                                disableFocusRipple={true}
                                                                disableRipple={true}
                                                                onClick={openAroaMarketAudienceNameDialog}
                                                                disabled={!this.canBuyAudience()}
                                                                variant="contained">
                                                                BUY
                                                            </Button>
                                                        : ''
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </DragDropContext>
                    </div>
                );
            } else {
                return this.renderNoResults();
            }
        }
    }
}

export function addSegmentToComposer(segment, segmentIndex) {
    addSegmentToComposerFn(segment, segmentIndex);
}

export function removeSegmentFromComposer(segment, segmentIndex) {
    removeSegmentFromComposerFn(segment, segmentIndex);
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCartItemsCount: (cartItemCount) => {
            dispatch(setCartItemsCount(cartItemCount))
        }
    }
};

export default withStyles(styles)(connect(null, mapDispatchToProps)(AroaSearchResults));
