import React from "react";
import {Typography} from "@material-ui/core";

const AroaCartHeader = ({title,subtitle}) => {

    return (
        <div className="cart-header">
            <Typography variant="h4" color="textPrimary" gutterBottom align="left" className="title--big">
                {title}
            </Typography>
            <Typography variant="h6" color={"inherit"} gutterBottom align={"left"}>
                {subtitle}
            </Typography>
        </div>
    )
};

export default AroaCartHeader;
