import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";
import clsx from 'clsx';
import {StepConnector} from "@material-ui/core";
import {withStyles, makeStyles} from '@material-ui/core/styles';
import StepLabel from "@material-ui/core/StepLabel";

const ColorlibConnector = withStyles({
    active: {
        '& $line': {
            backgroundImage: 'linear-gradient(to top, #25b7ab, #2099d8)',
        },
    },
    completed: {
        backgroundImage: 'linear-gradient(to top, #25b7ab, #2099d8)',
        '& $line': {
            backgroundImage: 'linear-gradient(to top, #25b7ab, #2099d8)',
        },
    },
    line: {
        height: 2,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 24,
        height: 24,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage: 'linear-gradient(to top, #25b7ab, #2099d8)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage: 'linear-gradient(to top, #25b7ab, #2099d8)'
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const {active, completed} = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {props.icon}
        </div>
    );
}

class AroaStepper extends React.Component {
    constructor(props) {
        super(props);
        this._activeStep = props.activeState;
        this._optionalSteps = props.optionals;
        this._completed = props.completed;
        this._skipped = [];
        this._steps = props.steps;
        this.isStepComplete = this.isStepComplete.bind(this);
        this.isStepOptional = this.isStepOptional.bind(this);
        this.isStepSkipped = this.isStepSkipped.bind(this);
    }
    
    isStepOptional(step) {
        return this._optionalSteps.indexOf(step) >= 0;
    }
    
    isStepSkipped(step) {
        return this._skipped.indexOf(step) >= 0;
    }
    
    isStepComplete(step) {
        return this._completed.indexOf(step) >= 0;
    }
    
    render() {
        return (
            <div>
                <Stepper alternativeLabel nonLinear activeStep={this._activeStep} connector={<ColorlibConnector/>}>
                    {
                        this._steps.map((label, index) => {
                            const stepProps = {};
                            const buttonProps = {};
                            if (this.isStepOptional(index)) {
                                buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                            }
                            if (this.isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps} completed={this.isStepComplete(index)}>
                                    <StepLabel
                                        StepIconComponent={ColorlibStepIcon}
                                        {...buttonProps}
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                            )
                        })
                    }
                </Stepper>
            </div>
        );
    }
}

AroaStepper.propTypes = {
    completed: PropTypes.array.isRequired,
    activeState: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired
};

AroaStepper.defaultProps = {
    skipped: [],
    optionals: [],
    activeState: 0
};

export default AroaStepper;
