import React, {Component} from "react";
import {ImageUnavailableIcon} from "../AroaIcons";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AroaDialog from "../AroaDialog";

class AroaDSPListItem extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {openDeleteDialog: false};
        this.openDeleteDialog = this.openDeleteDialog.bind(this);
    }
    
    deleteDSP = () => {
        if (this.props.onEnable) this.props.onEnable(this.props.item.dspid);
        else this.props.onDisable(this.props.item.dspid);
    };

    editDSP = () => {
        this.props.onEdit(this.props.item);
    };
    
    openAdvertiserList = () => {
        this.props.onAdvertiserList(this.props.item);
    };
    
    openDeleteDialog = () => {
        this.setState({openDeleteDialog: true});
    };
    
    closeDeleteDialog = () => {
        this.setState({openDeleteDialog: false});
    };

    render() {
        const {item} = this.props;
        const {seatname, seatid} = item;
        const {platformname} = item.platform;
        const {businessname} = item.databuyer;

        return (
            <Paper className="dsp-item">
                <Grid container alignItems={"center"} justify={"space-between"} className="height--full">
                    <Grid className="flex flex--centered flex--item">
                        <ImageUnavailableIcon/>
                    </Grid>
                    <Grid className="flex flex--column height--full flex--around flex--top fix-width flex--item">
                        <span className="title">{platformname}</span>
                        <span>{seatname}</span>
                        <span>{seatid}</span>
                    </Grid>
                    <Grid className="flex flex--column height--full flex--around flex--bottom flex--item">
                        <span>{businessname}</span>
                        <Grid className="flex">
                            <span className="title" onClick={() => this.editDSP()}>Edit</span>
                            <span className="title--separator">|</span>
                            <span className="title" onClick={() => this.openAdvertiserList()}>Advertiser List</span>
                            <span className="title--separator">|</span>
                            {
                                this.props.onEnable
                                    ? <span className="title" onClick={() => this.openDeleteDialog()}>Enable</span>
                                    : <span className="title" onClick={() => this.openDeleteDialog()}>Disable</span>
                            }
                        </Grid>
                    </Grid>
                    {this.state.openDeleteDialog
                        ? <AroaDialog title={this.props.onEnable ? "Do you want to enable this dsp?" : "Do you want to disable this dsp?"}
                                      text=""
                                      cancel={true}
                                      accept={true}
                                      acceptText={this.props.onEnable ? "Enable": "Disable"}
                                      acceptHandler={() => this.deleteDSP()}
                                      cancelHandler={() => this.closeDeleteDialog()}
                                      open={this.state.openDeleteDialog}
                        />
                        : null
                    }
                    
                </Grid>
            </Paper>
        );
    }
}

export default AroaDSPListItem;
