import React from "react";
import Grid from "@material-ui/core/Grid";
import { Line } from "react-chartjs-2";

class DataPartnerViewGraph extends React.Component {
  constructor() {
    super();

    this.months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
  }
  transformAudienceData = (audienceData) => {
    const labels = audienceData.map(
      (audience) => this.months[parseInt(audience.month) - 1]
    );
    const datasets = [
      {
        label: "Vendor Rev Share",
        fillColor: "rgba(220,220,220,0.2)",
        strokeColor: "rgba(220,220,220,1)",
        pointColor: "rgba(220,220,220,1)",
        pointStrokeColor: "#fff",
        pointHighlightFill: "#fff",
        pointHighlightStroke: "rgba(220,220,220,1)",
        data: audienceData.map((audience) => parseFloat(audience.vendorRev)),
      },
    ];

    return {
      labels,
      datasets,
    };
  };

  render() {
    const { data, onMonthChange } = this.props;
    let transformedData = {};
    if (data) {
      transformedData = this.transformAudienceData(data);
    }

    return (
      <Grid>
        <Grid className="flex flex__full flex--justify--center">
          <Grid className="width--75">
            <Line
              data={transformedData}
              options={{
                onClick: (evt, element) => {
                  console.log(evt, element)
                  if (element.length > 0){
                    const month =
                      transformedData.labels[element[0]._index];
                    const monthIndex = this.months.findIndex((x) => x === month);
                    onMonthChange(month, monthIndex+1);
                  }
                },
              }}
            ></Line>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default DataPartnerViewGraph;
