import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { showNotify } from "../../../atoms/AroaNotifier";
import AroaBar from "../../../components/AroaBar";
import AroaPage from "../../../components/AroaPage";
import styles from "../../../components/AroaPage/aroatheme";
import AroaParentComponent from "../../../components/AroaParentComponent";
import CostsUploaderData from "../../../components/CostsUploaderData";
import {
  __COSTS_CONSOLIDATE,
  __COSTS_UPLOAD,
} from "../../../services/endpoint-manager";
import xhrHandler from "../../../services/xhr-handler";

class CostsUploaderBody extends AroaParentComponent {
  constructor(props) {
    super(props);

    this.state = {
      audienceRegEx: "([0-9]{14,20})",
      audienceColumn: 0,
      revenueColumn: 0,
      conversionRevenue: "NoConvertir",
      conversion: "0.8585",
      currencies: [],
      reportDate: new Date().toISOString().substring(0, 10),
      page: 1,
      letters: [],
      segmentData: [],
    };
    this.axiosHandler = new xhrHandler();
  }

  componentDidMount() {
    this.getLetters();
  }

  getLetters() {
    this.setState({
      letters: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "Ñ",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
    });
  }

  submit = () => {
    const formData = new FormData();
    formData.append("excel", this.state.files[0]);
    formData.append("filepage", this.state.page);
    formData.append("filedate", this.state.reportDate);
    formData.append("audienceIDColumn", this.state.audienceColumn);
    formData.append("revenueColumn", this.state.revenueColumn);
    formData.append("currencyConversion", this.state.conversionRevenue);
    formData.append("conversionCustom", this.state.conversion ?? 1);
    formData.append("audienceIDRegex", this.state.audienceRegEx);

    this.axiosHandler.launchPost(
      __COSTS_UPLOAD,
      formData,
      (response) => {
        let segmentData = this.axiosHandler.getSuccessData(response);
        segmentData.checkedAudiences = Object.keys(segmentData.dataset).reduce(
          (prev, audience) => ({ ...prev, [audience]: true }),
          {}
        );
        this.setState({ segmentData });
      },
      (error) => {
        showNotify({ message: this.axiosHandler.getErrorDescription(error) });
        this.setState({ loadingLoader: false });
      }
    );
  };

  uploadData = () => {
    const data = {
      dataToDB: this.state.segmentData.json,
      dateToDB: this.state.reportDate,
      checkedAudiences: this.state.segmentData.checkedAudiences,
    };

    this.axiosHandler.launchPost(
      __COSTS_CONSOLIDATE,
      data,
      (response) => {
        let segmentData = this.axiosHandler.getSuccessData(response);
        this.setState(
          { segmentData },
          showNotify({ message: "Uploaded correctly" })
        );
      },
      (error) => {
        showNotify({ message: this.axiosHandler.getErrorDescription(error) });
        this.setState({ loadingLoader: false });
      }
    );
  };

  onAudienceChange = (e) => {
    const segmentData = this.state.segmentData;
    const { name, checked } = e.target;
    segmentData.checkedAudiences[name] = checked;

    this.setState({ segmentData });
  };

  render() {
    return (
      <Grid>
        <Grid className="margin-top-10">
          <Grid className="width--full">
            <Typography varian="h1"> File Configuration</Typography>
          </Grid>
          <Paper
            className="flex flex__full flex--justify--spacebetween"
            elevation={0}
          >
            <Grid className="flex flex--justify--spacebetween width--full">
              <FormControl required>
                <span className="text-prompt-operations">File</span>
                <TextField
                  onChange={(e) => {
                    this.setState({ files: e.target.files });
                  }}
                  className="report_file"
                  id="report_file"
                  name="report_file"
                  type={"file"}
                />
              </FormControl>
              <FormControl required>
                <span className="text-prompt-operations">Report Date</span>
                <TextField
                  onChange={(e) =>
                    this.setState({ reportDate: e.target.value })
                  }
                  className="report_date"
                  id="report_date"
                  name="report_date"
                  type={"date"}
                  value={this.state.reportDate}
                />
              </FormControl>
              <FormControl required>
                <span className="text-prompt-operations">Page</span>
                <TextField
                  onChange={(e) => this.setState({ page: e.target.value })}
                  className="page"
                  id="page"
                  name="page"
                  type={"number"}
                  min="1"
                  value={this.state.page}
                />
              </FormControl>
            </Grid>
          </Paper>
          <Grid className="width--full">File Configuration</Grid>
          <Paper
            className="flex flex__full flex--justify--spacebetween"
            elevation={0}
          >
            <Grid className="flex flex--justify--spacebetween width--full">
              <FormControl variant="outlined" required>
                <span className="text-prompt-operations">
                  Columna AudienceID
                </span>
                <Select
                  value={this.state.audienceColumn}
                  input={<OutlinedInput labelWidth={0} />}
                  name="audienceColumn"
                  onChange={(e) =>
                    this.setState({ audienceColumn: e.target.value })
                  }
                >
                  <MenuItem disabled value={0}>
                    Select Audience Column
                  </MenuItem>
                  {this.state.letters.map((letter) => (
                    <MenuItem value={letter} key={letter}>
                      {`Columna ${letter}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" required>
                <span className="text-prompt-operations">Columna Revenue</span>
                <Select
                  value={this.state.revenueColumn}
                  input={<OutlinedInput labelWidth={0} />}
                  name="revenueColumn"
                  onChange={(e) =>
                    this.setState({ revenueColumn: e.target.value })
                  }
                >
                  <MenuItem disabled value={0}>
                    Select Revenue Column
                  </MenuItem>
                  {this.state.letters.map((letter) => (
                    <MenuItem value={letter} key={letter}>
                      {`Columna ${letter}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" required>
                <span className="text-prompt-operations">
                  Conversion Revenue
                </span>
                <Select
                  value={this.state.conversionRevenue}
                  input={<OutlinedInput labelWidth={0} />}
                  name="revenueColumn"
                  onChange={(e) =>
                    this.setState({ conversionRevenue: e.target.value })
                  }
                >
                  <MenuItem value={"NoConvertir"}>No Convertir</MenuItem>
                  <MenuItem value={"Custom"}>Custom</MenuItem>
                  {this.state.currencies.map((currency) => (
                    <MenuItem value={currency} key={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {this.state.conversionRevenue === "Custom" ? (
                <FormControl required>
                  <span className="text-prompt-operations">Conversion</span>
                  <TextField
                    className="conversion"
                    id="conversion"
                    name="conversion"
                    type={"number"}
                    step={"0.0001"}
                    min="0.0001"
                    value={this.state.conversion}
                    onChange={(e) =>
                      this.setState({ conversion: e.target.value })
                    }
                  />
                </FormControl>
              ) : (
                ""
              )}
              <FormControl required>
                <span className="text-prompt-operations">Audience RegEx</span>
                <TextField
                  className="audienceRegEx"
                  id="audienceRegEx"
                  name="audienceRegEx"
                  type={"text"}
                  value={this.state.audienceRegEx}
                  onChange={(e) =>
                    this.setState({ audienceRegEx: e.target.value })
                  }
                />
              </FormControl>
            </Grid>
          </Paper>
          <Button
            className="button-market-name-dialog margin-top-20"
            onClick={this.submit}
            color="primary"
          >
            Submit
          </Button>
        </Grid>
        {this.state.segmentData.dataset ? (
          <Grid className="margin-top-10 width--full text--align--left">
            <Grid className="margin-bottom-10 text--align-right">
              <Button
                variant="contained"
                color="primary"
                onClick={this.uploadData}
              >
                Upload
              </Button>
            </Grid>
            <Typography variant="h4">
              N Audiences {Object.keys(this.state.segmentData.dataset).length}{" "}
              (Total Revenue:{" "}
              {parseFloat(this.state.segmentData.totalRevenue).toFixed(3)})
            </Typography>
            <CostsUploaderData
              data={this.state.segmentData}
              onAudienceChange={this.onAudienceChange}
            ></CostsUploaderData>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    );
  }
}

class CostsUploader extends AroaParentComponent {
  render() {
    if (this.isUserLogged()) {
      return (
        <AroaPage
          header={new AroaBar(this.props).render()}
          body={<CostsUploaderBody props={this.props} />}
        />
      );
    } else {
      this.gotoPath("/");
      return "";
    }
  }
}

export default withStyles(styles)(CostsUploader);
