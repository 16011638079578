import React                    from "react";
import Tab                      from "@material-ui/core/Tab";
import Tabs                     from "@material-ui/core/Tabs";
import DspStep2Active           from "../AroaCard/DspStep2Active";
import {Grid}                   from "@material-ui/core";
import AroaParentComponent      from "../AroaParentComponent";
import {__DSP_BY_DATABUYER, __MAIN_DATABUYER} from '../../services/endpoint-manager';
import {showNotify}             from "../../atoms/AroaNotifier";
import Typography               from "@material-ui/core/Typography";
import Scrollbar from "react-scrollbars-custom";

class DspStep2TabsCompo extends AroaParentComponent
{
	constructor(props)
	{
		super(props);
		
		this.state      = {
			loading: true,
			actualDatabuyer: false,
			value  : 0,
			dspList: [],
		};
		this.getDSPlist = this.getDSPlist.bind(this);
		this.getActualDatabuyer = this.getActualDatabuyer.bind(this);
	}
	
	getDSPlist()
	{
		let _self = this;
		this.getXHRHandler().launchGet(__DSP_BY_DATABUYER + parseInt(this.state.actualDatabuyer.dataBuyerID), (response) => {
			_self.setState({dspList: this.getXHRHandler().getSuccessData(response), loading: false});
		}, (error) => {
			showNotify({message: this.getXHRHandler().getErrorDescription(error)});
		});
	}
	
	getActualDatabuyer = () => {
		let _self = this;
		this.getXHRHandler().launchGet(__MAIN_DATABUYER, (response) => {
			_self.setState({actualDatabuyer: this.getXHRHandler().getSuccessData(response)}, function () {
				_self.getDSPlist();
			});
		}, (error) => {
			showNotify({message: this.getXHRHandler().getErrorDescription(error)});
		});
	};
	
	componentDidMount()
	{
		this.getActualDatabuyer();
	}
	
	handleChange = (event, value) => {
		this.setState({value});
	};
	
	render()
	{
		const {value}   = this.state;
		let dspActive   = this.state.dspList.filter(dspA => {
			return dspA.active === '1';
		});
		let dspInactive = this.state.dspList.filter(dspI => {
			return dspI.active === '0';
		});
		if(this.state.loading){
			return <Typography style={{margin: 30}} variant={'h5'}>Loading...</Typography>;
		}
		return (
			<Grid className="menuTabs">
				<Tabs
					name={"value"}
					id={"value"}
					value={value}
					onChange={this.handleChange}
					indicatorColor="primary"
					textColor="primary">
					<Tab label="Active"/>
					<Tab label="Inactive"/>
				</Tabs>
				<Scrollbar style={{height: 350}}>
					{value === 0 ? dspActive.map((currentDsp, _id) => (
						<DspStep2Active key={_id}
										plataformName={currentDsp.platform.platformname}
										seatName={currentDsp.seatname}
										seatId={currentDsp.seatid}
										dspData={currentDsp}
						
						/>)) : ""}
					{value === 1 ? dspInactive.map((currentDsp, _id) => (
						<DspStep2Active key={_id}
										plataformName={currentDsp.platform.platformname}
										seatName={currentDsp.seatname}
										seatId={currentDsp.seatid}
										dspData={currentDsp}
						/>)) : ""}
				</Scrollbar>
			</Grid>
		);
	}
}

export default DspStep2TabsCompo;
