import Card         from "@material-ui/core/Card";
import CardContent  from "@material-ui/core/CardContent";
import React        from "react";
import PropTypes    from 'prop-types';
import {withStyles} from "@material-ui/core";
import styles       from "../AroaPage/aroatheme";
import NumberFormat from 'react-number-format';
import Grid from "@material-ui/core/Grid";

const StyledCard = withStyles(() => ({
	root: {
		boxShadow      : '0 0 15px 0 rgba(0, 0, 0, 0.05)',
		backgroundColor: '#fff',
		borderRadius   : 10,
		height         : 117,
		width          : 254,
		border         : 'solid 1px rgba(156, 175, 195, 0)'
	},

}))(Card);

const AroaCard = (props) => {
	const {classes}   = props;
	const {amount}    = props;
	const {cardLabel} = props;

	return (
		<StyledCard>
			<CardContent className={classes.cardContent}>
                <Grid>
                    <span className={classes.cardData}>
                        <NumberFormat value={amount} displayType={'text'} thousandSeparator={true}/>
                    </span>
                </Grid>
                <Grid>
                    <span className={classes.cardText}>
                        {cardLabel}
                    </span>
                </Grid>
			</CardContent>
		</StyledCard>
	);
};

AroaCard.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AroaCard);