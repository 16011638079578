import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import CloseIcon from "@material-ui/icons/Close";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import React from "react";
import { Link, matchPath, withRouter } from "react-router-dom";
import styles from "../../components/AroaPage/aroatheme";
import AroaParentComponent from "../../components/AroaParentComponent";
import AroaSettingsPanel from "../../components/AroaSettingsPanel";
import HeaderSearchInput from "../../redux/containers/HeaderSearchInput";
import sessionStorage, {
  __SELECTED_DATABUYER,
} from "../../services/storage-manager";
import { userRoles } from "../../services/userRoles-manager";
import xhrHandler from "../../services/xhr-handler";
import {
  BellIcon,
  GraphIcon,
  ProfileIcon,
  SettingsIcon,
  ShoppingCartIcon,
} from "../AroaIcons";
import { showNotify } from "../AroaNotifier";
import AroaPanelParent from "../AroaPanelParent";
import AroaProfilePanel from "../AroaProfilePanel";
import { __BOTTOM_BADGE } from "../ButtonsStyles";

const BottomBadge = withStyles(() => __BOTTOM_BADGE)(Badge);
class AutenticatedButtons extends AroaParentComponent {
  constructor(props) {
    super(props);
    this.state = {
      settingsPanel: false,
      profilePanel: false,
    };
    this.escFunction = this.escFunction.bind(this);
    this.handleActivityClick = this.handleActivityClick.bind(this);
    this.isActivityScreenActive = this.isActivityScreenActive.bind(this);
    this.isCartScreenActive = this.isCartScreenActive.bind(this);

    this.axiosHandler = new xhrHandler();

    this.actualDatabuyer = sessionStorage.getValue(__SELECTED_DATABUYER);
  }

  dataBuyerHandler = () => {
    this.closePanels();
  };

  closePanels = () => {
    this.setState({ settingsPanel: false, profilePanel: false });
  };

  triggerSettingsPanel = () => {
    this.closePanels();
    if (this.actualDatabuyer) {
      this.setState({ settingsPanel: !this.state.settingsPanel });
    } else {
      showNotify({
        message: "Please select first a databuyer in your profile",
      });
    }
  };

  triggerUserPanel = () => {
    this.closePanels();
    this.setState({ profilePanel: !this.state.profilePanel });
  };

  changePanel = (panel) => {
    this.setState(panel);
  };

  escFunction(event) {
    if (event.keyCode === 27) {
      this.closePanels();
    }
  }

  getCartItemCount = () => {
    this.props.getCartItemsCount();
    return this.props.cartItemsCount;
  };

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  handleActivityClick = () => {
    this.actualDatabuyer = sessionStorage.getValue(__SELECTED_DATABUYER);
    if (this.actualDatabuyer) {
      this.props.history.push("/activity");
    } else {
      showNotify({
        message: "Please select first a databuyer in your profile",
      });
    }
  };

  handleBillingClick = () => {
    if (userRoles.isBilling(this.userEmail)) {
      this.props.history.push("/billing");
    } else {
      showNotify({
        message: "Please select first a databuyer in your profile",
      });
    }
  };

  handleConsolidatedClick = () => {
    if (userRoles.isBilling(this.userEmail)) {
      this.props.history.push("/consolidated");
    } else {
      showNotify({
        message: "Please select first a databuyer in your profile",
      });
    }
  };

  handleSpentClick = () => this.props.history.push("/spent");

  handlePastClick = () => this.props.history.push("/past");

  handleDataPartnerCurrentClick = () => this.props.history.push("/");

  handleDatPartnerLiveClick = () =>
    this.props.history.push("/dataPartner/live");

  isActivityScreenActive = () =>
    !!matchPath(this.props.location.pathname, "/activity");

  isBillingScreenActive = () =>
    !!matchPath(this.props.location.pathname, "/billing");

  isConsolidatedScreenActive = () =>
    !!matchPath(this.props.location.pathname, "/consolidated");

  isSpentScreenActive = () =>
    !!matchPath(this.props.location.pathname, "/spent");

  isCartScreenActive = () => !!matchPath(this.props.location.pathname, "/cart");

  isPastScreenActive = () => !!matchPath(this.props.location.pathname, "/past");

  isDataPartnerCurrentScreenActive = () => this.props.location.pathname === "/";

  isDataPartnerLiveScreenActive = () =>
    !!matchPath(this.props.location.pathname, "/dataPartner/live");

  isScreenActive = (path) => !!matchPath(this.props.location.pathname, path);

  renderCartIconLink = (classes, cartItemCount) => {
    return (
      <Link to="/cart/step1">
        <IconButton className="icon-button" size={"small"}>
          <BottomBadge
            color="secondary"
            badgeContent={cartItemCount}
            invisible={cartItemCount <= 0}
            className={classes.margin}
          >
            <ShoppingCartIcon
              classes="top-bar__icon-container"
              active={this.isCartScreenActive()}
            />
          </BottomBadge>
        </IconButton>
      </Link>
    );
  };

  render() {
    const { classes } = this.props;
    let cartItemCount = this.getCartItemCount();

    const cartIconLink = this.renderCartIconLink(classes, cartItemCount);

    const cartIconDisabled = (
      <div className="icon-button">
        <ShoppingCartIcon />
      </div>
    );

    return (
      <Grid
        className={classes.gridAutenticatedButtons}
        container
        justifyContent={"space-between"}
      >
        <Grid className="flex margin-left-20">
          {userRoles.currentView() === "dataBuyer" ? (
            <Grid className="flex flex--aligned margin-right-10">
              <Grid
                className="flex cursor-pointer flex--aligned margin-right-10"
                onClick={this.handleActivityClick}
              >
                <IconButton className="icon-button" size={"small"}>
                  <GraphIcon active={this.isActivityScreenActive()} />
                </IconButton>

                <Grid style={{ marginLeft: -7 }}>
                  <Typography
                    variant="overline"
                    className={
                      this.isActivityScreenActive()
                        ? "top-bar__text-link top-bar__text-link--active"
                        : "top-bar__text-link"
                    }
                  >
                    Activity
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                className="flex cursor-pointer flex--aligned margin-right-10"
                onClick={this.handleSpentClick}
              >
                <IconButton className="icon-button" size={"small"}>
                  <AssessmentIcon />
                </IconButton>
                <Grid>
                  <Typography
                    variant="overline"
                    className={
                      this.isSpentScreenActive()
                        ? "top-bar__text-link top-bar__text-link--active"
                        : "top-bar__text-link"
                    }
                  >
                    Spent
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                className="flex cursor-pointer flex--aligned margin-right-10"
                onClick={this.handleBillingClick}
              >
                <IconButton className="icon-button" size={"small"}>
                  <AccountBalanceWalletIcon />
                </IconButton>
                <Grid>
                  <Typography
                    variant="overline"
                    className={
                      this.isBillingScreenActive()
                        ? "top-bar__text-link top-bar__text-link--active"
                        : "top-bar__text-link"
                    }
                  >
                    Billing
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                className="flex cursor-pointer flex--aligned margin-right-10"
                onClick={this.handleConsolidatedClick}
              >
                <IconButton className="icon-button" size={"small"}>
                  <AccountBalanceIcon />
                </IconButton>
                <Grid>
                  <Typography
                    variant="overline"
                    className={
                      this.isConsolidatedScreenActive()
                        ? "top-bar__text-link top-bar__text-link--active"
                        : "top-bar__text-link"
                    }
                  >
                    Consolidated
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid className="flex flex--aligned margin-right-10">
              <Grid className="flex flex--aligned margin-right-10">
                <Grid
                  className="flex cursor-pointer flex--aligned margin-right-10"
                  onClick={this.handleDataPartnerCurrentClick}
                >
                  <IconButton className="icon-button" size={"small"}>
                    <GraphIcon
                      active={this.isDataPartnerCurrentScreenActive()}
                    />
                  </IconButton>

                  <Grid style={{ marginLeft: -7 }}>
                    <Typography
                      variant="overline"
                      className={
                        this.isDataPartnerCurrentScreenActive()
                          ? "top-bar__text-link top-bar__text-link--active"
                          : "top-bar__text-link"
                      }
                    >
                      Revenue Current Month
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="flex flex--aligned margin-right-10">
                <Grid
                  className="flex cursor-pointer flex--aligned margin-right-10"
                  onClick={this.handleDatPartnerLiveClick}
                >
                  <SubscriptionsIcon className="icon-button" size={"small"}>
                    <GraphIcon active={this.isDataPartnerLiveScreenActive()} />
                  </SubscriptionsIcon>

                  <Grid style={{ marginLeft: 7 }}>
                    <Typography
                      variant="overline"
                      className={
                        this.isDataPartnerLiveScreenActive()
                          ? "top-bar__text-link top-bar__text-link--active"
                          : "top-bar__text-link"
                      }
                    >
                      Live
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className="flex cursor-pointer flex--aligned margin-right-10"
                onClick={this.handlePastClick}
              >
                <DynamicFeedIcon className="icon-button" size={"small"}>
                  <GraphIcon active={this.isPastScreenActive()} />
                </DynamicFeedIcon>

                <Grid style={{ marginLeft: 7 }}>
                  <Typography
                    variant="overline"
                    className={
                      this.isPastScreenActive()
                        ? "top-bar__text-link top-bar__text-link--active"
                        : "top-bar__text-link"
                    }
                  >
                    Revenue Report
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                className="flex cursor-pointer flex--aligned margin-right-10"
                onClick={() => {}}
              >
                <CloseIcon className="icon-button" size={"small"}>
                  <GraphIcon active={this.isScreenActive("/blacklist")} />
                </CloseIcon>

                <Grid style={{ marginLeft: 7 }}>
                  <Typography
                    variant="overline"
                    className={
                      this.isScreenActive("/blacklist")
                        ? "top-bar__text-link top-bar__text-link--active"
                        : "top-bar__text-link"
                    }
                  >
                    Blacklist
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                className="flex cursor-pointer flex--aligned margin-right-10"
                onClick={() => {}}
              >
                <DataUsageIcon className="icon-button" size={"small"}>
                  <GraphIcon active={this.isScreenActive("/insight")} />
                </DataUsageIcon>

                <Grid style={{ marginLeft: 7 }}>
                  <Typography
                    variant="overline"
                    className={
                      this.isScreenActive("/insight")
                        ? "top-bar__text-link top-bar__text-link--active"
                        : "top-bar__text-link"
                    }
                  >
                    Insight
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <HeaderSearchInput />
        {userRoles.currentView() === "dataBuyer" ? (
          <Grid className="flex flex--aligned flex--end">
            <Grid>{cartItemCount > 0 ? cartIconLink : cartIconDisabled}</Grid>
            <Grid>
              <AroaPanelParent
                onOutsideClick={() =>
                  this.changePanel({ settingsPanel: false })
                }
              >
                <Grid>
                  <IconButton
                    className="icon-button"
                    size={"small"}
                    onClick={() => this.triggerSettingsPanel()}
                  >
                    <SettingsIcon
                      active={this.state.settingsPanel}
                      classes="top-bar__icon-container"
                    />
                  </IconButton>
                </Grid>
                {this.state.settingsPanel ? <AroaSettingsPanel /> : null}
              </AroaPanelParent>
            </Grid>
            <Grid>
              <BellIcon />
            </Grid>
            <Grid>
              <AroaPanelParent
                onOutsideClick={() => this.changePanel({ profilePanel: false })}
              >
                <Grid>
                  <IconButton
                    className="icon-button"
                    size={"small"}
                    onClick={() => this.triggerUserPanel()}
                  >
                    <ProfileIcon
                      active={this.state.profilePanel}
                      classes="top-bar__icon-container"
                    />
                  </IconButton>
                </Grid>
                {this.state.profilePanel ? <AroaProfilePanel /> : null}
              </AroaPanelParent>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Grid>
              <AroaPanelParent
                onOutsideClick={() => this.changePanel({ profilePanel: false })}
              >
                <Grid>
                  <IconButton
                    className="icon-button"
                    size={"small"}
                    onClick={() => this.triggerUserPanel()}
                  >
                    <ProfileIcon
                      active={this.state.profilePanel}
                      classes="top-bar__icon-container"
                    />
                  </IconButton>
                </Grid>
                {this.state.profilePanel ? <AroaProfilePanel /> : null}
              </AroaPanelParent>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(AutenticatedButtons));
