import React                                               from 'react';
import AroaParentComponent                                 from "../AroaParentComponent";
import {__AROA_SEARCH_ENGINE_ADDR}                         from "../../services/endpoint-manager";
import Notifier, {showNotify}                              from "../../atoms/AroaNotifier";
import {IconButton, InputAdornment, TextField, withStyles} from "@material-ui/core";
import SearchIcon                                          from '@material-ui/icons/Search';
import styles                                              from "../AroaPage/aroatheme";
import PubSub from "pubsub-js";
import {__AROA_SEARCH_EVENT} from "../../services/event-subscriber";

const SearchHeaderInput = withStyles(() => ({
	root: {
		height: 32,
		width : 270
	},
}))(TextField);

class AroaHeaderSearchComponent extends AroaParentComponent
{
	constructor(props)
	{
		super(props);
		this.state                 = {searchTerms: ""};
		
		this.fireSearch            = this.fireSearch.bind(this);
		this.handleSubmit          = this.handleSubmit.bind(this);
		this.handleChange          = this.handleChange.bind(this);
	}
	
	handleSubmit(event)
	{
		event.preventDefault();
		this.fireSearch();
	}
	
	handleChange(event)
	{
		this.setState({searchTerms: event.target.value});
	}
	
	fireSearch()
	{
		let self = this;
		if (this.state.searchTerms) {
			this.props.setSearchTerm(this.state.searchTerms);
			this.props.setSearchLoading(true);
			this.getXHRHandler().launchPost(__AROA_SEARCH_ENGINE_ADDR, this.state, (response) => {
				let results = self.getXHRHandler()
					.getSuccessData(response).aggregations.dp.buckets;
				self.props.setSearchResults(results);
				self.props.setSearchLoading(false);
				
				PubSub.publish(__AROA_SEARCH_EVENT, results);
				self.setState({searchTerms: ""});
			}, (error) => {
				showNotify({message: this.getXHRHandler().getErrorDescription(error)});
			});
		} else {
			showNotify({message: "You must to provide search term"});
		}
	}
	
	render()
	{
		const {classes} = this.props;
		
		return (
			<React.Fragment>
				{
					this.props.searchTerms && (!this.props.searchLoading || this.state.searchTerms)
						? <div>
							<Notifier/>
							<form method={"post"} onSubmit={this.handleSubmit}>
								<SearchHeaderInput
									className={classes.searchInput}
									id="searchTerms"
									type="text"
									name="searchTerms"
									margin="normal"
									placeholder={this.props.searchTerms}
									variant="outlined"
									autoComplete="off"
									onChange={this.handleChange}
									value={this.state.searchTerms}
									InputProps={{
										endAdornment: (
										   <InputAdornment>
											   <IconButton type="submit" onSubmit={this.handleSubmit}
														   className="icon-button">
												   <SearchIcon/>
											   </IconButton>
										   </InputAdornment>
									   )
								   }}/>
							</form>
						</div>
						: ""
				}
				
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(AroaHeaderSearchComponent);
