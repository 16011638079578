import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import groupBy from "lodash/fp/groupBy";
import React from "react";
import { withRouter } from "react-router";
import Scrollbar from "react-scrollbars-custom";
import AroaCostsAudienceRow from "../../atoms/AroaCostsAudienceRow";
import { NotFileIcon } from "../../atoms/AroaIcons";
import AroaMessageScreen from "../../atoms/AroaMessageScreen";
import { showNotify } from "../../atoms/AroaNotifier";
import { __ICON_BUTTON } from "../../atoms/ButtonsStyles";
import AroaBar from "../../components/AroaBar";
import AroaCalendar from "../../components/AroaCalendar";
import AroaPage from "../../components/AroaPage";
import styles from "../../components/AroaPage/aroatheme";
import AroaParentComponent from "../../components/AroaParentComponent";
import {
  __ACTVITY_STATES,
  __COSTS_OVERVIEW,
  __GET_ADVERTISERS_BY_DATABUYER,
} from "../../services/endpoint-manager";
import sessionManager, { __SELECTED_DATABUYER } from "../../services/storage-manager";
import xhrHandler from "../../services/xhr-handler";

import {min, max } from 'lodash/fp'

const IconButtonFilter = withStyles(() => __ICON_BUTTON)(Button);
const SearchAudienceInput = withStyles(() => ({
  root: {
    height: 32,
    width: 270,
    "& fieldset": {
      height: 45,
      borderColor: "#23AABF",
    },
    "& div": {
      height: 40,
    },
  },
}))(TextField);

class CostsBody extends AroaParentComponent {
  constructor(props) {
    super(props);

    this.state = {
      advertiserList: [],
      audienceList: [],
      stateList: [],
      selectedAdvertiser: false,
      selectedState: false,
      selectedStartDate: false,
      selectedEndDate: false,
      loadingLoader: true,
      refreshAll: true,
      filterAudience: "",
      currentRawAudienceList: [],
    };
    this.databuyerId = parseInt(sessionManager.getValue(__SELECTED_DATABUYER));
    this.axiosHandler = new xhrHandler();
  }

  componentDidMount() {
    this.getAudiences();
    this.getStateList();
    this.getAdvertiserList();
  }

  getAudiences = () => {
    const data = this.getFilters();

    this.axiosHandler.launchPost(
      __COSTS_OVERVIEW,
      data,
      (response) => {
        let audiences = this.axiosHandler.getSuccessData(response);
        const grouppedAudiences = groupBy("audienceID", audiences);
        this.setState({
          loadingLoader: false,
          audienceList: grouppedAudiences,
          currentRawAudienceList: grouppedAudiences,
        });
      },
      (error) => {
        showNotify({ message: this.axiosHandler.getErrorDescription(error) });
        this.setState({ loadingLoader: false });
      }
    );
  };

  getAdvertiserList = () => {
    this.axiosHandler.launchGet(
      __GET_ADVERTISERS_BY_DATABUYER(this.databuyerId),
      (response) => {
        const advertisers = this.axiosHandler
          .getSuccessData(response)
          .map((advertiser) => ({
            advertiserid: advertiser.advertiserid,
            name: advertiser.advertisername,
          }));
        this.setState({
          advertiserList: advertisers,
        });
      },
      (error) =>
        showNotify({ message: this.getXHRHandler().getErrorDescription(error) })
    );
  };

  getLoader = () => {
    const loader = (
      <Grid container justify={"center"}>
        <CircularProgress />
      </Grid>
    );

    if (this.state.loadingLoader === true) {
      return loader;
    }
  };

  getStateList = () => {
    this.axiosHandler.launchGet(
      __ACTVITY_STATES,
      (response) => {
        this.setState({
          stateList: this.axiosHandler.getSuccessData(response),
        });
      },
      (error) => {
        showNotify({
          message: this.getXHRHandler().getErrorDescription(error),
        });
      }
    );
  };

  getFilters = () => {
    if (this.state.refreshAll) this.setState({ loadingLoader: true });

    let filter = {};
    let includeFilter = this.hasSomeFilter();
    if (this.state.selectedStartDate) {
      filter = { ...filter, startDate: this.state.selectedStartDate };
    }
    if (this.state.selectedEndDate) {
      filter = { ...filter, endDate: this.state.selectedEndDate };
    }
    if (this.state.selectedState) {
      filter = { ...filter, stateID: parseInt(this.state.selectedState) };
    }
    if (this.state.selectedAdvertiser) {
      filter = {
        ...filter,
        advertiserID: parseInt(this.state.selectedAdvertiser),
      };
    }

    return includeFilter
      ? { dataBuyerID: this.databuyerId, filter: filter }
      : { dataBuyerID: this.databuyerId };
  };

  hasSomeFilter = () =>
    this.hasDateFilter() ||
    this.state.selectedState ||
    this.state.selectedAdvertiser;

  hasDateFilter = () =>
    this.state.selectedStartDate || this.state.selectedEndDate;

  onAdvertiserSelect = (event) => {
    this.setState(
      { selectedAdvertiser: event.target.value, refreshAll: true },
      this.getAudiences
    );
  };

  onDateSelect = (startDate, endDate) => {
    this.setState(
      {
        selectedStartDate: startDate.format("YYYY-MM-DD"),
        selectedEndDate: endDate.format("YYYY-MM-DD"),
        refreshAll: true,
      },
      this.getAudiences
    );
  };

  onStateSelect = (event) => {
    this.setState(
      { selectedState: event.target.value, refreshAll: true },
      this.getAudiences
    );
  };

  showAudiences = () => {
    if (this.state.audienceList) {
      return Object.entries(this.state.audienceList).map(([key, audiences]) => {
        if (audiences.length > 0) {
          const totalCost = audiences.reduce((prev, current) => parseFloat(current.costs) + prev, 0)
          const minDate = min(audiences.map(audience => audience.date))
          const maxDate = max(audiences.map(audience => audience.date))
          const audienceData = {
            id: key,
            name: audiences[0].audienceName,
            totalCosts: totalCost,
            minDate,
            maxDate
          }

          const sortedAudiences = audiences.sort((a, b) => new Date(b.date) - new Date(a.date))

          return (
            <AroaCostsAudienceRow
              key={key}
              audience={audienceData}
              audienceData={sortedAudiences}
              onStateChange={() => this.onAudienceStateChange()}
            />
          );
        } else {
          return "";
        }
      });
    } else {
      return (
        <AroaMessageScreen
          icon={<NotFileIcon classes="message-screen__icon" active={true} />}
          buttonText="Marketplace"
          linkTo="/"
          title="No audiences found for this DataBuyer with current filters..."
          message={
            "You can change your DataBuyer account in your user panel or buy an audience using the marketplace"
          }
        />
      );
    }
  };

  render() {
    return (
      <Grid>
        <Grid className="margin-top-10">
          <Paper
            className="flex flex__full flex--justify--spacebetween"
            elevation={0}
          >
            <Grid className="flex">
              <Grid>
                <AroaCalendar onApply={this.onDateSelect}>
                  <IconButtonFilter
                    variant="contained"
                    color="secondary"
                    className={
                      this.hasDateFilter() ? "activity__calendar--active" : ""
                    }
                  >
                    <span className="icon icon-calendar white" />
                    Pick Date
                  </IconButtonFilter>
                </AroaCalendar>
              </Grid>
              <Grid className="margin-left-20 ">
                <FormControl variant="outlined">
                  <Select
                    value={this.state.selectedState}
                    input={<OutlinedInput labelWidth={0} />}
                    onChange={this.onStateSelect}
                    name="state"
                    className="activity__state-filter"
                  >
                    <MenuItem value={false}>All States</MenuItem>
                    {this.state.stateList.map((state) => (
                      <MenuItem value={state.stateid} key={state.stateid}>
                        {state.statename}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid className="margin-left-20 ">
                <FormControl variant="outlined">
                  <Select
                    value={this.state.selectedAdvertiser}
                    input={<OutlinedInput labelWidth={0} />}
                    onChange={this.onAdvertiserSelect}
                    name="state"
                    className="activity__state-filter"
                  >
                    <MenuItem value={false}>All Advertisers</MenuItem>
                    {this.state.advertiserList.map((advertiser) => (
                      <MenuItem
                        value={advertiser.advertiserid}
                        key={advertiser.advertiserid}
                      >
                        {advertiser.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid className="margin-left-20 flex--end">
              <form onSubmit={this.handleSubmitSearchAudience}>
                <SearchAudienceInput
                  id="filtererAudience"
                  type="text"
                  name="filterAudience"
                  variant={"outlined"}
                  placeholder={"Search an Audience"}
                  autoComplete="off"
                  onChange={this.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          type="submit"
                          onSubmit={this.handleSubmitSearchAudience}
                          className="icon-button"
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </Grid>
          </Paper>
          <Grid container direction={"row"}>
            <Paper
              className="flex__full margin-top-30 margin-bottom-30"
              elevation={0}
            >
              <Scrollbar style={{ height: 730 }}>
                {this.state.loadingLoader
                  ? this.getLoader()
                  : this.showAudiences()}
              </Scrollbar>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

class Costs extends AroaParentComponent {
  render() {
    if (this.isUserLogged()) {
      return (
        <AroaPage
          header={new AroaBar(this.props).render()}
          body={<CostsBody props={this.props} />}
        />
      );
    } else {
      this.gotoPath("/");
      return "";
    }
  }
}

export default withRouter(withStyles(styles)(Costs));
