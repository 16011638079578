import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Button, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {__ACTION_BUTTON} from "../../atoms/ButtonsStyles";

const ColorButton = withStyles(() => (__ACTION_BUTTON))(Button);

const AroaMessageScreen = ({icon, title, message, showButton, buttonText, linkTo, onButtonClick, containerClasses}) => {
    return (
        <Grid style={{marginTop: 50}} className={containerClasses}>
            {icon}
            <Typography className="margin-top-30 title--big" variant={'h4'} color={"inherit"}>
                {title}
            </Typography>
            <Typography className="margin-top-10 title--big" variant={'h6'} color={"inherit"}>
                {message}
            </Typography>
            {showButton ?
                <Grid container justify={'center'} direction={'row'} className="margin-top-50">
                    <Grid>
                        <Link to={linkTo}>
                            <ColorButton aria-label={buttonText} className="button--big" onClick={onButtonClick}>
                                {buttonText}
                            </ColorButton>
                        </Link>
                    </Grid>
                </Grid>
                : null
            }
        </Grid>
    )
};

export default AroaMessageScreen;
