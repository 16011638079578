import React from "react";
import { Grid, Switch, Typography } from "@material-ui/core";
import AroaParentComponent from "../../components/AroaParentComponent";

class CostUploaderRow extends AroaParentComponent {
  render() {
    const { audKey, audArray, checkedAudiences } = this.props;
    return (
      <Grid className="margin-top-10" key={audKey}>
        <Grid className="flex flex__full flex--justify--spacebetween">
          <Typography variant="h5">
            <b>Audience</b> {audKey} (Aud Rev:{" "}
            {parseFloat(audArray["AudRevenue"]).toFixed(3)})
          </Typography>
          <Switch
            checked={checkedAudiences[audKey]}
            onChange={this.props.onCheckChange}
            name={audKey}
            inputProps={{ "aria-label": "checkbox" }}
          />
        </Grid>
        {Object.values(audArray["DataPartners"]).map((dpArray) => {
          return (
            <Grid
              className="margin-top-5 margin-left-10"
              key={dpArray["DataPartnerName"]}
            >
              <Typography variant="h6">
                <b>DataPartner</b> {dpArray["DataPartnerName"]} [DP Revenue:{" "}
                {parseFloat(dpArray["DataPartnerRevenue"]).toFixed(3)}]
                [Billing: {dpArray["DataPartnerPercent"]}%]
              </Typography>
              <Grid className="margin-left-20">
                {Object.values(dpArray["DataPartnersSegments"]).map(
                  (segArray) => {
                    return (
                      <Typography
                        variant="subtitle1"
                        key={segArray["SegmentID"]}
                      >
                        {parseFloat(segArray["SegmentID"]).toFixed(3)} {"=>"}{" "}
                        {segArray["SegmentRevenue"]}
                      </Typography>
                    );
                  }
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

export default CostUploaderRow;
