import { connect } from 'react-redux';
import {setAudiences, attributeChange} from '../../actions';
import AroaCartAudienceDateRow from "../../../components/AroaCartAudienceDateRow";

const getAudienceAttributes = (audienceAttributes) => {
    return audienceAttributes;
};

const mapStateToProps = (state) => {
    return {
        audienceAttributes: getAudienceAttributes(state.audienceAttributes)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAttributeChange: (id) => {
            dispatch(attributeChange(id))
        },
        setAudiences: (audiences) => {
            dispatch(setAudiences(audiences));
        }
    }
};

const CartAudiencesDates = connect(
    mapStateToProps,
    mapDispatchToProps
)(AroaCartAudienceDateRow);

export default CartAudiencesDates;
