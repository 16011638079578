import React, {Component} from "react";
import PropTypes from "prop-types";

class AroaPanelParent extends Component
{

    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        let isDialogOpen = document.getElementById("dialog") !== null;
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && !isDialogOpen) {
            this.props.onOutsideClick();
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        const {children} = this.props;

        return (
            <div ref={this.setWrapperRef}>
                {children}
            </div>
        );
    }
}

AroaPanelParent.propTypes = {
    onOutsideClick: PropTypes.func.isRequired
};

export default AroaPanelParent;
