import React                 from 'react';
import {withStyles}          from '@material-ui/core/styles';
import AroaParentComponent   from "../../../components/AroaParentComponent";
import AroaPage              from "../../../components/AroaPage";
import SimpleAroaBar         from "../../../atoms/AroaSimpleBar";
import styles                from '../../../components/AroaPage/aroatheme';
import AroaStepper from "../../../atoms/AroaStepper";
import Grid                  from "@material-ui/core/Grid";
import {FontAwesomeIcon}     from "@fortawesome/react-fontawesome";
import {faPlusCircle}        from "@fortawesome/free-solid-svg-icons/";
import {Typography}          from "@material-ui/core";
import {Link}                from "react-router-dom";
import Fab                   from "@material-ui/core/Fab";
import AdvertiserCard        from "../../../components/AroaCard/AdvertiserCard";
import IconButton from "@material-ui/core/IconButton";

class CreateUserAdvertiserStep2Body extends AroaParentComponent
{
	render()
	{
		return (
			<Grid>
				<AroaStepper
					steps={['Create an account', 'Add users', 'Add DSP', 'Add advertiser']}
					activeState={3}
					completed={[0, 1, 2]}
				/>

					<Grid className="margin-bottom-20 flex flex--content-start">
						<Typography className="advertisers-title">
							Advertiser List
						</Typography>
						<Grid className="margin-left-30">
							<IconButton
								onClick={() => this.gotoPath("/createadvertiser/step1")}
							>
								<FontAwesomeIcon
									icon={faPlusCircle} size='2x'
									color={"#015783"}
								/>
							</IconButton>
						</Grid>
					</Grid>

				<AdvertiserCard/>
				<Grid container justify={"flex-end"} className="margin-top-50">
					<Link to={"/createadvertiser/step3"}>
						<Fab variant="extended" aria-label="LOG IN" className="button-signup-login">
							NEXT STEP
						</Fab>
					</Link>
				</Grid>
				<Grid style={{margin: 15}}>
					<Link to={"/createdsp/step2"}>
						<Typography className="path-link" style={{float: "left"}}>
							&lt; Back to DSP list
						</Typography>
					</Link>
				</Grid>
			</Grid>)
	}
}

class CreateAdvertiserStep2 extends AroaParentComponent
{
	
	render()
	{
		if(this.isUserLogged()){
			return <AroaPage header={(new SimpleAroaBar(this.props).render())}
							 body={(new CreateUserAdvertiserStep2Body(this.props).render())}/>;
		}else{
			this.gotoPath('/');
			return '';
		}
	}
}

export default withStyles(styles)(CreateAdvertiserStep2);
