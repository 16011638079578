export const __BUTTON_GRADIENT1 = {
  root: {
    color: "#fff",
    backgroundImage: "linear-gradient(-135deg, #25b7ab, #2099d8)",
    height: 44,
    width: 255,
    borderRadius: 30,
    fontWeight: "bold",
    fontSize: 12,
    marginTop: 15,
  },
};

export const __BUTTON_SOLID_BLUE = {
  root: {
    color: "#fff",
    backgroundColor: "#015783",
    borderRadius: 30,
    height: 44,
    width: 255,
    fontWeight: "bold",
    fontSize: 12,
  },
};

export const __ACTION_BUTTON = {
  root: {
    color: "#fff",
    backgroundColor: "#015783",
    "&:hover": {
      backgroundColor: "#015783",
    },
    borderRadius: 30,
    height: 40,
    width: 167,
    fontSize: 12,
    fontWeight: "bold",
  },
};

export const __DISABLED_BUTTON = {
  root: {
    color: "#fff",
    backgroundColor: "#94999f",
    "&:hover": {
      backgroundColor: "#999999",
    },
    borderRadius: 30,
    height: 40,
    width: 167,
    fontSize: 12,
    fontWeight: "bold",
  },
};

export const __SECONDARY_BUTTON = {
  root: {
    width: 167,
    height: 40,
    borderRadius: 30,
    fontSize: 12,
    fontWeight: "bold",
    border: "solid 2px #015783",
    backgroundColor: "#ffffff",
  },
};

export const __BOTTOM_BADGE = {
  badge: {
    top: "71%",
    right: 8,
    backgroundColor: "#f36f5e",
    minWidth: 15,
    height: 15,
  },
};

export const __ICON_BUTTON = {
  root: {
    color: "#fff",
    backgroundColor: "#cad0d7",
    "&:hover": {
      backgroundColor: "#94999f",
    },
    borderRadius: 10,
    height: 40,
    width: 117,
    fontSize: 12,
    fontWeight: "bold",
  },
};

export const __CALENDAR = {
  fromDate: { backgroundColor: "#015783" },
  toDate: { backgroundColor: "#015783" },
  betweenDates: { backgroundColor: "#eeeef1" },
  hoverCell: { backgroundColor: "#015783", color: "#ffffff" },
  customRangeButtons: {
    backgroundColor: "#ffffff",
    color: "#015783",
    borderColor: "#015783",
    fontWeight: "bold",
    borderRadius: 20,
  },
  customRangeSelected: {
    backgroundColor: "#015783",
    fontWeight: "bold",
    borderRadius: 20,
  },
};
