import React from 'react';
import {Grid, withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/es/Paper/Paper";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/es/Checkbox/Checkbox";
import Typography from "@material-ui/core/es/Typography/Typography";
import Fab from "@material-ui/core/Fab";
import TextField from '@material-ui/core/TextField';
import Notifier, {showNotify} from "../../../../atoms/AroaNotifier";
import AroaParentComponent from "../../../../components/AroaParentComponent";
import Link from "@material-ui/core/Link";
import {__CREATENEWUSER, __LOGIN} from '../../../../services/endpoint-manager';
import sessionStorage, {
    __API_TOKEN,
    __CREATE_USER_MAIL_FIELD,
    __CREATE_USER_NAME_FIELD,
    __CREATE_USER_SURNAME_FIELD,
    localStorageManager
} from '../../../../services/storage-manager';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(1) * 8,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 350,
        alignItems: 'center',
        padding: `${theme.spacing(1) * 2}px ${theme.spacing(1) * 3}px ${theme.spacing(1) * 3}px`,
    },
    form: {
        width: 250, // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(1) * 3,
    },

});

class CreateUserForm extends AroaParentComponent {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            surname: "",
            mail: "",
            newPassword: "",
            newRepeatedPassword: "",
            privacy: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateNewPassword = this.validateNewPassword.bind(this);
        this.validateCheckPrivacyPolicy = this.validateCheckPrivacyPolicy.bind(this)
    }

    handleChange(event) {
        if (event.target.type === 'checkbox') {
            this.state[event.target.id] = event.target.checked;
        } else {
            this.state[event.target.id] = event.target.value;
        }
    }

    userLogin(mail, password) {
        let self = this;
        this.getXHRHandler().launchPost(__LOGIN, {usr: mail, pwd: password, rememberMe: true}, (response) => {
            /** guardamos el apiToken que viene del back */
            localStorageManager.setValue(__API_TOKEN, this.getXHRHandler().getSuccessData(response).apiToken);
            self.gotoPath('/createuser/step2');
        }, (error) => {
            showNotify({message: this.getXHRHandler().getErrorDescription(error)});
        });
    }

    handleSubmit(event) {
        this.state.mail = this.state.mail.trim();
        event.preventDefault();
        let self = this;
        this.state.isLogged = false;
        if (this.state.name && this.state.mail && this.state.newRepeatedPassword && this.state.newPassword && this.state.privacy) {
            if (this.validateNewPassword()) {
                this.getXHRHandler().launchPost(__CREATENEWUSER, this.state, (response) => {
                    sessionStorage.setValue(__CREATE_USER_NAME_FIELD, this.state.name);
                    sessionStorage.setValue(__CREATE_USER_MAIL_FIELD, this.state.mail);
                    sessionStorage.setValue(__CREATE_USER_SURNAME_FIELD, this.state.surname);
                    self.userLogin(this.state.mail, this.state.newPassword);
                }, (error) => {
                    showNotify({message: this.getXHRHandler().getErrorDescription(error)});
                });
            }
        } else {
            showNotify({message: "Please make sure you have fill all required fields"});
        }
    };

    validateNewPassword() {
        if (this.state.newPassword !== this.state.newRepeatedPassword) {
            showNotify({message: "New passwords mismatch"});
        }
        return this.state.newPassword === this.state.newRepeatedPassword;
    }

    validateCheckPrivacyPolicy() {
        if (this.state.privacy !== 'true') {
            showNotify({message: "Please read and accept privacy policy"});
        }
        return this.state.privacy === 'true'
    }

    render() {
        const label = (
            <Typography className="School-of">By submitting this form you agree to our
                <Link to={'/'}> privacy policy</Link>
            </Typography>
        );
        return (
            <React.Fragment>
                <Grid container justify={"center"}>
                    <Notifier/>
                    <form onSubmit={this.handleSubmit}>
                        <Grid item>
                            <Grid className={"margin-top-30"} style={{textAlign: "center"}}>
                                <Paper elevation={0}>
                                    <Grid className="margin-bottom-30 margin-left-15">
                                        <Typography className="welcome-title  text--align--left">
                                            Create an account
                                        </Typography>
                                    </Grid>
                                    <Grid container>
                                        <Grid className="margin-5 margin-left-20">
                                            <FormControl required>
                                                <TextField
                                                    className="textfield-Create"
                                                    id="name"
                                                    name="name"
                                                    type={"text"}
                                                    onChange={this.handleChange}
                                                    label="NAME"
                                                    autoComplete="name"
                                                    autoFocus
                                                    placeholder={"Your name"}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid className="margin-5 margin-left-20">
                                            <FormControl required>
                                                <TextField
                                                    className="textfield-Create"
                                                    id="surname"
                                                    name="surname"
                                                    type={"text"}
                                                    onChange={this.handleChange}
                                                    label="SURNAME"
                                                    autoComplete="surname"
                                                    placeholder={"Your surname"}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container className="margin-5 margin-left-20">
                                        <FormControl required>
                                            <TextField
                                                className="textfield-Create"
                                                id="mail"
                                                name="mail"
                                                type={"text"}
                                                onChange={this.handleChange}
                                                label="EMAIL"
                                                autoComplete="email"
                                                placeholder={"Your email"}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container>
                                        <Grid className="margin-5 margin-left-20">
                                            <FormControl required>
                                                <TextField
                                                    className="textfield-Create"
                                                    id="newPassword"
                                                    name="newPassword"
                                                    type="Password"
                                                    onChange={this.handleChange}
                                                    label="PASSWORD"
                                                    autoComplete="current-password"
                                                    placeholder={"Password"}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid className="margin-5 margin-left-20">
                                            <FormControl required>
                                                <TextField
                                                    className="textfield-Create"
                                                    id="newRepeatedPassword"
                                                    name="newRepeatedPassword"
                                                    type="Password"
                                                    onChange={this.handleChange}
                                                    label="PASSWORD REPEAT"
                                                    placeholder={"Confirm your new password"}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid className="margin-top-50 margin-right-20">
                                        <FormControlLabel
                                            control={<Checkbox color="primary" name="privacy"
                                                               id={"privacy"}
                                                               onChange={this.handleChange}/>}
                                            label={(label)}
                                        />
                                        <Fab type="submit" aria-label="Create User"
                                             onClick={this.handleSubmit}
                                             className="button-signup-login margin-15"
                                        >
                                            SIGN IN
                                        </Fab>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CreateUserForm);
