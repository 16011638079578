import React                    from 'react';
import AroaParentComponent      from "../../components/AroaParentComponent";
import {Grid}                   from "@material-ui/core";
import AroaCard                 from "../../components/AroaCard";
import Notifier                 from "../AroaNotifier";
import showNotify               from "../AroaNotifier";
import xhrHandler               from "../../services/xhr-handler";
import {__UNIQUEEVENTSANDUSERS} from '../../services/endpoint-manager';
import CircularProgress from "@material-ui/core/CircularProgress";

const __REFRESH_DATA_TIME = 30000;

class AroaCounterUU extends AroaParentComponent
{
	constructor(props)
	{
		super(props);
		this.state     = {
			uniqueUsers : "-",
			uniqueEvents: "-"
		};
		this.scheduler = "";
	}

	getData()
	{
		let _self          = this;
		let myAxiosHandler = new xhrHandler();

		myAxiosHandler.launchGet(__UNIQUEEVENTSANDUSERS, (response) => {
			_self.setState({uniqueUsers: parseInt(myAxiosHandler.getSuccessData(response).uniqueUsers)});
			_self.setState({uniqueEvents: parseInt(myAxiosHandler.getSuccessData(response).totalEvents.value)});
			this.scheduleGetData();
		}, (error) => {
			showNotify(myAxiosHandler.getErrorDescription(error));
		});
	}

	scheduleGetData()
	{
		this.scheduler = setTimeout(() => {
			this.getData()
		}, __REFRESH_DATA_TIME);
	}

    componentDidMount()
	{
		this.getData();
	}

	componentWillUnmount()
	{
		clearInterval(this.scheduler);
	}

	render()
	{
		if (isNaN(this.state.uniqueEvents) || isNaN(this.state.uniqueUsers)) {
			return (
				<Grid item xs={12} xl={12} md={12}>
					<div className={"margin-top-30"}>
						<CircularProgress/>
					</div>
				</Grid>
			);
		} else {
			return (
				<Grid item xs={12} xl={12} md={12}>
					<Notifier/>
					<div className={"margin-top-30"}>
						<Grid container spacing={3} justify={"center"}>
							<Grid item>
								<AroaCard amount={this.state.uniqueEvents} cardLabel={"events"}/>
							</Grid>
							<Grid item>
								<AroaCard amount={this.state.uniqueUsers} cardLabel={"unique users"}/>
							</Grid>
						</Grid>
					</div>
				</Grid>
			);
		}
	}
}

export default AroaCounterUU;