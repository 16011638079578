import environmentManager from "./environment-manager";
import axios from "axios";
import { __API_TOKEN, localStorageManager } from "./storage-manager";

const __DEFAULT_SUCCESS_MESSAGE = "Success";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorageManager.getValue(__API_TOKEN);

const removeTokenAndGOTOHome = () => {
  localStorageManager.remove(__API_TOKEN);
  window.location.href = "/";
};

class xhrHandler {
  constructor() {
    this._environmentManager = new environmentManager();
  }

  handleError(error, fail) {
    console.info(error);
    if (error.response instanceof Object) {
      if (error.response.status === 401) {
        removeTokenAndGOTOHome();
      } else {
        const errorReg = new RegExp(/(.*)api token is not present/g);
        if (errorReg.exec(error.response.data.response.error.description)) {
          removeTokenAndGOTOHome();
        }
      }
    }

    console.error();
    return fail(error);
  }

  launchPost(url, data, success, fail) {
    axios
      .post(this._environmentManager.getBackendURL() + url, data)
      .then((response) => success(response))
      .catch((error) => {
        this.handleError(error, fail);
      });
  }

  launchPut(url, data, success, fail) {
    axios
      .put(this._environmentManager.getBackendURL() + url, data)
      .then((response) => success(response))
      .catch((error) => {
        this.handleError(error, fail);
      });
  }

  launchDelete(url, data, success, fail) {
    axios
      .delete(this._environmentManager.getBackendURL() + url, { data: data })
      .then((response) => success(response))
      .catch((error) => {
        this.handleError(error, fail);
      });
  }

  launchGet(url, success, fail) {
    axios
      .get(this._environmentManager.getBackendURL() + url)
      .then((response) => success(response))
      .catch((error) => this.handleError(error, fail));
  }

  getErrorObject(error) {
    return error.response.data.response.error;
  }

  getErrorDescription(error) {
    if (!error.response) {
      return error.message;
    } else {
      return this.getErrorObject(error).description;
    }
  }

  getSuccessMessage(response) {
    if (!response.contents) {
      return __DEFAULT_SUCCESS_MESSAGE;
    } else {
      return response.contents.info;
    }
  }

  getSuccessData(response) {
    if (!response.data.response) {
      return response.data;
    } else {
      return response.data.response.contents.data;
    }
  }
}

export default xhrHandler;
