import React from 'react';
import AroaParentComponent from "../../components/AroaParentComponent";
import AroaBar from "../../components/AroaBar";
import AroaPage from "../../components/AroaPage";
import AroaMessageScreen from "../../atoms/AroaMessageScreen";
import {withStyles} from "@material-ui/core";
import styles from "../../components/AroaPage/aroatheme";
import {NotFoundIcon} from "../../atoms/AroaIcons";

class PageNotFoundBody extends AroaParentComponent
{
    
    render() {
        return (
            <AroaMessageScreen
                icon={<NotFoundIcon classes="message-screen__icon" active={true}/>}
                showButton={true}
                buttonText="Go to Home"
                linkTo="/"
                title="Error 404"
                message="Sorry, page not found."
            />
        )
    }
}

class PageNotFound extends AroaParentComponent
{
    
    render() {
        if (this.isUserLogged()) {
            return <AroaPage header={(new AroaBar(this.props).render())}
                             body={(<PageNotFoundBody/>)}/>;
        } else {
            this.gotoPath('/');
            return '';
        }
    }
}

export default withStyles(styles)(PageNotFound);
