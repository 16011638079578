import React               from 'react';
import AppBar              from "@material-ui/core/AppBar";
import Toolbar             from "@material-ui/core/Toolbar";
import Grid                from "@material-ui/core/Grid";
import AroaLogo            from "../../atoms/AroaLogo";
import Button              from "@material-ui/core/Button";
import {Link}              from "react-router-dom";
import AroaParentComponent from "../AroaParentComponent";
import HeaderBar from "../../redux/containers/HeaderBar";
import {Typography} from "@material-ui/core";

const LogoffButtons = (props) => {
	const {textAroaBar,trial, about, signup} = props;
	return (
		<Grid container style={{justifyContent: "space-between"}}>
            <Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Grid>
			{textAroaBar ?
				<Grid item xl={10} sm={8} xs={false} >
					<Typography component="span" variant="h5" className="landding--intro--text">
						Your 2nd Party Data Marketplace
					</Typography>
				</Grid> :''
			}
			<Grid style= {{flex : "auto"}} className="flex flex--end margin-bottom-5" item sm={false} xs={false}>
			{
				trial ?
					    <Button href={"https://datmean.com/#contact"}
								target="_blank"
								rel="noopener noreferrer"
								className="bar-button-link margin-right-10"
						>Trial
					    </Button>
					: ''
			}
			{
				about ?
					   <Button href={"http://www.datmean.com/"}
							   className="bar-button-link margin-right-10"
							   target="_blank"
							   rel="noopener noreferrer"
					   >About
					   </Button>
					: ''
			}
			{
				signup ?
					<Link to="/login">
						<Button className="button--login--landing"  variant="outlined">LOGIN</Button>
					</Link>
					: ''
			}
			</Grid>
		</Grid>
	);
};

LogoffButtons.defaultProps = {
    trial     : true,
	about   : true,
	signup     : true,
	textAroaBar : true
};

class AroaBar extends AroaParentComponent
{
	render()
	{
		const {classes}                = this.props;
		const {signup, about, trial, textAroaBar} = this.props;

		return (
			<AppBar position="sticky" color="default">
				<Toolbar className={classes.appBar}>
					<Grid container justify={"center"} wrap={"nowrap"} spacing={0}>
						<Grid item xs={12} sm={1} xl={1} className="flex flex--aligned">
							<AroaLogo width={"100rem"}/>
						</Grid>

						<Grid item xs={12} sm={11} xl={11} container justify={"flex-end"} alignItems={"center"}>
							{
								this.isUserLogged() ? <HeaderBar/> :
									<LogoffButtons textAroaBar={textAroaBar} signup={signup} about={about} trial={trial}/>
							}
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		);
	}
}

AroaBar.defaultProps = {
	platform: false,
	about   : false,
	login   : false,
	logout  : true
};

export default AroaBar;
