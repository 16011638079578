import React, {Fragment} from 'react';
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";

const aroaCartTable = ({headers, emptyLine, bodyClass, tableClass, children}) => {

    let emptyLineJSX = (<Fragment/>);
    if (emptyLine) {
        emptyLineJSX = (
            <TableRow>
                <TableCell/>
            </TableRow>
        );
    }

    let body = (<Fragment/>);
    if (children) {
        body = (
            <TableBody className={bodyClass}>
                {children}
                {emptyLineJSX}
            </TableBody>
        );
    }

    return (
        <Table className={tableClass}>
            <TableHead>
                <TableRow>
                    {headers.map(header => (
                        <TableCell key={header}>{header}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            {body}
        </Table>
    )
};

export default aroaCartTable;