import React from 'react'
// import miNube   from './../../assets/images/dpLogo/minube_gris.png'
import Grid from "@material-ui/core/Grid";


export const MiNubeLogo = (props) => {
    const {height, width, alt} = props;
    return (
        <React.Fragment>
            <Grid>
                <img src={require('../../assets/images/dpLogo/minube_transparente.png')}
                     alt={alt}
                     height={height}
                     width={width}

                />
            </Grid>
        </React.Fragment>
    );
};
MiNubeLogo.defaultProps = {
    height: 40,
    width: 40,
    alt: 'minube logo'
};

export const SercotelLogo = (props) => {
    const {height, width, alt} = props;
    return (
        <React.Fragment>
            <Grid>
                <img src={require('../../assets/images/dpLogo/sercotel_transparente.png')}
                     alt={alt}
                     height={height}
                     width={width}

                />
            </Grid>
        </React.Fragment>
    );
};
SercotelLogo.defaultProps = {
    height: 40,
    width: 40,
    alt: 'Sercotel logo'
};

export const GuiaDelOcioLogo = (props) => {
    const {height, width, alt} = props;
    return (
        <React.Fragment>
            <Grid>
                <img src={require('../../assets/images/dpLogo/Guia_del_ocio_gris_transparente.png')}
                     alt={alt}
                     height={height}
                     width={width}

                />
            </Grid>
        </React.Fragment>
    );
};
GuiaDelOcioLogo.defaultProps = {
    height: 40,
    width: 40,
    alt: 'guia del ocio logo'
};

export const AvanzaBusLogo = (props) => {
    const {height, width, alt} = props;
    return (
        <React.Fragment>
            <Grid>
                <img src={require('../../assets/images/dpLogo/logo_avanza transparente.png')}
                     alt={alt}
                     height={height}
                     width={width}

                />
            </Grid>
        </React.Fragment>
    );
};
AvanzaBusLogo.defaultProps = {
    height: 40,
    width: 40,
    alt: 'Avanzabus logo'
};

export const AvanzaBusGrisLogo = (props) => {
    const {height, width, alt} = props;
    return (
        <React.Fragment>
            <Grid>
                <img src={require('../../assets/images/dpLogo/avanza_cuadrado_gris_transp.png')}
                     alt={alt}
                     height={height}
                     width={width}
                />
            </Grid>
        </React.Fragment>
    );
};
AvanzaBusGrisLogo.defaultProps = {
    height: 40,
    width: 40,
    alt: 'Avanzabus logo'
};

export const DeportVillageLogo = (props) => {
    const {height, width, alt} = props;
    return (
        <React.Fragment>
            <Grid>
                <img src={require('../../assets/images/dpLogo/deporVillageLogo.png')}
                     alt={alt}
                     height={height}
                     width={width}

                />
            </Grid>
        </React.Fragment>
    );
};
DeportVillageLogo.defaultProps = {
    height: 40,
    width: 40,
    alt: 'deportvillage logo'
};

export const DeportVillageGrisLogo = (props) => {
    const {height, width, alt} = props;
    return (
        <React.Fragment>
            <Grid>
                <img src={require('../../assets/images/dpLogo/deporvillage_gris_transpa.png')}
                     alt={alt}
                     height={height}
                     width={width}

                />
            </Grid>
        </React.Fragment>
    );
};
DeportVillageGrisLogo.defaultProps = {
    height: 40,
    width: 40,
    alt: 'deportvillage logo'
};

export const BlueBananaLogoXS = (props) => {
    const {height, width, alt} = props;
    return (
        <React.Fragment>
            <Grid>
                <img src={require('../../assets/images/dpLogo/bluebanana_logo_xs.png')}
                     alt={alt}
                     height={height}
                     width={width}

                />
            </Grid>
        </React.Fragment>
    );
};
BlueBananaLogoXS.defaultProps = {
    height: 80,
    width: 80,
    alt: 'blue banana logo'
};

export const WortenLogoXS = (props) => {
    const {height, width, alt} = props;
    return (
        <React.Fragment>
            <Grid>
                <img src={require('../../assets/images/dpLogo/worten_logo_xs.png')}
                     alt={alt}
                     height={height}
                     width={width}

                />
            </Grid>
        </React.Fragment>
    );
};
WortenLogoXS.defaultProps = {
    height: 80,
    width: 80,
    alt: 'worten logo'
};
export const AvanzaLogoXS = (props) => {
    const {height, width, alt} = props;
    return (
        <React.Fragment>
            <Grid>
                <img src={require('../../assets/images/dpLogo/avanza_bus_logo_xs.png')}
                     alt={alt}
                     height={height}
                     width={width}

                />
            </Grid>
        </React.Fragment>
    );
};
AvanzaLogoXS.defaultProps = {
    height: 80,
    width: 80,
    alt: 'avanza-bus logo'
};

export const ThePhoneHouseLogoXS = (props) => {
    const {height, width, alt} = props;
    return (
        <React.Fragment>
            <Grid>
                <img src={require('../../assets/images/dpLogo/the_phone_house_logo_xs.png')}
                     alt={alt}
                     height={height}
                     width={width}

                />
            </Grid>
        </React.Fragment>
    );
};
ThePhoneHouseLogoXS.defaultProps = {
    height: 80,
    width: 80,
    alt: 'the phone house logo'
};

export const ScreenShotExample = (props) => {
    const {height, width, alt} = props;
    return (
        <React.Fragment>
            <Grid>
                <img src={require('../../assets/images/aroaExample/capturaActivity.png')}
                     alt={alt}
                     height={height}
                     width={width}

                />
            </Grid>
        </React.Fragment>
    );
};
ScreenShotExample.defaultProps = {
    height: 80,
    width: 80,
    alt: 'worten logo'
};
