import React, {Component} from 'react';
import {Button, Grid, withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/es/FormControl";
import TextField from "@material-ui/core/TextField";
import {__ADD_ADVERTISERS_TO_USER} from '../../../services/endpoint-manager';
import xhrHandler from "../../../services/xhr-handler";
import {__ACTION_BUTTON, __SECONDARY_BUTTON} from "../../../atoms/ButtonsStyles";
import {showNotify} from "../../../atoms/AroaNotifier";
import sessionManager, { __SELECTED_DATABUYER } from '../../../services/storage-manager';

const ColorButton = withStyles(() => (__ACTION_BUTTON))(Button);
const SecondaryButton = withStyles(() => (__SECONDARY_BUTTON))(Button);

class AddEditAdvertiser extends Component {
    constructor(props) {
        super(props);
        let databuyer = sessionManager.getValue(__SELECTED_DATABUYER)
        if (props.advertiser) {
            this.state = {
                advertiserName: props.advertiser.advertisername,
                remoteAdvertiserID: props.advertiser.remoteadvertiserid,
                advertiserURL: props.advertiser.advertiserurl,
                advertiserID: props.advertiser.advertiserid,
                advertiserDeleted: 0,
                dspID: props.dspID,
                edit: true,
                counter: 0,
                dataBuyerID: databuyer
            };
        } else {
            this.state = {
                advertiserName: "",
                remoteAdvertiserID: "",
                advertiserURL: "",
                advertiserDeleted: 0,
                dspID: props.dspID,
                edit: false,
                dataBuyerID: databuyer
            };
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearForm = this.clearForm.bind(this);
    }

    componentDidMount() {
        this.getDisableEditNotifier();
    }

    clearForm() {
        this.setState({
            advertiserName: "",
            remoteAdvertiserID: "",
            advertiserURL: ""
        });
    }

    componentWillUnmount() {
        this.clearForm();
    }

    handleChange(event) {
        this.setState({[event.target.id]: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        let axiosHandler = new xhrHandler();
        if (this.state.advertiserName && this.state.remoteAdvertiserID && this.state.advertiserURL) {
            if (this.state.edit) {
                axiosHandler.launchPut(__ADD_ADVERTISERS_TO_USER, this.state, (response) => {
                    showNotify({message: axiosHandler.getSuccessMessage(response)});
                    this.props.onAccept();
                }, (error) => {
                    showNotify({message: axiosHandler.getErrorDescription(error)});
                });
            } else {
                axiosHandler.launchPost(__ADD_ADVERTISERS_TO_USER, this.state, (response) => {
                    showNotify({message: axiosHandler.getSuccessMessage(response)});
                    this.props.onAccept();
                }, (error) => {
                    showNotify({message: axiosHandler.getErrorDescription(error)});
                });
            }
        } else {
            showNotify({message: "Please make sure you have fill all required fields"});
        }
    }

    getDisableEditNotifier = () => {
        if (this.state.edit || this.counter === 0) {
            this.setState({counter: 1});
            return showNotify({message: 'Advertiser edition is temporaly disabled'});
        }
        ;
    }


    render() {
        return (
            <Grid>
                <Grid className="flex flex--spaced margin-bottom-15 width--full">
                    <Grid className="margin-right-10 width--full">
                        <FormControl required>
                            <TextField id="advertiserName"
                                       name="advertiserName"
                                       type={"text"}
                                       onChange={this.handleChange}
                                       label="Advertiser Name"
                                       autoComplete="advertiserName"
                                       autoFocus
                                       placeholder="Add the advertiser name"
                                       value={this.state.advertiserName}
                                       disabled={this.state.edit === true ? true : false}
                            />
                        </FormControl>
                    </Grid>
                    <Grid className="margin-left-10 width--full">
                        <FormControl required>
                            <TextField id="remoteAdvertiserID"
                                       name="remoteAdvertiserID"
                                       type="number"
                                       onChange={this.handleChange}
                                       label="Advertiser ID (In your dsp)"
                                       autoComplete="remoteAdvertiserID"
                                       placeholder="Add the advertiser id"
                                       value={this.state.remoteAdvertiserID}
                                       disabled={this.state.edit === true ? true : false}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid className="flex flex--spaced">
                    <FormControl required>
                        <TextField id="advertiserURL"
                                   name="advertiserURL"
                                   type={"text"}
                                   onChange={this.handleChange}
                                   label="Advertiser Url"
                                   autoComplete="advertiserURL"
                                   autoFocus
                                   placeholder="Add the advertiser url"
                                   value={this.state.advertiserURL}
                                   disabled={this.state.edit === true ? true : false}
                        />
                    </FormControl>
                </Grid>
                <Grid className="flex flex--end dialog__footer-buttons">
                    <SecondaryButton disabled={this.state.edit === true ? true : false} onClick={this.clearForm} color="primary">
                        Clear
                    </SecondaryButton>
                    <ColorButton onClick={this.handleSubmit} color="primary">
                        Save
                    </ColorButton>
                </Grid>
            </Grid>
        )
    }
}

export default AddEditAdvertiser;
