import { connect } from 'react-redux';
import AutenticatedButtons from '../../../atoms/AutenticatedButtons';
import {setCartItemsCount} from "../../actions";

const getCartItemsCount = (cartItemsCount) => {
    return cartItemsCount;
};

const mapStateToProps = (state) => {
    return {
        cartItemsCount: getCartItemsCount(state.cartItemsCount)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCartItemsCount: (cartItemCount) => {
            dispatch(setCartItemsCount(cartItemCount))
        },
        getCartItemsCount: () => {
            dispatch(setCartItemsCount())
        }
    }
};

const HeaderBar = connect(
    mapStateToProps,
    mapDispatchToProps
)(AutenticatedButtons);

export default HeaderBar;
