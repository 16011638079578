export const __LOGIN = "nfw/user/login";
export const __LOGOUT = "user/logout";
export const __CREATENEWUSER = "nfw/user/create";
export const __RESETPASSWORD = "nfw/resetpassword";
export const __CHANGEPASSWORD = "nfw/resetpassword";
export const __UNIQUEEVENTSANDUSERS = "nfw/elastic/uniqueusersandevents";
export const __USER_ADD_PARTNERS = "nfw/user/partner/add";
export const __CHECK_TOKEN_VALID = "/checksession";
export const __RETRIEVE_DSP_PLATFORM_LIST = "dsp/platforms";
export const __ADD_DSP_TO_USER = "dsp";
export const __DELETE_DSP = "dsp";
export const __ENABLE_DSP = "dsp/enable";
export const __ADD_ADVERTISERS_TO_USER = "dspadv";
export const __ADD_DSP_LIST_TO_USER = "dsp/list";
export const __ADD_ADVERTISERS_LIST = "dspadv/list";
export const __AROA_SEARCH_ENGINE_ADDR = "nfw/elastic/customsearch";
export const __DATABUYER_LIST = "databuyer/user/list";
export const __AUDIENCE_TOTAL_COOKIES = "audience/cookies";
export const __BUY_AUDIENCE = "audience/buy";
export const __CART_PREVIEW = "cart/preview";
export const __AUDIENCE = "audience";
export const __CART_DEVICES = "cart/devices";
export const __CART_BILLINGMAPPINGS = "cart/billingMappings";
export const __CART_TRANSACTION_TYPES = "cart/transaction/types";
export const __CART_TRANSACTION = "cart/transaction";
export const __CART_PURCHASES_TYPES = "cart/purchases/types";
export const __ACTIVITY_OVERVIEW = "activity/overview";
export const __ACTIVITY_DUPLICATE = "activity/duplicate";
export const __ACTIVITY_CHART_DATA = "activity/audience/graphic";
export const __ACTIVITY_STOP = "activity/audience/stop";
export const __ACTIVITY_RESUME = "activity/audience/resume";
export const __CART_STATES = "cart/states";
export const __ACTVITY_STATES = "/activity/states";
export const __DSP_BY_DATABUYER = "dsp/listbydatabuyer/";
export const __FORGOT_PASSWORD = "nfw/rememberpassword";
export const __MAIN_DATABUYER = "databuyer/user/maindatabuyer";
export const __REPURCHASE_AUDIENCE = "audience/repurchase";
export const __COSTS_OVERVIEW = "/costs/overview";
export const __COSTS_UPLOAD = "/costs/upload";
export const __COSTS_CONSOLIDATE = "/costs/consolidate";
export const __BILLING_CONSOLIDATE = "/costs/platforms/consolidate";
export const __MONTHLY_CONSOLIDATE = "costs/monthly/consolidate";
export const __DATAPARTNER_LIST = "datapartner/list";
export const __DATAPARTNER_AUDIENCES = "datapartner/audiences";

export const __EMPTY_CART_APOLLO = "cart/empty";
export const __TAXONOMY_LIST = "apollo/taxonomy";
export const __SEGMENTS_LIST_BY_TAX_ID = "apollo/taxonomy/list/";
export const __APOLLO_CART_TRANSACTION = "/apollo/taxonomy/segment/audiences";
export const __APOLLO_INFO_BY_TAX_ID = "/apollo/taxonomy/info/";
export const __APOLLO_TAXONOMY_SUMMARY = "/apollo/taxonomy/summary";

export const __GET_DSPS_BY_DATABUYER = (id) => "databuyer/dsp/list/" + id;
export const __GET_ADVERTISERS_BY_DATABUYER = (id) =>
  "databuyer/advertiser/list/" + id;
export const __GET_BILLING_MONTH_DATA = (month) => "costs/month/" + month;

export const __GET_DATAPARTNER_DATA_YEAR = (datapartner) =>
  `datapartner/${datapartner}/year`;
export const __GET_DATAPARTNER_DATA_MONTH = (month, datapartner) =>
  `datapartner/${datapartner}/month/${month}`;
export const __GET_DATAPARTNER_DATA_MONTH_RESUME = (month, datapartner) =>
  `datapartner/${datapartner}/resume/month/${month}`;

export const __GET_DATAPARTNER_AUDIENCES = (datapartner) =>
  `datapartner/audiences/${datapartner}`;

export const __GET_DATAPARTNER_DATA_MONTH_GROUPED = (
  month,
  datapartner,
  groupBy
) => `datapartner/${datapartner}/month/${month}?group=${groupBy}`;

export const __GET_AVAILABLE_MONTHS = (type) => `costs/available/month/${type}`;
