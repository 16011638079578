import Paper from "@material-ui/core/Paper";
import React, {Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import {Typography, withStyles} from "@material-ui/core";
import AroaCartTable from "../AroaCartTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import {__ACTION_BUTTON} from "../ButtonsStyles";

const ColorButton = withStyles(() => (__ACTION_BUTTON))(Button);

const AroaCartAudienceDetails = ({segments, onButtonClick}) => {

    return (
        <Grid style={{backgroundColor: "#f5f5f5"}}
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
        >
            <Fragment>
                <Typography variant={"body2"} className="aroaColor margin-bottom-10">SEGMENTS</Typography>
                <Grid container className="margin-bottom-20">
                    <Grid item xs={12}>
                        <Paper className="scroll__container">
                            <AroaCartTable
                                headers={['DATA PARTNER', 'TYPE', 'VALUE', 'UNIQUE USERS']}
                                tableClass="audience-table"
                            >
                                {
                                    segments
                                        ? segments.map( (segment) => (
                                            <TableRow key={segment.segmentID} className="audience-table__row">
                                                <TableCell>{segment.dataPartner.name}</TableCell>
                                                <TableCell>{segment.type}</TableCell>
                                                <TableCell>{segment.value}</TableCell>
                                                <TableCell className="text--align-right">{segment.nCookies}</TableCell>
                                            </TableRow>
                                        ))
                                        : ""
                                }
                            </AroaCartTable>
                        </Paper>
                    </Grid>
                </Grid>
            </Fragment>
            <Grid container justify={"flex-end"}>
                <ColorButton
                    onClick={onButtonClick}
                >
                    HIDE DETAILS
                </ColorButton>
            </Grid>
        </Grid>
    )
};

export default AroaCartAudienceDetails;
