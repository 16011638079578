import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import AroaParentComponent from "../../../AroaParentComponent";
import Typography from "@material-ui/core/es/Typography";
import {Grid} from "@material-ui/core";
import Notifier, {showNotify} from "../../../../atoms/AroaNotifier";
import Paper from "@material-ui/core/es/Paper";
import FormControl from "@material-ui/core/es/FormControl";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import {__ADD_ADVERTISERS_TO_USER} from '../../../../services/endpoint-manager';
import sessionStorage, {__CREATE_ADD_DSP_DSPID_FIELD, __SELECTED_DATABUYER} from '../../../../services/storage-manager';
import sessionManager from '../../../../services/storage-manager';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(1) * 1,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 350,
        alignItems: 'center',
        padding: `${theme.spacing(1) * 2}px ${theme.spacing(1) * 3}px ${theme.spacing(1) * 3}px`,
    },
    form: {
        width: 250, // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(1) * 3,
    },
    aroaShortButton: {
        backgroundColor: "#9CAFC3",
        color: "#FFFFFF",
        width: 110
    },
    aroaShortClearButton: {
        backgroundColor: "#FFFFFF",
        color: "#9CAFC3",
        width: 110
    }
});

class AddAdvertiserStep1Form extends AroaParentComponent {
    constructor(props) {
        super(props);
        let databuyer = sessionManager.getValue(__SELECTED_DATABUYER)
        this.state = {
            advertiserName: "",
            dspID: "",
            remoteAdvertiserID: "",
            advertiserURL: "",
            dataBuyerID: databuyer
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        /*introducimos la variable dspID generada al crear DSP en state ya que necesaria parar crear advertiser*/
        const dspIDvalue = sessionStorage.getValue(__CREATE_ADD_DSP_DSPID_FIELD);
        this.setState({dspID: dspIDvalue});
    }

    handleChange(event) {
        this.state[event.target.id] = event.target.value;
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.advertiserName && this.state.dspID && this.state.remoteAdvertiserID) {
            this.getXHRHandler().launchPost(__ADD_ADVERTISERS_TO_USER, this.state, (response) => {
                showNotify({message: this.getXHRHandler().getSuccessMessage(response)});
                this.gotoPath('/createadvertiser/step2');
            }, (error) => {
                showNotify({message: this.getXHRHandler().getErrorDescription(error)});
            })
        } else {
            showNotify({message: "Please make sure you have fill all required fields"});
        }
    }

    render() {


        return (
            <React.Fragment>

                <Grid container justify={"center"}>
                    <Notifier/>
                    <form onSubmit={this.handleSubmit}>
                        <Grid item>
                            <Paper elevation={0}>
                                <Grid className="margin-top-50  margin-bottom-30 margin-left-20" container justify={"start"}>
                                    <Typography className="welcome-title  text--align--left">
                                        Add advertisers to your list
                                    </Typography>
                                </Grid>
                                <Grid container>
                                    <Grid className="margin-5 margin-left-20">
                                        <FormControl required>
                                            <TextField
                                                className="textfield-Create"
                                                id="advertiserName"
                                                name="advertiserName"
                                                type={"text"}
                                                onChange={this.handleChange}
                                                label="ADVERTISER NAME"
                                                autoComplete="advertiserName"
                                                autoFocus
                                                placeholder={"Add the advertiser name"}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid className="margin-5 margin-left-20">
                                        <FormControl required>
                                            <TextField
                                                className="textfield-Create"
                                                id="remoteAdvertiserID"
                                                name="remoteAdvertiserID"
                                                type={"number"}
                                                onChange={this.handleChange}
                                                label="ADVERTISER ID (IN YOUR DSP)"
                                                placeholder={"Add the advertiser id"}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container className="margin-5 margin-top-15 margin-left-20">
                                    <FormControl>
                                        <TextField
                                            className="textfield-Create"
                                            id="advertiserURL"
                                            name="advertiserURL"
                                            type={"text"}
                                            onChange={this.handleChange}
                                            label="URL"
                                            autoComplete="Url"
                                            placeholder={"Add the advertiser url"}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container justify={"flex-end"} className="margin-top-50">
                                    <Grid item>
                                        <Fab type="reset" aria-label="Clear form"
                                             className="buttonsmall--blue--border "
                                             style={{margin: 10}}
                                        >
                                            CLEAR
                                        </Fab>
                                    </Grid>
                                    <Grid item >
                                        <Fab type="submit" variant="extended" aria-label="Add Advertisers"
                                             onClick={this.handleSubmit}
                                             className="buttonsmall--solidblue"
                                             style={{margin: 10}}
                                        >
                                            SAVE
                                        </Fab>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </form>
                </Grid>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(AddAdvertiserStep1Form);
