import React                  from 'react';
import {withStyles}           from '@material-ui/core/styles';
import AroaParentComponent    from "../../../components/AroaParentComponent";
import AroaPage               from "../../../components/AroaPage";
import SimpleAroaBar          from "../../../atoms/AroaSimpleBar";
import styles                 from '../../../components/AroaPage/aroatheme';
import AroaStepper  from "../../../atoms/AroaStepper";
import AddAdvertiserStep1Form from "../../../components/AroaForms/RegisterForms/AddAdvertiserStep1Form"

class CreateAdvertiserStep1Body extends AroaParentComponent
{
	
	render()
	{
		return (
			<React.Fragment>
				<AroaStepper
					steps={['Create an account', 'Add users', 'Add DSP', 'Add advertiser']}
					activeState={3}
					completed={[0, 1, 2]}
				/>
				<AddAdvertiserStep1Form/>
			</React.Fragment>
		)
	}
}

class CreateAdvertiserStep1 extends AroaParentComponent
{
	render()
	{
		if(this.isUserLogged()){
			return <AroaPage header={(new SimpleAroaBar(this.props).render())}
							 body={(new CreateAdvertiserStep1Body(this.props).render())}/>;
		}else{
			this.gotoPath('/');
			return '';
		}
	}
}

export default withStyles(styles)(CreateAdvertiserStep1);
