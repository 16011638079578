import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core/index";

class AroaDialog extends React.Component
{
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.accept = this.accept.bind(this);
        this.cancel = this.cancel.bind(this);

        this.state = {
            open: props.open || false,
            title: props.title || "",
            text: props.text || "",
            accept: props.accept || false,
            cancel: props.cancel || false,
            acceptText: props.acceptText || "Accept",
            cancelText: props.cancelText || "Cancel"
        };
    }

    close() {
        this.setState({open: false});
    }

    accept() {
        if (this.props.acceptHandler) this.props.acceptHandler();
        this.close();
    }

    cancel() {
        if (this.props.cancelHandler) this.props.cancelHandler();
        this.close();
    }

    render() {
        return (
            <Dialog
                open={this.state.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                id="dialog">
                <DialogTitle id="alert-dialog-title">{this.state.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {this.state.cancel ? <Button onClick={this.cancel} color="primary">{this.state.cancelText}</Button> : ''}
                    {this.state.accept ? <Button onClick={this.accept} color="primary">{this.state.acceptText}</Button> : ''}
                </DialogActions>
            </Dialog>
        );
    }
}

export default AroaDialog;
