import React                 from 'react';
import styles                from '../../../components/AroaPage/aroatheme';
import {withStyles}          from '@material-ui/core/styles';
import AroaParentComponent   from "../../../components/AroaParentComponent";
import CreateUserForm        from "../../../components/AroaForms/RegisterForms/CreateUserForm";
import AroaStepper from "../../../atoms/AroaStepper";
import AroaBar               from "../../../components/AroaBar";
import AroaPage              from "../../../components/AroaPage";
import Grid                  from "@material-ui/core/Grid";

class CreateUserStep1Body extends React.Component
{
	render()
	{
		return (
			<Grid>
				<AroaStepper
					steps={['Create an account', 'Add users', 'Add DSP', 'Add advertiser']}
					activeState={0}
					optionals={[1]}
					completed={[]}
				/>
				<CreateUserForm/>
			</Grid>
		)
	}
}

class CreateUserStep1 extends AroaParentComponent
{
	render()
	{
		let props = {
			about       : false,
			trial       : false,
			signup      : false,
			textAroaBar : false
		}
		return (
			
			<AroaPage header={(new AroaBar({...this.props, ...props}).render())}
					  body={(new CreateUserStep1Body(this.props).render())}/>
		)
	}
}

export default withStyles(styles)(CreateUserStep1);
