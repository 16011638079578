import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import AroaParentComponent from "../../../components/AroaParentComponent";
import AroaPage from "../../../components/AroaPage";
import SimpleAroaBar from "../../../atoms/AroaSimpleBar";
import styles from '../../../components/AroaPage/aroatheme';
import DspStep2TabsCompo from "../../../components/AroaDspTabsEstado/DspStep2TabsCompo";
import AroaStepper from "../../../atoms/AroaStepper";
import Grid from "@material-ui/core/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons/";
import {Button, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {__ACTION_BUTTON} from "../../../atoms/ButtonsStyles";

const ColorButton = withStyles(() => (__ACTION_BUTTON))(Button);

class CreateUserDspStep2Body extends AroaParentComponent {
    
    render() {
        return (
            <Grid>
                <AroaStepper
                    steps={['Create an account', 'Add users', 'Add DSP', 'Add advertiser']}
                    activeState={2}
                    completed={[0, 1]}
                />
                <Grid className="flex flex--centered">
                    <Grid className="flex flex--column width--dsp">
                        <Link to={"/createuser/dsp/step1"}>
                            <Grid className="margin-top-50 margin-bottom-30" container>
                                <Typography className="welcome-title text--align--left">
                                    DSP List
                                </Typography>
                                <FontAwesomeIcon style={{marginLeft: 10}} icon={faPlusCircle} size='2x' color={"inherit"}/>
                            </Grid>
                        </Link>
                        <DspStep2TabsCompo/>
                        <Grid className="flex flex--end" style={{margin: 15}}>
                            <Link to={"/createdsp/stepfinish"}>
                                <ColorButton aria-label="LOG IN">
                                    NEXT STEP
                                </ColorButton>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

class CreateDspStep2 extends AroaParentComponent {
    render() {
        if (this.isUserLogged()) {
            return <AroaPage header={(new SimpleAroaBar(this.props).render())}
                             body={(new CreateUserDspStep2Body(this.props).render())}/>;
        } else {
            this.gotoPath('/');
            return '';
        }
    }
}

export default withStyles(styles)(CreateDspStep2);
