import React  from 'react'
import logo   from './../../assets/images/rootlogo.png'
import {Link} from "react-router-dom";

const AroaLogo = (props) => {
	const {width}  = props;
	const showLink = React.useState(props.showLink || true);
	return (
		<React.Fragment>
			{showLink[0] ?
				<Link to={"/"}><img src={logo} width={width} className="headerLogo" alt={"Logotipo de AROA"}/></Link>
				:
				<img src={logo} width={width} className="headerLogo" alt={"Logotipo de AROA"}/>
			}
		
		</React.Fragment>
	);
};

export default AroaLogo;