import React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField
} from "@material-ui/core/index";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {showNotify} from "../AroaNotifier";
import sessionManager, {__AUDIENCENAME_ON_ACTIVITY_BUYAGAIN_} from "../../services/storage-manager";


class AroaActivityAudienceNameDialog extends React.Component
{
    constructor(props) {
        super(props);



        this.state = {
            open: props.open || false,
            accept: props.accept || false,
            cancel: props.cancel || false,
            acceptText: props.acceptText || "Accept",
            cancelText: props.cancelText || "Cancel"
        };
        this.data = {audienceName: ""};

        this.close = this.close.bind(this);
        this.accept = this.accept.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    close() {
        this.setState({open: false});
    }

    accept() {
        this.handleSubmit();
        if (this.props.acceptHandler) this.props.acceptHandler();
        this.setState({open: false});
    }

    cancel() {
        if (this.props.cancelHandler) this.props.cancelHandler();
        this.close();
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
           this.accept()
        }
    };

    handleSubmit = () => {
        let _data = this.data;

        if (_data.audienceName.length > 1) {
            if (_data.audienceName.match("^[A-Za-z0-9]+$")) {
                sessionManager.setValue(__AUDIENCENAME_ON_ACTIVITY_BUYAGAIN_, _data.audienceName);

            } else {
                showNotify({message: 'Please enter a valid name without special characters'});
            }
        } else {
            showNotify({message: 'Please enter a name for your audience'});
        }
    };

    handleChange = (event) => {
        event.persist();
        this.data.audienceName = event.target.value;
    };

    render() {
        return (
            <Dialog
                open={this.state.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                id="dialog">
                <DialogTitle className="market__dialog--close">
                    <IconButton aria-label="close"  onClick={this.cancel}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid className="margin-top-30 flex flex--direction--column">
                        <span className="text-prompt-operations">Name your new audience</span>
                        <TextField
                            onChange={this.handleChange}
                            autoFocus={true}
                            margin="dense"
                            placeholder="Audience name"
                            id="audiencename"
                            type="text"
                            onKeyPress={this.handleKeyPress}
                        />
                        <Grid className="margin-top-20 margin-bottom-50 flex flex--justify--center">
                            <Button className="button-market-name-dialog"
                                    onClick={this.accept}
                                    color="primary"
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default AroaActivityAudienceNameDialog;
