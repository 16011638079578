import { Grid } from "@material-ui/core";
import React from "react";
import styles from "../../components/AroaPage/aroatheme";
import { withStyles } from "@material-ui/core/styles";
import {
  __DATAPARTNER_LIST,
  __GET_DATAPARTNER_DATA_MONTH,
  __GET_DATAPARTNER_DATA_MONTH_GROUPED,
} from "../../services/endpoint-manager";
import { showNotify } from "../../atoms/AroaNotifier";
import DataPartnerViewData from "../../atoms/DataPartnerViewData";
import AroaParentComponent from "../../components/AroaParentComponent";
import DataPartnerLiveGraph from "../../atoms/DataPartnerLiveGraph";
import DataPartnerLiveButtons from "../../atoms/DataPartnerLiveButtons";
import AroaPage from "../../components/AroaPage";
import AroaBar from "../../components/AroaBar";
import sessionManager, {
  __SELECTED_DATAPARTNER,
} from "../../services/storage-manager";
import xhrHandler from "../../services/xhr-handler";
import { tokenManager } from "../../services/token-manager";

class DataPartnerLiveBody extends AroaParentComponent {
  constructor() {
    super();

    this.state = {
      month: new Date().getMonth() + 1,
      audienceData: [],
      monthData: [],
      currentSelection: "Advertiser",
      tableColumns: {
        Advertiser: {
          graphGrouping: "advertiserName",
          header: ["DataPartner", "Month", "Advertiser", "Revenue"],
          columns: ["name", "month", "advertiserName", "vendorRev"],
        },
        DataBuyer: {
          graphGrouping: "dataBuyerName",
          header: ["DataPartner", "Month", "DataBuyer", "Revenue"],
          columns: ["name", "month", "dataBuyerName", "vendorRev"],
        },
        Segment: {
          graphGrouping: "value",
          header: ["DataPartner", "Month", "Segment", "Revenue"],
          columns: ["name", "month", "value", "vendorRev"],
        },
      },
    };

    this.axiosHandler = this.getXHRHandler();
  }

  componentDidMount = () => {
    if (!sessionManager.getValue(__SELECTED_DATAPARTNER)) {
      this.loadDefaultDataPartner().then((dataPartners) => {
        sessionManager.setValue(__SELECTED_DATAPARTNER, dataPartners[0].id);
        this.loadAudienceDataByMonth();
      });
    } else {
      this.loadAudienceDataByMonth();
    }
  };

  loadDefaultDataPartner = () => {
    let axiosHandler = new xhrHandler();
    const userId = tokenManager.getTokenData()["user_id"];
    return new Promise((resolve, reject) => {
      axiosHandler.launchGet(
        __DATAPARTNER_LIST + "?userID=" + userId,
        (response) => resolve(axiosHandler.getSuccessData(response)),
        (error) => reject(axiosHandler.getErrorDescription(error))
      );
    });
  };

  loadAudienceDataByMonth = () => {
    let url;
    const dataPartner = sessionStorage.getItem(__SELECTED_DATAPARTNER);

    switch (this.state.currentSelection) {
      case "Advertiser":
        url = __GET_DATAPARTNER_DATA_MONTH_GROUPED(
          this.state.month,
          dataPartner,
          "advertiser"
        );
        break;
      case "DataBuyer":
        url = __GET_DATAPARTNER_DATA_MONTH_GROUPED(
          this.state.month,
          dataPartner,
          "dataBuyer"
        );
        break;
      case "Segment":
        url = __GET_DATAPARTNER_DATA_MONTH(this.state.month, dataPartner);
        break;
      default:
        return;
    }

    this.axiosHandler.launchGet(
      url,
      (response) => {
        const monthData = this.getXHRHandler().getSuccessData(response);
        this.setState({ monthData });
      },
      (error) => {
        showNotify({
          message: this.getXHRHandler().getErrorDescription(error),
        });
      }
    );
  };

  handleSelectionChange = (e) =>
    this.setState({ currentSelection: e }, () =>
      this.loadAudienceDataByMonth()
    );

  render() {
    const selectionTableData =
      this.state.tableColumns[this.state.currentSelection];
    return (
      <Grid>
        <Grid className="flex flex--around flex__full">
          <DataPartnerLiveButtons
            clicked={this.state.currentSelection === "Advertiser" ? 1 : 0}
            text="Advertiser"
            onClick={this.handleSelectionChange}
          ></DataPartnerLiveButtons>
          <DataPartnerLiveButtons
            clicked={this.state.currentSelection === "DataBuyer" ? 1 : 0}
            text="DataBuyer"
            onClick={this.handleSelectionChange}
          ></DataPartnerLiveButtons>

          <DataPartnerLiveButtons
            clicked={this.state.currentSelection === "Segment" ? 1 : 0}
            text="Segment"
            onClick={this.handleSelectionChange}
          ></DataPartnerLiveButtons>
        </Grid>

        <DataPartnerLiveGraph
          group={selectionTableData.graphGrouping}
          data={this.state.monthData}
        ></DataPartnerLiveGraph>
        <DataPartnerViewData
          header={selectionTableData.header}
          columns={selectionTableData.columns}
          data={this.state.monthData}
        ></DataPartnerViewData>
      </Grid>
    );
  }
}

class DataPartnerLiveView extends AroaParentComponent {
  render() {
    return (
      <AroaPage
        header={new AroaBar(this.props).render()}
        body={<DataPartnerLiveBody></DataPartnerLiveBody>}
      ></AroaPage>
    );
  }
}

export default withStyles(styles)(DataPartnerLiveView);
