import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

class FormModal extends Component
{
    handleClose() {
        this.props.onClose();
    }

    render() {
        return (
            <Dialog open={this.props.opened} id="dialog" maxWidth={this.props.maxWidth ? this.props.maxWidth : false}>
                <DialogTitle>
                    <span>{this.props.title}</span>
                    {
                        this.props.closeIcon
                            ? <IconButton onClick={() => this.handleClose()} style={{float: "right"}}>
                                <CloseIcon />
                                </IconButton>
                            : null
                    }
                </DialogTitle>
                <DialogContent className={this.props.className}>
                    {this.props.children}
                </DialogContent>
            </Dialog>
        );
    }
}

export default FormModal;
