import React                 from 'react';
import styles                from '../../../components/AroaPage/aroatheme';
import {withStyles}          from '@material-ui/core/styles';
import AroaParentComponent   from "../../../components/AroaParentComponent";
import AddUserForm           from "../../../components/AroaForms/RegisterForms/AddUserForm";
import AroaStepper from "../../../atoms/AroaStepper";
import AroaPage              from "../../../components/AroaPage";
import Grid                  from "@material-ui/core/Grid";
import SimpleAroaBar         from "../../../atoms/AroaSimpleBar";

class CreateUserStep3Body extends React.Component
{
	render()
	{
		return (
			<Grid>
				<AroaStepper
					steps={['Create an account', 'Add users', 'Add DSP', 'Add advertiser']}
					activeState={1}
					optionals={[1]}
					completed={[0]}
				/>
				<AddUserForm/>
			</Grid>
		)
	}
}

class CreateUserStep3 extends AroaParentComponent
{
	render()
	{
		if(this.isUserLogged()){
			return <AroaPage header={(new SimpleAroaBar(this.props).render())}
							 body={(new CreateUserStep3Body(this.props).render())}/>;
		}else{
			this.gotoPath('/');
			return '';
		}
	}
}

export default withStyles(styles)(CreateUserStep3);
