import { __API_TOKEN, localStorageManager } from "./storage-manager";
import { __CHECK_TOKEN_VALID } from "./endpoint-manager";
import decode from "jwt-decode";

export class tokenManager {
  static checkToken() {
    if (!localStorageManager.keyExists(__API_TOKEN)) {
      return false;
    } else {
      let request = new XMLHttpRequest();
      request.open("GET", __CHECK_TOKEN_VALID, false); // `false` makes the request synchronous
      request.setRequestHeader(
        "Authorization",
        "Bearer " + localStorageManager.getValue(__API_TOKEN)
      );
      request.send(null);

      if (request.status === 401) {
        localStorageManager.remove(__API_TOKEN);
      }

      return request.status === 200;
    }
  }

  static getTokenData() {
    if (!localStorageManager.keyExists(__API_TOKEN)) {
      return {};
    } else {
      return decode(localStorageManager.getValue(__API_TOKEN));
    }
  }

  static removeToken() {
    localStorageManager.remove(__API_TOKEN);
  }

  static checkTokenIsExpired() {
    if (!localStorageManager.keyExists(__API_TOKEN)) {
      return true;
    } else {
      let tokenData = tokenManager.getTokenData();
      return tokenData.exp < new Date().getTime() / 1000;
    }
  }

  static getUserRoles() {
    if (!localStorageManager.keyExists(__API_TOKEN)) {
      return [];
    } else {
      let tokenData = tokenManager.getTokenData();

      return tokenData.userRoles;
    }
  }
}
