import { connect } from 'react-redux';
import AroaCartFooter from "../../../atoms/AroaCartFooter";

const getIsFormCompleted = (audienceAttributes, cartStep) => {
    if (cartStep === 1) return true;
    
    let audienceCount = audienceAttributes.length;
    
    if (cartStep === 2) {
        let finishedAudiences = audienceAttributes.filter(audience =>
            audience.platform && audience.device && audience.advertiser
        );
        return audienceCount === finishedAudiences.length;
    }
    
    if (cartStep === 3) {
        let finishedAudiences = audienceAttributes.filter(audience =>
            audience.typeID && audience.expirationDate
        );
        return audienceCount === finishedAudiences.length;
    }
    
    return true;
};

const mapStateToProps = (state) => {
    return {
        isFormCompleted: getIsFormCompleted(state.audienceAttributes, state.cartStep)
    }
};

const CartStepFooter = connect(
    mapStateToProps
)(AroaCartFooter);

export default CartStepFooter;
