import React from "react";
import styles from "../../components/AroaPage/aroatheme";
import { withStyles } from "@material-ui/core/styles";
import AroaPage from "../../components/AroaPage";
import AroaParentComponent from "../../components/AroaParentComponent";
import AroaBar from "../../components/AroaBar";
import Paper from "@material-ui/core/es/Paper";
import SearchInput from "../../redux/containers/SearchInput";
import SearchResults from "../../redux/containers/SearchResults";
import Grid from "@material-ui/core/Grid";

class MarketLandingBody extends AroaParentComponent {
  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paperMarketLanding} elevation={0}>
        <Grid>
          <Grid className={classes.gridCreateUserStep2}>
            <SearchInput />
          </Grid>
          <Grid container justify={"center"}>
            <Grid
              className={classes.gridCreateUserStep2}
              style={{ marginTop: 50 }}
            >
              <SearchResults />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

class MarketLandingPage extends AroaParentComponent {
  render() {
    if (this.isUserLogged()) {
      return (
        <AroaPage
          header={new AroaBar(this.props).render()}
          body={new MarketLandingBody(this.props).render()}
        />
      );
    } else {
      this.gotoPath("/");
      return "";
    }
  }
}


export default withStyles(styles)(MarketLandingPage);
