import React from "react";
import xhrHandler from "../../services/xhr-handler";
import { tokenManager } from "../../services/token-manager";

class AroaParentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.logged = false;
    this.setXHRHandler(new xhrHandler());

    let tokenData = tokenManager.getTokenData();
    this.userEmail = tokenData["user_mail"];
  }

  getXHRHandler() {
    return this._xhrHandler;
  }

  setXHRHandler(value) {
    this._xhrHandler = value;
  }

  isUserLogged() {
    return !tokenManager.checkTokenIsExpired();
  }

  gotoPath(path) {
    window.location.href = path;
  }
}

export default AroaParentComponent;
