import React, {Fragment} from 'react';
import classNames        from 'classnames';
import PropTypes         from 'prop-types';
import {withStyles}      from '@material-ui/core/styles';
import CssBaseline       from "@material-ui/core/CssBaseline";
import styles            from '../AroaPage/aroatheme';
import Notifier from "../../atoms/AroaNotifier";

const AroaPage = (props) => {
	const {classes, header,bodyHeader , body, footer, marginTop = 50} = props;
	return (
		<Fragment>
			<CssBaseline/>
			<Fragment>
				{header}
			</Fragment>

			<Fragment>
				<Notifier/>
				<Fragment>
					{bodyHeader }
				</Fragment>
				<main className={classNames(classes.layout)} style={{marginTop}}>
					{body}
				</main>
			</Fragment>

			<Fragment>
				{footer}
			</Fragment>
		</Fragment>
	);
};

AroaPage.propTypes = {
	header: PropTypes.node,
	bodyHeader : PropTypes.node,
	body  : PropTypes.node.isRequired,
	footer: PropTypes.node,
	marginTop: PropTypes.number
};

export default withStyles(styles)(AroaPage);
