import React                 from 'react';
import styles                from '../../../components/AroaPage/aroatheme';
import {withStyles}          from '@material-ui/core/styles';
import AroaParentComponent   from "../../../components/AroaParentComponent";
import AroaStepper from "../../../atoms/AroaStepper";
import AroaPage              from "../../../components/AroaPage";
import Grid                  from "@material-ui/core/Grid";
import {Typography}          from "@material-ui/core";
import {Link}                from "react-router-dom";
import Fab                   from "@material-ui/core/Fab";
import SimpleAroaBar         from "../../../atoms/AroaSimpleBar";
import {SuccessIcon} from "../../../atoms/AroaIcons";

class CreateAdvertiserStep3Body extends React.Component
{
	
	render()
	{
		return (
			<Grid>
				<AroaStepper
					steps={['Create an account', 'Add users', 'Add DSP', 'Add advertiser']}
					activeState={3}
					completed={[0, 1, 2, 3]}
				/>
				<Grid className="margin-top-50" >
					<SuccessIcon classes="message-screen__icon" active={true}/>
					<Typography  className="margin-top-30 welcome-title" >
						Done!
					</Typography>
					<Typography className="margin-top-10 welcome-text" >
						Done!! Enjoy you are ready to start using Aroa!
					</Typography>
					<Grid container justify={'center'} direction={'row'}>
						<Grid className="margin-5 margin-top-30" >
							<Link to={"/marketplace"}>
								<Fab variant="extended" aria-label="Dsp step1" className="margin-top-20 button-signup-login">
									LOOK FOR AN AUDIENCE
								</Fab>
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)
	}
}

class CreateAdvertiserStep3 extends AroaParentComponent
{
	
	render()
	{
		if(this.isUserLogged()){
			return <AroaPage header={(new SimpleAroaBar(this.props).render())}
							 body={(new CreateAdvertiserStep3Body(this.props).render())}/>;
		}else{
			this.gotoPath('/');
			return '';
		}
	}
}

export default withStyles(styles)(CreateAdvertiserStep3);
