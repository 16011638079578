import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import AroaParentComponent from "../AroaParentComponent";
import React from "react";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import LandingLogin from "../AroaForms/LandingLogIn";
import {AvanzaLogoXS, BlueBananaLogoXS, ThePhoneHouseLogoXS, WortenLogoXS} from "../../atoms/AroaImages";


const DataForSlider = [
    {
        title: "Welcome to Root",
        textBody: "The place you can find the perfect audiences for your campaigns. Millions of fresh data available in a full transparent environment",
        imageDP: "",
        image: require('../../assets/images/imagesSlider/aroa_slide_1280x400/aroa_slide_1280x400.png')
    },
    {
        title: "Looking for the millenials?",
        textBody: "Where to find them and how to impact them in the correct place and moment?\n" +
            "BlueBanana has the key. Millions of data points from trendy young people.",
        imageDP: <BlueBananaLogoXS />,
        image: require('../../assets/images/imagesSlider/aroa_slide_1280x400/bluebanana_slide_1280x400.png')
    },
    {
        title: "Much more than an electronical devices shop.",
        textBody: "A real and unique marketplace where a full range of products and brands meet.\n" +
            "Millions of shopping and purchasing data points a day, from interest to payment, follow them along the entire customer online journey.",
        imageDP: <WortenLogoXS/>,
        image: require('../../assets/images/imagesSlider/aroa_slide_1280x400/worten_slide_1280x400.png')
    },
    {
        title: "Mobility data? you can't do without it",
        textBody: "1.200.000 sesions / month,\n" +
            "720K UU, long distance bus travellers / month,\n" +
            "5.200.000 viewed pages / month.",
        imageDP: <AvanzaLogoXS/>,
        image: require('../../assets/images/imagesSlider/aroa_slide_1280x400/avanza_slide_1280x400.png')
    },
    {
        title: "Not only a phone company, but the new integrated operator in mobile, cable & power",
        textBody: "2.000.000 U.U / month,\n" +
            "10M viewed pages / month,\n" +
            "16K transactions.",
        imageDP: <ThePhoneHouseLogoXS/>,
        image: require('../../assets/images/imagesSlider/aroa_slide_1280x400/phone_house_slide_1280x400.png')
    }
];

class AroaAnimatedSlider extends AroaParentComponent {
    constructor(props) {
        super(props);
        this.state = {
            DataForSlider: DataForSlider,
            matches: window.matchMedia("(min-width: 768px)").matches
        }
    }

    render() {
        const data = this.state;
        return (
            <Slider>
                {data.DataForSlider.map((article, index) =>
                    <Grid className="flex flex--justify--center"
                          key={index} style={{  background: `url('${article.image}') no-repeat top ` }}>
                        <Grid style={{
                            width: 650, background: `linear-gradient(
                             rgba(0, 0, 0, 0.7),
                             rgba(0, 0, 0, 0.7)
                         )`
                        }}>
                            <Grid  className="margin-top-15">
                                <Grid style={{marginLeft: 78 ,textAlign: "left"}}>
                                    {article.imageDP}
                                </Grid>
                                <Grid className="margin-right-15 margin-left-15 ">
                                    <h1 className="welcome-intro--form-h1 margin-top-10 margin-bottom-10">{article.title}</h1>

                                    <Typography className="welcome-intro--form-text">{article.textBody}</Typography>
                                </Grid>
                            </Grid>
                            {index === 0 ?
                                <LandingLogin/>
                                : ''
                            }
                        </Grid>
                    </Grid>)}
            </Slider>

        )
    }
}

export default AroaAnimatedSlider;

