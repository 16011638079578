import React     from "react";
import Grid      from "@material-ui/core/Grid/index";
import Tabs      from "@material-ui/core/Tabs";
import Tab       from "@material-ui/core/Tab";
import Box       from "@material-ui/core/Box";
import DSPListPanel from "./DSPListPanel";

function TabPanel(props)
{
	const {children, value, index} = props;

	return (
		<Box role="tabpanel"
			 hidden={value !== index}
			 id={`vertical-tabpanel-${index}`}
			 aria-labelledby={`vertical-tab-${index}`} width="100%">
			<Grid container className="height--full">
				{children}
			</Grid>
		</Box>
	);
}

function a11yProps(index)
{
	return {
		id             : `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
		style          : {width: 60, minWidth: 60, height: '50%'},
	};
}

export default function AroaSettingsPanel(){

	const [value, setValue] = React.useState(0);

	function handleChange(event, newValue)
	{
		setValue(newValue);
	}

	return (
		<Grid className="settings__card-base">
			<Grid className="settings__card-base__sidebar">
				<Tabs value={value} onChange={handleChange}
					  className="height--full" orientation="vertical"
				>
					<Tab label="DSP LIST" {...a11yProps(0)} />
				</Tabs>
			</Grid>
			<Grid container>
				<TabPanel value={value} index={0}>
					<DSPListPanel/>
				</TabPanel>
			</Grid>
		</Grid>
	);
}
