import React from 'react';
import {Bar} from 'react-chartjs-2';
import Grid from "@material-ui/core/Grid";
import xhrHandler from "../../services/xhr-handler";
import {__ACTIVITY_CHART_DATA} from "../../services/endpoint-manager";
import {showNotify} from "../../atoms/AroaNotifier";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Typography} from "@material-ui/core";
import {ImageUnavailableIcon} from "../../atoms/AroaIcons";
import AroaParentComponent from "../AroaParentComponent";

export default class AroaActivityAudienceGraphic extends AroaParentComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                labels: '',
                datasets: [
                    {
                        /*label: 'LAST 30 DAY EVOLUTION',*/
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: '#22A8C0',
                        backgroundImage: 'linear-gradient(to top, #25b7ab, #2099d8)',
                        borderColor: 'rgba(75,192,192,1)',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: 'rgba(75,192,192,1)',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                        pointHoverBorderColor: 'rgba(220,220,220,1)',
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: ''
                    }
                ]
            },
            audienceID: this.props.audienceID,
            loadingLoader: true,
            noChartData: false
        };
        this.getChartData = this.getChartData.bind(this);
        this.getLoader = this.getLoader.bind(this);
        this.getNoResultData = this.getNoResultData.bind(this);
    }

    getChartData() {
        let axiosHandler = new xhrHandler();
        axiosHandler.launchPost(__ACTIVITY_CHART_DATA, {"audienceID": this.state.audienceID}, (response) => {
            let _rawChartData = axiosHandler.getSuccessData(response);
            if (_rawChartData.length > 0) {
                const _cookiesY = [];
                const _datesX = [];
                _rawChartData.map((rawDataMapped) => {
                   _cookiesY.push(rawDataMapped.cookies);
                   _datesX.push(rawDataMapped.date);
                   return "";
                });
                this.setState(
                    prevState => ({
                        data: {
                            ...prevState.data,
                            labels: _datesX,
                            datasets: [{
                                ...prevState.data.datasets[0],
                                data: _cookiesY
                            }]
                        }
                    })
                );
                
                this.setState({loadingLoader: false, noChartData: false, rawChartData: _rawChartData});
            } else if (_rawChartData.length === 0){
               this.setState({loadingLoader: false, noChartData: true})
            }
        }, (error) => {
            showNotify({message: this.getXHRHandler().getErrorDescription(error)});
            setTimeout(this.setState({loadingLoader: false, noChartData: true}), 2000);
        });
    }

    getNoResultData() {
        const noResults = (
            <Grid container alignItems={"center"} justify={"center"} direction={"column"}>
                <Grid><ImageUnavailableIcon/></Grid>
                <Grid><Typography className="greyText">Unavailable data</Typography></Grid>
            </Grid>
        );
        if (this.state.noChartData === true) {
            return noResults;
        }
    }

    getLoader() {
        const loader = (
            <Grid container justify={"center"}>
                <CircularProgress/>
            </Grid>
        );
        if (this.state.loadingLoader === true) {
            return loader;
        }
    }

    render() {
        if (this.state.loadingLoader === true) {
            return this.getLoader()
        } else if (this.state.noChartData === true) {
            return this.getNoResultData()
        } else {
            return (
                <Grid>
                    <Bar ref="chart" data={this.state.data} height={115} width={200}
                         legend={{display : false}}
                         options={{
                             scales: {
                                 xAxes: [{
                                     display: false
                                 }],
                                 yAxes: [{
                                     display: false,
                                 }],
                             },
                             legend: {
                                 display: false,
                                 position: 'top',
                                 labels: {
                                     fontColor: '#333'
                                 }
                             },
                             title: {
                                 display: true,
                                 text: 'LAST 30 DAY EVOLUTION',
                                 /*fontFamily: 'serif',*/
                                 fontColor: '#94999F',
                                 lineHeight: '2.25',
                                 fontStyle : 'bold',
                                 letterSpacing : 'normal',
                                 fontSize : 12,
                                 fontStretch: 'normal',
                             },
                             tooltips: {
                                 callbacks: {
                                     label: function(tooltipItem, data) {
                                         var value = data.datasets[0].data[tooltipItem.index];
                                         value = value.toString();
                                         value = value.split(/(?=(?:...)*$)/);
                                         value = value.join(',');
                                         return value;
                                     }
                                 } // end callbacks:
                             }
                         }}
    
                    />
                </Grid>
            );
        }
    }

    componentDidMount() {
        this.getChartData();
    }
}
