import { connect } from 'react-redux';
import CartStep2 from "../../../screens/Cart/CartStep2";
import {setAudiences, changeCartStep} from "../../actions";

const mapDispatchToProps = (dispatch) => {
    return {
        setCartStep: (cartStep) => {
            dispatch(changeCartStep(cartStep))
        },
        setAudiences: (audiences) => {
            dispatch(setAudiences(audiences));
        }
    }
};

const CartStepContainer2 = connect(
    null,
    mapDispatchToProps
)(CartStep2);

export default CartStepContainer2;
