import React                                               from 'react';
import Card                                                from '@material-ui/core/Card';
import CardActionArea                                      from '@material-ui/core/CardActionArea';
import CardActions                                         from '@material-ui/core/CardActions';
import CardContent                                         from '@material-ui/core/CardContent';
import Typography                                          from '@material-ui/core/Typography';
import Grid                                                from "@material-ui/core/Grid";
import AroaParentComponent                                 from "../../AroaParentComponent";
import {__ADD_ADVERTISERS_LIST, __ADD_ADVERTISERS_TO_USER} from "../../../services/endpoint-manager";
import {showNotify}                                        from "../../../atoms/AroaNotifier";
import sessionStorage, {__CREATE_ADD_DSP_DSPID_FIELD}      from "../../../services/storage-manager";
import CircularProgress from "@material-ui/core/CircularProgress";
import Scrollbar from "react-scrollbars-custom";
import AroaDialog from "../../../atoms/AroaDialog";

class AdvertiserCard extends AroaParentComponent
{
	
	constructor(props)
	{
		super(props);
		this.state             = {
			loading         : true,
			advertiserList  : [],
			removeAdvertiser: [],
			openDeleteDialog: false,
			deleteAdvertiserID: false
	};
		this.getAdvertiserList = this.getAdvertiserList.bind(this);
		this.deleteAdvertiser  = this.deleteAdvertiser.bind(this);
	}
	
	getAdvertiserList()
	{
		let _self = this;
		this.getXHRHandler()
			.launchPost(__ADD_ADVERTISERS_LIST, {dspID: sessionStorage.getValue(__CREATE_ADD_DSP_DSPID_FIELD)},
						(response) => {
							_self.setState(
								{advertiserList: this.getXHRHandler().getSuccessData(response), loading: false});
						}, (error) => {
					showNotify({message: this.getXHRHandler().getErrorDescription(error)});
				});
	}
	
	componentDidMount()
	{
		this.getAdvertiserList();
	}
	
	openDeleteDialog = (advertiserId) => {
		this.setState({openDeleteDialog: true, deleteAdvertiserID: advertiserId});
	};
	
	closeDeleteDialog = () => {
		this.setState({openDeleteDialog: false});
	};
	
	deleteAdvertiser()
	{
		let _self = this;
		
		this.getXHRHandler()
			.launchDelete(__ADD_ADVERTISERS_TO_USER ,{advertiserID: this.state.deleteAdvertiserID}, (response) => {
				_self.setState({removeAdvertiser: this.getXHRHandler().getSuccessData(response)});
				window.location.reload();
				showNotify({message : 'Advertiser Deleted'});
			}, (error) => {
				showNotify({message: this.getXHRHandler().getErrorDescription(error)});
			});
	}

	render()
	{
		let advertiserList = this.state.advertiserList;
		let _self          = this;
		
		if(this.state.loading){
			return <Grid container justify={"center"}>
				<CircularProgress/>
			</Grid>;
		}
		return (<Grid>
			<Scrollbar style={{height:248,width:"auto"}}>
				{
					advertiserList.length > 0 ? advertiserList.map(function (advList, i){
						return <Card className="Lesson-Panel"   key={i}>
							<CardActionArea style={{display: "flex", justifyContent: "space-between", }}>
								<CardContent style={{alignContent: "space-around"}}>
									<Grid style={{
										marginLeft   : 15,
										display      : "flex",
										flexDirection: "column",
										textAlign    : "left",
									}}>
										<Grid>
										<Typography className="advertiser-card-title">
											{advList.advertisername}
										</Typography>
										</Grid>
										<Grid className="margin-top-15" >
										<Typography className="margin-top-15 School-of">
											{advList.remoteadvertiserid}
										</Typography>
										</Grid>
									</Grid>
								</CardContent>
							</CardActionArea>
							<CardActions style={{justifyContent: "flex-end"}}>
								<span className="title-delete-adv title" onClick={() => _self.openDeleteDialog(advList.advertiserid)}>
									Delete
								</span>
							</CardActions>
						</Card>
					}) :
					<Grid className="flex margin-top-30 margin-bottom-50 margin-left-30">
						<Typography className="text--no--advertisers">There is no associated advertiser to this DSP</Typography>
					</Grid>
				}
				{this.state.openDeleteDialog
					? <AroaDialog title="Do you want to delete this dsp?"
								  text=""
								  cancel={true}
								  accept={true}
								  acceptText="Delete"
								  acceptHandler={() => this.deleteAdvertiser()}
								  cancelHandler={() => this.closeDeleteDialog()}
								  open={this.state.openDeleteDialog}
					/>
					: null
				}
			</Scrollbar>
			</Grid>
		)
	}
}

export default AdvertiserCard;
