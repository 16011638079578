import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import PubSub from "pubsub-js";
import {__AROA_AUDIENCE_NAME_INPUT} from "../../services/event-subscriber";
import {DialogTitle, TextField} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import {showNotify} from "../AroaNotifier";
import IconButton from "@material-ui/core/IconButton";

let openAroaMarketAudienceNameDialogFn;

const DialogContent = withStyles(theme => ({
    root: {
        width: 240,
        borderRadius: 8
    },
}))(MuiDialogContent);

class AroaMarketAudienceNameDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            helperText: '',
            error: false
        };

        this.data = {audienceName: ""};
    }

    componentDidMount() {
        openAroaMarketAudienceNameDialogFn = this.handleClickOpen;
    }

    handleClickOpen = (props) => {
        this.setState({open: true});
    };

    handleSubmit = () => {
        let _data = this.data;

        if (_data.audienceName.length > 1) {
            if (_data.audienceName.match("^[A-Za-z0-9]+$")) {
                this.setState({open: false});
                PubSub.publish(__AROA_AUDIENCE_NAME_INPUT, _data);
            } else {
                showNotify({message: 'Please enter a valid name without special characters'});
            }
        } else {
            showNotify({message: 'Please enter a name for your audience'});
        }
    };
    
    handleClose = () => {
        this.setState({open: false});
    };
    
    handleChange = (event) => {
        event.persist();
        this.data.audienceName = event.target.value;
    };
    
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleSubmit();
            this.handleClose();
        }
    };

    render() {
        const {open} = this.state;
        return (
            <Grid>
                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={open}
                        disableEscapeKeyDown={true} disableBackdropClick={true}>
                    <DialogTitle className="market__dialog--close">
                        <IconButton aria-label="close" onClick={this.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="text--align--center name-dialog-container">
                        <Grid className="margin-top-30">
                            <span className="text-prompt-operations">Name your new audience</span>
                            <TextField
                                onChange={this.handleChange}
                                autoFocus={true}
                                margin="dense"
                                placeholder="Audience name"
                                id="audiencename"
                                type="text"
                                onKeyPress={this.handleKeyPress}
                            />
                            <Grid className="margin-top-20 flex flex--justify--center">
                                <Button className="button-market-name-dialog"
                                        onClick={this.handleSubmit}
                                        color="primary"
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </Grid>
        );
    }
}

export function openAroaMarketAudienceNameDialog() {
    openAroaMarketAudienceNameDialogFn();
}

export default AroaMarketAudienceNameDialog;
