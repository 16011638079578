import React from 'react';
import styles from '../../../components/AroaPage/aroatheme';
import {withStyles} from '@material-ui/core/styles';
import AroaParentComponent from "../../../components/AroaParentComponent";
import AroaStepper from "../../../atoms/AroaStepper";
import AroaBar from "../../../components/AroaBar";
import AroaPage from "../../../components/AroaPage";
import Grid from "@material-ui/core/Grid";
import AroaCartTable from "../../../atoms/AroaCartTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import AroaCartAudienceDetailRow from "../../../components/AroaCartAudienceDetailRow";
import Paper from "@material-ui/core/Paper";
import AroaCartHeader from "../../../atoms/AroaCartHeader";
import xhrHandler from "../../../services/xhr-handler";
import {__AUDIENCE, __CART_PREVIEW} from "../../../services/endpoint-manager";
import sessionStorage, {
    __CURRENT_TRANSACTION
} from "../../../services/storage-manager";
import CircularProgress from "@material-ui/core/CircularProgress";
import CartStepFooter from "../../../redux/containers/CartStepFooter";
import sessionManager from "../../../services/storage-manager";
import {showNotify} from "../../../atoms/AroaNotifier";

class CartStep1Body extends AroaParentComponent {
    constructor(props) {
        super(props);
        this.state = {
            audienceList: [],
            loadingLoader: true
        };
        this.deleteAudience = this.deleteAudience.bind(this);
        this.getLoader = this.getLoader.bind(this);
    }
    
    componentDidMount() {
        this.getAudienceList();
    }
    
    getTransactionId = () => {
        return sessionStorage.getValue(__CURRENT_TRANSACTION);
    };
    
    decreaseSessionCartItemCount = () => {
        let cartItemsCount = this.props.props.cartItemsCount;
        this.props.props.setCartItemsCount(--cartItemsCount);
    };
    
    goToMarketPlace = () => {
        let _self = this;
        _self.gotoPath('/marketplace');
    };
    
    getAudienceList() {
        const regularExpresion = new RegExp('Transaction with ID\\s(.*)\\salready exists!');
        let _self = this;
        let axiosHandler = new xhrHandler();
        axiosHandler.launchPost(__CART_PREVIEW, {"transactionID": this.getTransactionId()}, (response) => {
            if (axiosHandler.getSuccessData(response).audiences.length === 0) {
                _self.setState({audienceList: axiosHandler.getSuccessData(response).audiences,})
                sessionManager.clearCartInfo();
                _self.setState({loadingLoader: false});
                showNotify({message: 'Your Cart is empty, please lets us redirect you to Aroa Marketplace.'});
                setTimeout(this.goToMarketPlace, 2000);
            } else {
                _self.setState({
                    audienceList: axiosHandler.getSuccessData(response).audiences,
                    loadingLoader: false
                }, function () {
                    this.props.setAudiences(this.state.audienceList)
                });
            }
        }, (error) => {
            showNotify({message: this.getXHRHandler().getErrorDescription(error)});
            _self.setState({loadingLoader: false});
            if (regularExpresion.exec(this.getXHRHandler().getErrorDescription(error)) !== null) {
                return setTimeout(this.goToMarketPlace, 2000)
            } else {
                console.log("expresion nula")
            }
        });
    }
    
    deleteAudience = (audienceId) => {
        let axiosHandler = new xhrHandler();
        axiosHandler.launchDelete(__AUDIENCE, {"audienceID": audienceId}, (response) => {
            this.decreaseSessionCartItemCount();
            this.getAudienceList();
            this.componentDidMount();
        }, (error) => {
            showNotify({message: this.getXHRHandler().getErrorDescription(error)});
        });
    };
    
    getLoader() {
        const loader = (
            <Grid container justify={"center"}>
                <CircularProgress/>
            </Grid>
        );
        if (this.state.loadingLoader === true) {
            return loader;
        }
    }
    
    render() {
        const {classes} = this.props;
        return (
            <Grid>
                <AroaStepper
                    steps={['Check audiences', 'Audience attributes', 'Audience update', 'Confirm your audiences']}
                    activeState={0}
                    optionals={[]}
                    completed={[]}
                />
                <Grid className="margin-top-10">
                    <AroaCartHeader title ="Data Cart" subtitle="Check your audiences"/>
                    <Grid container direction={'row'}>
                        <Paper className="flex__full" elevation={0}>
                            {
                                this.state.audienceList.length > 0
                                    ? <AroaCartTable headers={['AUDIENCE ID', 'UNIQUE USERS']} tableClass="cart-table">
                                        {this.state.audienceList.map((audience) =>
                                            <AroaCartAudienceDetailRow
                                                key={audience.audienceID}
                                                audience={audience}
                                                classes={classes}
                                                onDelete={this.deleteAudience}
                                            />)}
                                        <TableRow>
                                            <TableCell/>
                                        </TableRow>
                                    </AroaCartTable>
                                    : this.getLoader()
                            }
                        </Paper>
                        <CartStepFooter nextLink="/cart/step2" nextText="NEXT STEP"/>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

class CartStep1 extends AroaParentComponent {
    
    constructor(props) {
        super(props);
        this.props.setCartStep(1);
    }
    
    render() {
        if (this.isUserLogged()) {
            return <AroaPage header={(new AroaBar(this.props).render())}
                             body={(<CartStep1Body props={this.props} setAudiences={this.props.setAudiences}/>)}/>;
        } else {
            this.gotoPath('/');
            return '';
        }
    }
}

export default withStyles(styles)(CartStep1);
