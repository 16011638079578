import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MarketLandingPage from "../MarketLandingPage";
import AroaBar from "../../components/AroaBar";
import AroaParentComponent from "../../components/AroaParentComponent";
import AroaPage from "../../components/AroaPage";
import styles from "../../components/AroaPage/aroatheme";
import Divider from "@material-ui/core/Divider";
import { LogoDatmeanWhite } from "../../atoms/AroaIcons";
import {
  AvanzaBusGrisLogo,
  DeportVillageGrisLogo,
  GuiaDelOcioLogo,
  MiNubeLogo,
  ScreenShotExample,
  SercotelLogo,
} from "../../atoms/AroaImages";
import Paper from "@material-ui/core/Paper";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AroaAnimatedSlider from "../../components/AroaAnimatedSlider";
import { userRoles } from "../../services/userRoles-manager";
import DataPartnerLive from "../DataPartnerLive";

const footerItems = [
  {
    title: "About us",
    description: [
      {
        class: "landing_link",
        href: "https://relevanttraffic.com/quienes-somos/",
        footText: "Who are we",
      },
      {
        class: "landing_link",
        href: "https://relevanttraffic.com",
        footText: "News",
      },
    ],
  },
  {
    title: "Help & support",
    description: [
      {
        class: "landing_link",
        href: "https://relevanttraffic.com/contactanos/",
        footText: "Contact",
      },
      {
        class: "landing_link",
        href: "https://relevanttraffic.com/politica-privacidad/",
        footText: "Privacy policy",
      },
      {
        class: "landing_link",
        href: "https://relevanttraffic.com/aviso-legal/",
        footText: "Terms & Conditions",
      },
    ],
  },
];

const FullWidthBodySlider = () => {
  return <AroaAnimatedSlider />;
};

const LandingBody = () => {
  return (
    <React.Fragment>
      <Grid className="margin-top-50">
        <Typography className="landding--redrt--text" component="span">
          Flexibility, control, transparency, efficiency.
        </Typography>
      </Grid>
      <Grid className="margin-top-50 ">
        <Typography className="landing--menu--text" component="span">
          root enables you to access millions of data that you can combine to
          find the perfect target for your programmatic campaigns.
          <br />
          Segments and audiences automatically activated through any DSP *.
        </Typography>
      </Grid>

      <Grid container className="margin-top-50 " style={{ marginBottom: 100 }}>
        <Grid
          className="margin-right-40 flex flex--direction--column how-it-works-container"
          item
          xs={12}
          md={6}
          xl={6}
          sm={12}
        >
          <Grid className="text--align--left">
            <Typography className="landding--subcribe--text" component="h3">
              How it Works
            </Typography>
            <Typography className="landding--subcribe--text" component="span">
              You will just have to do a contextual search by keyword and aroa
              will propose the most related, curated and relevant segments,
              which you can gather, combine, intersect,…
              <br />
              <br />
              And once the audience is composed you will be ready to activate it
              automatically in a simple checkout process or share it with your
              team, save it for later or reuse it at any time for any client or
              campaign. All this with a simple and intuitive tool that will
              allow you to take real time full control. Aroa enables you to
              Access millions of data that you can combine to find the perfect
              target for your programmatic campaigns. Segments and audiences
              automatically activated through any DSP*.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          className="flex big-square"
          style={{ paddingTop: 10, alignItems: "flex-end" }}
          item
          xs={12}
          md={6}
          xl={6}
          sm={12}
        >
          <ScreenShotExample alt={"Sample"} height={"100%"} width={"100%"} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const LandingFooter = () => {
  return (
    <footer>
      <Paper
        elevation={3}
        square={true}
        className=" AroaMosaic--container "
        style={{ paddingTop: 50, paddingBottom: 50 }}
      >
        <Grid container className="flex--justify--spaceevenly">
          <Grid item xl={1} sm={2} xs={12}>
            <MiNubeLogo
              className="margin-15"
              alt={"minube logo"}
              height={45}
              width={125}
            />
          </Grid>
          <Grid className="margin-15" item xl={1} sm={2} xs={12}>
            <SercotelLogo alt={"Sercotel logo"} height={55} width={110} />
          </Grid>
          <Grid className="margin-15" item xl={1} sm={2} xs={12}>
            <AvanzaBusGrisLogo alt={"avanzabus logo"} height={60} width={130} />
          </Grid>
          <Grid className="margin-15" item xl={1} sm={2} xs={12}>
            <GuiaDelOcioLogo
              alt={"guia del ocio logo"}
              height={60}
              width={100}
            />
          </Grid>
          <Grid className="margin-15" item xl={1} sm={2} xs={12}>
            <DeportVillageGrisLogo
              alt={"deportvillage logo"}
              height={80}
              width={140}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        square={true}
        className="flex flex--direction--column AroaFooter--container"
      >
        <Grid
          container
          className="flex flex--justify--spacebetween aroa-footer-nav-container"
        >
          <Grid
            item
            xs={12}
            md={6}
            xl={6}
            sm={12}
            className="flex margin-top-15 container-footer-logo"
          >
            <LogoDatmeanWhite width={170} />
          </Grid>
          <Grid item xs={12} md={6} xl={6} sm={12} className="flex flex--end">
            {footerItems.map((footer, index) => (
              <Grid
                className="flex flex--direction--column margin-left-3rem text--align--left title-container"
                key={index}
                component={"nav"}
              >
                <Typography className="footer-text-link ">
                  {footer.title}
                </Typography>
                {footer.description.map((item, index) => (
                  <Typography className="footer-link-text-title " key={index}>
                    {item.description}
                    <a
                      key={item.footText}
                      className={item.class}
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.footText}
                    </a>
                  </Typography>
                ))}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Divider light={true} />
        <Grid className="flex  aroa-footer-copyright-container">
          <Typography className="footer-copyright-text">
            &#169; {new Date().getFullYear()} Relevanttraffic. All Rights Reserved
          </Typography>
        </Grid>
      </Paper>
    </footer>
  );
};

class Landing extends AroaParentComponent {
  render() {
    if (this.isUserLogged()) {
      userRoles.loadView();
      if (userRoles.currentView() === "dataBuyer") {
        this.props.history.push("/marketplace");
        return <MarketLandingPage />;
      } else {
        return <DataPartnerLive/>
      }
    } else {
      return (
        <AroaPage
          header={new AroaBar(this.props).render()}
          bodyHeader={FullWidthBodySlider(this.props)}
          body={LandingBody(this.props)}
          footer={LandingFooter(this.props)}
        />
      );
    }
  }
}

Landing.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Landing);
