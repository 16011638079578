import React, {Component} from 'react';
import {Button, Grid, withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/es/FormControl";
import TextField from "@material-ui/core/TextField";
import {__ADD_DSP_TO_USER, __RETRIEVE_DSP_PLATFORM_LIST} from '../../../services/endpoint-manager';
import xhrHandler from "../../../services/xhr-handler";
import {__ACTION_BUTTON, __SECONDARY_BUTTON} from "../../../atoms/ButtonsStyles";
import {showNotify} from "../../../atoms/AroaNotifier";
import sessionStorage, {__SELECTED_DATABUYER} from "../../../services/storage-manager";
import NativeSelect from "@material-ui/core/NativeSelect";

const ColorButton = withStyles(() => (__ACTION_BUTTON))(Button);
const SecondaryButton = withStyles(() => (__SECONDARY_BUTTON))(Button);

class AddEditDsp extends Component {
    constructor(props) {
        super(props);
        if (props.dsp) {
            this.state = {
                dspPlatforms: [],
                seatName: props.dsp.seatname,
                seatID: props.dsp.seatid,
                dspID: props.dsp.dspid,
                dataBuyerID: props.dsp.databuyer.databuyerid,
                platformID: props.dsp.platformdspid,
                active: !!+props.dsp.active,
                edit: true,
                counter: 0
            };
        } else {
            this.state = {
                dspPlatforms: [],
                seatName: "",
                seatID: "",
                platformID: -1,
                active: true,
                edit: false
            };
        }
        this.getDSPPlatforms = this.getDSPPlatforms.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearForm = this.clearForm.bind(this);
    }

    getDSPPlatforms() {
        let _self = this;
        let axiosHandler = new xhrHandler();
        axiosHandler.launchGet(__RETRIEVE_DSP_PLATFORM_LIST, (response) => {
            _self.setState({dspPlatforms: axiosHandler.getSuccessData(response)});
        }, (error) => {
            showNotify({message: axiosHandler.getErrorDescription(error)});
        });
    }

    componentDidMount() {
        if (!this.state.dataBuyerID) {
            let actualDatabuyer = sessionStorage.getValue(__SELECTED_DATABUYER);
            this.setState({dataBuyerID: actualDatabuyer});
        }
        this.getDSPPlatforms();
        this.getDisableEditNotifier();
    }

    clearForm() {
        this.setState({
            seatName: "",
            seatID: "",
            platformID: -1
        });
    }

    componentWillUnmount() {
        this.clearForm();
    }

    handleChange(event) {
        if (event.target.id === 'active') this.setState({[event.target.id]: event.target.checked});
        else this.setState({[event.target.id]: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        let axiosHandler = new xhrHandler();
        if (this.state.seatName && this.state.seatID && this.state.platformID > 0 && this.state.dataBuyerID) {
            if (this.state.edit) {
                axiosHandler.launchPut(__ADD_DSP_TO_USER, {
                    ...this.state,
                    active: this.state.active + ""
                }, (response) => {
                    showNotify({message: axiosHandler.getSuccessMessage(response)});
                    this.props.onAccept();
                }, (error) => {
                    showNotify({message: axiosHandler.getErrorDescription(error)});
                });
            } else {
                axiosHandler.launchPost(__ADD_DSP_TO_USER, this.state, (response) => {
                    showNotify({message: axiosHandler.getSuccessMessage(response)});
                    this.props.onAccept();
                }, (error) => {
                    showNotify({message: axiosHandler.getErrorDescription(error)});
                });
            }
        } else {
            if (!this.state.dataBuyerID) showNotify({message: "Please select first a databuyer in your profile"});
            else showNotify({message: "Please make sure you have fill all required fields"});
        }
    }

    getDisableEditNotifier = () => {
        if (this.state.edit || this.counter === 0) {
            this.setState({counter: 1});
            return showNotify({message: 'DSP edition is temporaly disabled'});
        }
        ;
    }


    render() {
        const {dspPlatforms} = this.state;

        return (
            <Grid>
                <Grid className="flex flex--spaced margin-bottom-15">
                    <Grid>
                        <FormControl variant="outlined">
                            <NativeSelect
                                disabled={this.state.edit}
                                value={this.state.platformID}
                                onChange={this.handleChange}
                                inputProps={{
                                    name: 'platformID',
                                    id: 'platformID',
                                }}>
                                <option key={-1} value={-1}>
                                    Select DSP Platform
                                </option>
                                {
                                    dspPlatforms.map((currentPlatform) => {
                                        return <option key={currentPlatform.dspplatformid}
                                                       value={currentPlatform.dspplatformid}>{currentPlatform.platformname}</option>
                                    })
                                }
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid className="flex flex--spaced">
                    <Grid>
                        <FormControl required>
                            <TextField id="seatName"
                                       name="seatName"
                                       type={"text"}
                                       onChange={this.handleChange}
                                       label="Seat Name"
                                       autoComplete="seatName"
                                       autoFocus
                                       placeholder={"Seat"}
                                       helperText=""
                                       value={this.state.seatName}
                                       disabled={this.state.edit}
                            />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl required>
                            <TextField id="seatID"
                                       name="seatID"
                                       type="number"
                                       onChange={this.handleChange}
                                       label="Seat ID"
                                       autoComplete="seatID"
                                       placeholder="ID"
                                       value={this.state.seatID}
                                       onInput={(e) => {
                                           e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                       }}
                                       disabled={this.state.edit}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid className="flex flex--end dialog__footer-buttons">
                    <SecondaryButton disabled={this.state.edit} onClick={this.clearForm} color="primary">
                        Clear
                    </SecondaryButton>
                    <ColorButton onClick={this.handleSubmit} color="primary">
                        Save
                    </ColorButton>
                </Grid>
            </Grid>
        )
    }
}

export default AddEditDsp;
