import React, { Component } from "react";
import Grid from "@material-ui/core/Grid/index";
import { Link, withRouter } from "react-router-dom";
import { Button, withStyles } from "@material-ui/core";
import xhrHandler from "../../../services/xhr-handler";
import { __LOGOUT } from "../../../services/endpoint-manager";
import {
  __API_TOKEN,
  localStorageManager,
} from "../../../services/storage-manager";
import {
  __ACTION_BUTTON,
  __SECONDARY_BUTTON,
} from "../../../atoms/ButtonsStyles";
import { userRoles } from "../../../services/userRoles-manager";

const ColorButton = withStyles(() => __ACTION_BUTTON)(Button);

const SecondaryButton = withStyles(() => __SECONDARY_BUTTON)(Button);

class UserPanel extends Component {
  handleLogout(event) {
    new xhrHandler().launchPost(
      __LOGOUT,
      {},
      (response) => {
        localStorageManager.remove(__API_TOKEN);
        window.location.href = "/";
        withRouter(({ history }) => history.push("/"));
      },
      (error) => {
        this.handleClose();
      }
    );
  }

  changeView() {
    const toView =
      userRoles.currentView() === "dataPartner" ? "dataBuyer" : "dataPartner";
    userRoles.setCurrentView(toView);
    window.location.href = "/";
  }

  goToAdminPanel() {
    window.location.href = "/admin";
  }

  render() {
    return (
      <Grid>
        {userRoles.isAdmin() ? (
          <Grid
            container
            direction={"row"}
            justify={"center"}
            alignItems={"flex-end"}
          >
            <Grid style={{ margin: "5px" }}>
              <SecondaryButton onClick={() => this.changeView()}>
                VIEW{" "}
                {userRoles.currentView() === "dataPartner"
                  ? "DATABUYER"
                  : "DATAPARTNER"}
              </SecondaryButton>
            </Grid>
            <Grid style={{ margin: "5px" }}>
              <SecondaryButton onClick={() => this.goToAdminPanel()}>
                ADMIN PANEL
              </SecondaryButton>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        <Grid
          container
          direction={"row"}
          justify={"center"}
          alignItems={"flex-end"}
          className="settings__card-base__footer"
        >
          <Grid>
            <Link to={"/user/password/change"}>
              <SecondaryButton>CHANGE PASSWORD</SecondaryButton>
            </Link>
          </Grid>

          <Grid>
            <ColorButton onClick={() => this.handleLogout()}>
              LOG OUT
            </ColorButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default UserPanel;
