import React                 from 'react';
import styles                from '../../../components/AroaPage/aroatheme';
import {withStyles}          from '@material-ui/core/styles';
import AroaParentComponent   from "../../../components/AroaParentComponent";
import AroaStepper		     from "../../../atoms/AroaStepper";
import AroaBar               from "../../../components/AroaBar";
import AroaPage              from "../../../components/AroaPage";
import Grid                  from "@material-ui/core/Grid";
import sessionStorage, {__COUNT_SUCCESS_BUY_AUDIENCE } from "../../../services/storage-manager";
import sessionManager from "../../../services/storage-manager";
import AroaMessageScreen from "../../../atoms/AroaMessageScreen";
import {SuccessIcon} from "../../../atoms/AroaIcons";

class CartStep5Body extends React.Component
{

	getAudiencesCount = () => {
		let count = sessionStorage.getValue(__COUNT_SUCCESS_BUY_AUDIENCE);

		return count;
	};

	handleClick() {
		sessionManager.clearSuccessBuyInfo()
	}

	render()
	{
		return (
			<Grid>
				<AroaStepper
					steps={['Check audiences', 'Audience attributes', 'Audience update', 'Confirm your audiences']}
					activeState={3}
					optionals={[]}
					completed={[0, 1, 2, 3]}
				/>
				<AroaMessageScreen
					buttonText="Check your audiences"
					showButton={true}
					linkTo="/activity"
					icon={<SuccessIcon classes="message-screen__icon" active={true}/>}
					title="Congrats!"
					message={"You have just shopped " + this.getAudiencesCount() + " new audiences"}
					onButtonClick={this.handleClick}
				/>
			</Grid>
		)
	}
}

class CartStep5 extends AroaParentComponent
{
	render()
	{
		let props = {
			about   : false,
			platform: false,
			login   : false
		};
		return (
			
			<AroaPage header={(new AroaBar({...this.props, ...props}).render())}
					  body={(new CartStep5Body(this.props).render())}/>
		)
	}
}

export default withStyles(styles)(CartStep5);
