import React, {Fragment} from 'react';
import xhrHandler from "../../../services/xhr-handler";
import {__DSP_BY_DATABUYER, __DELETE_DSP, __ENABLE_DSP} from "../../../services/endpoint-manager";
import Grid from "@material-ui/core/Grid";
import {Button, withStyles} from "@material-ui/core";
import {__ACTION_BUTTON} from "../../../atoms/ButtonsStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import AroaDSPListItem from "../../../atoms/AroaDSPListItem";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import FormModal from "../../AroaModals/FormModal";
import AddEditDsp from "../../AroaForms/AddEditDsp";
import AroaAdvertiserList from "../../../atoms/AroaAdvertiserList";
import Scrollbar from "react-scrollbars-custom";
import sessionStorage, {__SELECTED_DATABUYER} from "../../../services/storage-manager";
import {showNotify} from "../../../atoms/AroaNotifier";
import AroaParentComponent from "../../AroaParentComponent";

const ColorButton = withStyles(() => (__ACTION_BUTTON))(Button);

function TabPanel(props) {
    const {children, value, index} = props;

    return (
        <Box role="tabpanel"
             hidden={value !== index}
             id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`} width="100%">
            <Grid container className="height--full">
                {children}
            </Grid>
        </Box>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        style: {width: '50%'}
    };
}

class DSPListPanel extends AroaParentComponent {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleCloseDSP = this.handleCloseDSP.bind(this);
        this.getLoader = this.getLoader.bind(this);

        this.state = {
            dspList: [],
            tabIndex: 0,
            openAddDspDialog: false,
            openEditDspDialog: false,
            openAdvertiserListDialog: false,
            selectedDsp: null,
            loadingLoader: true
        };
    }

    componentDidMount() {
        this.getDSPList();
    }

    getActualDatabuyer() {
        return sessionStorage.getValue(__SELECTED_DATABUYER);
    }

    getDSPList() {
        let _self = this;
        let axiosHandler = new xhrHandler();

        axiosHandler.launchGet(__DSP_BY_DATABUYER + this.getActualDatabuyer(), (response) => {
            if (axiosHandler.getSuccessData(response).length > 0) {
                _self.setState({dspList: axiosHandler.getSuccessData(response)});
                _self.setState({loadingLoader: false});
            } else {
                _self.setState({loadingLoader: false});
                showNotify({message: 'No Assocciated DSP to this Databuyer'})
            }
            ;
        }, (error) => {
            showNotify({message: this.getXHRHandler().getErrorDescription(error)});
            _self.setState({loadingLoader: false});
        });
    }

    disableDSP = (dspId) => {
        let axiosHandler = new xhrHandler();
        axiosHandler.launchDelete(__DELETE_DSP, {"dspID": dspId}, (success) => {
            this.setState({dspList: []});
            this.getDSPList();
        }, (error) => {
            console.log(axiosHandler.getErrorDescription(error));
        });
    };

    enableDSP = (dspId) => {
        let axiosHandler = new xhrHandler();
        axiosHandler.launchPost(__ENABLE_DSP, {"dspID": dspId}, (success) => {
            this.setState({dspList: []});
            this.getDSPList();
        }, (error) => {
            console.log(axiosHandler.getErrorDescription(error));
        });
    };

    editDSP = (dsp) => {
        this.setState({openEditDspDialog: true, selectedDsp: dsp});
    };

    advertiserList = (dsp) => {
        this.setState({openAdvertiserListDialog: true, selectedDsp: dsp});
    };

    handleCloseDSP = () => {
        this.setState({openAddDspDialog: false});
        this.setState({openEditDspDialog: false});
        this.getDSPList();
    };

    handleChange(event, newValue) {
        this.setState({tabIndex: newValue});
    }

    getLoader() {
        const loader = (
            <Grid container justify={"center"}>
                <CircularProgress/>
            </Grid>
        );
        if (this.state.loadingLoader === true) {
            return loader;
        } else {
        }
        ;
    }

    render() {
       
        const value = this.state.tabIndex;

        return (
            <Fragment>
                <Grid className="width--full">
                    <Tabs value={value} onChange={this.handleChange}>
                        <Tab label="ACTIVE" {...a11yProps(0)} />
                        <Tab label="INACTIVE" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Grid className="settings__card-base__body">
                            <Scrollbar style={{height: 210}}>
                                {
                                    this.state.dspList.length > 0
                                        ? this.state.dspList.filter(
                                        (item) => item.active === "1"
                                        ).map((item) =>
                                            <AroaDSPListItem
                                                item={item} key={item.dspid}
                                                onDisable={this.disableDSP}
                                                onEdit={this.editDSP}
                                                onAdvertiserList={this.advertiserList}
                                            />
                                        )
                                        : this.getLoader()
                                }
                            </Scrollbar>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Grid className="settings__card-base__body">
                            <Scrollbar style={{height: 210}}>
                                {
                                    this.state.dspList.length > 0
                                        ? this.state.dspList.filter(
                                        (item) => item.active === "0"
                                        ).map((item) =>
                                            <AroaDSPListItem
                                                item={item} key={item.dspid}
                                                onEnable={this.enableDSP}
                                                onEdit={this.editDSP}
                                                onAdvertiserList={this.advertiserList}
                                            />
                                        )
                                        : this.getLoader()
                                }
                            </Scrollbar>
                        </Grid>
                    </TabPanel>
                </Grid>
                <Grid container justify={"center"} alignItems={"flex-end"} className="settings__card-base__footer">
                    <Grid>
                        <ColorButton color="secondary" onClick={() => this.setState({openAddDspDialog: true})}>
                            + ADD DSP
                        </ColorButton>
                    </Grid>
                    <FormModal opened={this.state.openAddDspDialog}
                               title="Add DSP to the list"
                               closeIcon={true}
                               onClose={() => this.setState({openAddDspDialog: false})}
                    >
                        <AddEditDsp onAccept={this.handleCloseDSP}/>
                    </FormModal>
                    <FormModal opened={this.state.openEditDspDialog}
                               title="Edit DSP"
                               closeIcon={true}
                               onClose={() => this.setState({openEditDspDialog: false})}
                    >
                        <AddEditDsp onAccept={this.handleCloseDSP} dsp={this.state.selectedDsp}/>
                    </FormModal>
                    <FormModal opened={this.state.openAdvertiserListDialog}
                               closeIcon={true}
                               className="advertiser-list-modal"
                               onClose={() => this.setState({openAdvertiserListDialog: false})}
                    >
                        <AroaAdvertiserList dspid={this.state.selectedDsp ? this.state.selectedDsp.dspid : null}/>
                    </FormModal>
                </Grid>
            </Fragment>
        );
    }
}

export default DSPListPanel;
