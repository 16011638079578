import React from "react";
import styles from "../../../components/AroaPage/aroatheme";
import { withStyles } from "@material-ui/core/styles";
import AroaParentComponent from "../../../components/AroaParentComponent";
import AroaStepper from "../../../atoms/AroaStepper";
import AroaBar from "../../../components/AroaBar";
import AroaPage from "../../../components/AroaPage";
import Grid from "@material-ui/core/Grid";
import AroaCartTable from "../../../atoms/AroaCartTable";
import Paper from "@material-ui/core/Paper";
import AroaCartHeader from "../../../atoms/AroaCartHeader";
import sessionStorage, {
  __CURRENT_TRANSACTION, __SELECTED_DATABUYER,
} from "../../../services/storage-manager";
import xhrHandler from "../../../services/xhr-handler";
import {
  __GET_DSPS_BY_DATABUYER,
  __CART_DEVICES,
  __CART_PREVIEW,
  __CART_PURCHASES_TYPES,
  __CART_BILLINGMAPPINGS
} from "../../../services/endpoint-manager";
import sessionManager from "../../../services/storage-manager";
import CircularProgress from "@material-ui/core/CircularProgress";
import CartAudiencesAttributes from "../../../redux/containers/CartAudiencesAttributes";
import CartStepFooter from "../../../redux/containers/CartStepFooter";
import { showNotify } from "../../../atoms/AroaNotifier";

class CartStep2Body extends AroaParentComponent {
  constructor(props) {
    super(props);
    this.state = {
      audienceList: [],
      billingMappings: [],
      platformList: [],
      deviceList: [],
      purchaseList: [],
      loadingLoader: true,
    };

    this.getLoader = this.getLoader.bind(this);
    this.axiosHandler = new xhrHandler();
  }

  componentDidMount() {
    this.getAudienceList();
    this.getPlatformList();
    this.getDeviceList();
    this.getPurchaseList();
    this.getBillingMappings();
  }

  getTransactionId = () => {
    return sessionStorage.getValue(__CURRENT_TRANSACTION);
  };

  getAudienceList() {
    let _self = this;
    let axiosHandler = new xhrHandler();
    axiosHandler.launchPost(
      __CART_PREVIEW,
      { transactionID: this.getTransactionId() },
      (response) => {
        if (axiosHandler.getSuccessData(response).audiences.length === 0) {
          sessionManager.clearCartInfo();
          _self.setState({ loadingLoader: false });
          showNotify({
            message:
              "Your Cart is empty, please lets us redirect you to Aroa Marketplace.",
          });
          setTimeout(this.goToMarketPlace, 2000);
        } else {
          _self.setState(
            {
              audienceList: axiosHandler.getSuccessData(response).audiences,
              loadingLoader: false,
            },
            function () {
              this.props.setAudiences(this.state.audienceList);
            }
          );
        }
      },
      (error) => {
        showNotify({
          message: this.getXHRHandler().getErrorDescription(error),
        });
        _self.setState({ loadingLoader: false });
        const regularExpresion = new RegExp(
          "Transaction with ID\\s(.*)\\salready exists!"
        );
        if (
          regularExpresion.exec(
            this.getXHRHandler().getErrorDescription(error)
          ) !== null
        ) {
          return setTimeout(this.goToMarketPlace, 2000);
        }
      }
    );
  }

  getPlatformList() {
    let _self = this;
    let axiosHandler = new xhrHandler();
    let databuyer =  sessionManager.getValue(__SELECTED_DATABUYER)
    axiosHandler.launchGet(
        __GET_DSPS_BY_DATABUYER(databuyer),
      (response) => {
        let dspList = axiosHandler.getSuccessData(response);
        dspList.map((dsp) => {
          _self.setState({
            platformList: this.state.platformList.concat({
              id: dsp.dspid,
              name: dsp.platform.platformname + " / " + dsp.seatname,
            }),
          });
          return true;
        });
      },
      (error) => {
        showNotify({
          message: this.getXHRHandler().getErrorDescription(error),
        });
      }
    );
  }

  getBillingMappings() {
    this.axiosHandler.launchGet(
      __CART_BILLINGMAPPINGS,
      (response) => {
        this.setState({
          billingMappings: this.axiosHandler.getSuccessData(response).billingMappings,
        });
      },
      (error) => {
        showNotify({
          message: this.axiosHandler.getErrorDescription(error),
        });
      }
    );
  }

  getDeviceList() {
    let _self = this;
    let axiosHandler = new xhrHandler();
    axiosHandler.launchGet(
      __CART_DEVICES,
      (response) => {
        _self.setState({
          deviceList: axiosHandler.getSuccessData(response).devices,
        });
      },
      (error) => {
        showNotify({
          message: this.getXHRHandler().getErrorDescription(error),
        });
      }
    );
  }

  getPurchaseList() {
    let _self = this;
    let axiosHandler = new xhrHandler();
    axiosHandler.launchGet(
      __CART_PURCHASES_TYPES,
      (response) => {
        _self.setState({
          purchaseList: axiosHandler.getSuccessData(response).purchasesTypes,
        });
      },
      (error) => {
        showNotify({
          message: this.getXHRHandler().getErrorDescription(error),
        });
      }
    );
  }

  getLoader() {
    const loader = (
      <Grid container justify={"center"}>
        <CircularProgress />
      </Grid>
    );
    if (this.state.loadingLoader === true) {
      return loader;
    } else {
    }
  }

  goToMarketPlace = () => {
    let _self = this;
    _self.gotoPath("/marketplace");
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid>
        <AroaStepper
          steps={[
            "Check audiences",
            "Audience attributes",
            "Audience update",
            "Confirm your audiences",
          ]}
          activeState={1}
          optionals={[]}
          completed={[0]}
        />
        <Grid className="margin-top-10">
          <AroaCartHeader title="Data Cart" subtitle="Audience attributes" />
          <Grid container direction={"row"}>
            <Paper className="flex__full" elevation={0}>
              {this.state.audienceList.length > 0 ? (
                <AroaCartTable
                  headers={[
                    "AUDIENCE ID",
                    "PLATFORM",
                    "BILLING",
                    "DEVICE",
                    "CMP/CPU",
                    "ADVERTISER",
                  ]}
                  bodyClass="cart-table--no-padding"
                  tableClass="cart-table"
                  emptyLine={true}
                >
                  {this.state.audienceList.map((audience) => (
                    <CartAudiencesAttributes
                      key={audience.audienceID}
                      audience={audience}
                      billingMappings={this.state.billingMappings}
                      platforms={this.state.platformList}
                      devices={this.state.deviceList}
                      purchases={this.state.purchaseList}
                      classes={classes}
                    />
                  ))}
                </AroaCartTable>
              ) : (
                this.getLoader()
              )}
            </Paper>
            <CartStepFooter
              nextLink="/cart/step3"
              nextText="NEXT STEP"
              prevLink="/cart/step1"
              prevText="BACK TO CHECK AUDIENCES"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

class CartStep2 extends AroaParentComponent {
  constructor(props) {
    super(props);
    this.props.setCartStep(2);
  }

  render() {
    let props = {
      about: false,
      platform: false,
      login: false,
    };
    return (
      <AroaPage
        header={new AroaBar({ ...this.props, ...props }).render()}
        body={
          <CartStep2Body
            props={this.props}
            setAudiences={this.props.setAudiences}
          />
        }
      />
    );
  }
}

export default withStyles(styles)(CartStep2);
