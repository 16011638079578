import React, {Fragment} from "react";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import AroaCartAudienceDetails from "../../atoms/AroaCartAudienceDetails";
import AroaDialog from "../../atoms/AroaDialog";
import {withStyles} from "@material-ui/core";
import {__ACTION_BUTTON, __SECONDARY_BUTTON} from "../../atoms/ButtonsStyles";

const ColorButton = withStyles(() => (__ACTION_BUTTON))(Button);
const SecondaryButton = withStyles(() => (__SECONDARY_BUTTON))(Button);

class AroaCartAudienceDetailRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {detailIsOpen: false, openDeleteDialog: false};
    }

    openDeleteDialog = () => {
        this.setState({openDeleteDialog: true});
    };
    
    hideDeleteDialog = () => {
        this.setState({openDeleteDialog: false});
    };

    detailPanelHandler(isOpen) {
        this.setState({detailIsOpen: isOpen});
    }
    
    handleDelete() {
        this.props.onDelete(this.props.audience.audienceID);
    }

    renderButtonOn() {
        return (
            <ColorButton
                onClick={() => this.detailPanelHandler(false)}
            >
                HIDE DETAILS
            </ColorButton>
        );
    }

    renderButtonOff() {
        return (
            <SecondaryButton
                onClick={() => this.detailPanelHandler(true)}
            >
                DETAILS
            </SecondaryButton>
        );
    }

    renderPanelOn(audience) {
        return (
            <TableRow>
                <TableCell colSpan={3} style={{backgroundColor: "#f5f5f5"}}>
                    <AroaCartAudienceDetails segments={audience.segments} onButtonClick={() => this.detailPanelHandler(false)}/>
                </TableCell>
            </TableRow>
        );
    }

    renderDeleteDialog() {
        return (
            <AroaDialog
                open={true}
                title={"Remove Audience?"}
                text={"This will remove the audience from your cart."}
                accept={true}
                cancel={true}
                acceptHandler={() => this.handleDelete()}
                cancelHandler={() => this.hideDeleteDialog()}
                acceptText={"Remove"}
            />
        );
    }

    render() {
        const detailIsOpen = this.state.detailIsOpen;
        const openDeleteDialog = this.state.openDeleteDialog;
        const {audience} = this.props;

        let detailButton = detailIsOpen ? this.renderButtonOn() : this.renderButtonOff();
        let detailPanel = detailIsOpen ? this.renderPanelOn(audience) : null;
        let deleteDialog = openDeleteDialog ? this.renderDeleteDialog() : null;

        return (
            <Fragment>
                <TableRow style={{height: 100}}>
                    <TableCell component="th" scope="row" className="aroaColor">{audience.audienceName}
                        <Grid container className="margin-top-10 margin-bottom-10" spacing={2}>
                            <Grid item>
                                {detailButton}
                            </Grid>
                            <Grid item>
                                <SecondaryButton onClick={() => this.openDeleteDialog()}>DELETE</SecondaryButton>
                                {deleteDialog}
                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell className="aroaColor text--align-right">{audience.numCookies}</TableCell>
                </TableRow>
                {detailPanel}
            </Fragment>
        )
    }
}

export default AroaCartAudienceDetailRow;
