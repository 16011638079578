import React from 'react';
import styles from '../../../components/AroaPage/aroatheme';
import {withStyles} from '@material-ui/core/styles';
import AroaParentComponent from "../../../components/AroaParentComponent";
import AroaStepper from "../../../atoms/AroaStepper";
import AroaBar from "../../../components/AroaBar";
import AroaPage from "../../../components/AroaPage";
import Grid from "@material-ui/core/Grid";
import AroaCartTable from "../../../atoms/AroaCartTable";
import Paper from "@material-ui/core/Paper";
import AroaCartHeader from "../../../atoms/AroaCartHeader";
import xhrHandler from "../../../services/xhr-handler";
import {__CART_PREVIEW, __CART_TRANSACTION_TYPES} from "../../../services/endpoint-manager";
import sessionStorage, {__CURRENT_TRANSACTION} from "../../../services/storage-manager";
import sessionManager from "../../../services/storage-manager";
import CartAudiencesDates from "../../../redux/containers/CartAudiencesDates";
import CartStepFooter from "../../../redux/containers/CartStepFooter";
import {showNotify} from "../../../atoms/AroaNotifier";
import CircularProgress from "@material-ui/core/CircularProgress";


class CartStep3Body extends AroaParentComponent {
    constructor(props) {
        super(props);
        this.state = {
            audienceList: [],
            transactionTypesList: [],
            loadingLoader: true
        };
        this.getAudienceList = this.getAudienceList.bind(this);
        this.getTransactionTypesList = this.getTransactionTypesList.bind(this);
        this.getLoader= this.getLoader.bind(this);
    }

    componentDidMount() {
        this.getAudienceList();
        this.getTransactionTypesList();
    }

    getTransactionId = () => {
        return sessionStorage.getValue(__CURRENT_TRANSACTION);
    };

    getAudienceList() {
        let _self = this;
        let axiosHandler = new xhrHandler();
        axiosHandler.launchPost(__CART_PREVIEW, {"transactionID": this.getTransactionId()}, (response) => {
            if (axiosHandler.getSuccessData(response).audiences.length === 0) {
                sessionManager.clearCartInfo();
                _self.setState({loadingLoader : false});
                showNotify({ message : 'Your Cart is empty, please lets us redirect you to Aroa Marketplace.'});
                setTimeout( this.goToMarketPlace,2000);
            }else {
                _self.setState({audienceList: axiosHandler.getSuccessData(response).audiences, loadingLoader : false}, function () {
                    this.props.setAudiences(this.state.audienceList)
                });
            }
        }, (error) => {
            showNotify({message: this.getXHRHandler().getErrorDescription(error)});
            _self.setState({loadingLoader : false});
            const regularExpresion = new RegExp('Transaction with ID\\s(.*)\\salready exists!');
            if (regularExpresion.exec(this.getXHRHandler().getErrorDescription(error)) !== null){
                return setTimeout(this.goToMarketPlace, 2000)
            }
        });
    }

    getTransactionTypesList() {
        let _self = this;
        let axiosHandler = new xhrHandler();
        axiosHandler.launchGet(__CART_TRANSACTION_TYPES, (response) => {
            _self.setState({transactionTypesList: axiosHandler.getSuccessData(response).types});
        }, (error) => {
            showNotify({message: this.getXHRHandler().getErrorDescription(error)});
        });
    }

    getLoader() {
        const loader = (
            <Grid container justify={"center"}>
                <CircularProgress/>
            </Grid>
        );
        
        if (this.state.loadingLoader === true) {
            return loader;
        }
    }

    goToMarketPlace = () => {
        let _self = this;
        _self.gotoPath('/marketplace');
    };

    render() {
        const audienceList = this.state.audienceList;
        const {transactionTypesList} = this.state;

        return (
            <Grid>
                <AroaStepper
                    steps={['Check audiences', 'Audience attributes', 'Audience update', 'Confirm your audiences']}
                    activeState={2}
                    optionals={[]}
                    completed={[0, 1]}
                />
                <Grid style={{marginTop: 10}}>
                    <AroaCartHeader title="Data Cart" subtitle="Audience update"/>
                    <Grid container direction={'row'}>
                        <Paper className="flex__full" elevation={0}>
                            {this.state.audienceList.length > 0
                                ? <AroaCartTable
                                    headers={['AUDIENCE ID', 'EXPIRATION', 'TYPES']}
                                    emptyLine={true}
                                    tableClass="cart-table"
                                >
                                    {
                                        audienceList.map(audience => (
                                            <CartAudiencesDates
                                                key={audience.audienceID}
                                                audience={audience}
                                                transactions={transactionTypesList}
                                            />
                                        ))
                                    }
                                </AroaCartTable>
                                : this.getLoader()
                            }
                        </Paper>
                        <CartStepFooter
                            nextLink="/cart/step4"
                            nextText="NEXT STEP"
                            prevLink="/cart/step2"
                            prevText="BACK TO AUDIENCE ATTRIBUTES"
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

class CartStep3 extends AroaParentComponent {
    constructor(props) {
        super(props);
        this.props.setCartStep(3);
    }

    render() {
        let props = {
            about: false,
            platform: false,
            login: false
        };
        return (

            <AroaPage header={(new AroaBar({...this.props, ...props}).render())}
                      body={<CartStep3Body props={this.props} setAudiences={this.props.setAudiences}/>}/>
        )
    }
}

export default withStyles(styles)(CartStep3);
