import React                                          from 'react';
import Grid                                           from "@material-ui/core/Grid";
import AroaParentComponent                            from "../../AroaParentComponent";
import {__DELETE_DSP}                                 from "../../../services/endpoint-manager";
import sessionStorage, {__CREATE_ADD_DSP_DSPID_FIELD} from "../../../services/storage-manager";
import Paper from "@material-ui/core/Paper";
import {ImageUnavailableIcon} from "../../../atoms/AroaIcons";
import AroaDialog from "../../../atoms/AroaDialog";

class DspStep2Active extends AroaParentComponent
{
	constructor(props)
	{
		super(props);
		this.state = {openDeleteDialog: false};
		this.handleAdvertiserList = this.handleAdvertiserList.bind(this);
		this.handleDeleteDsp      = this.handleDeleteDsp.bind(this);
		this.openDelete = this.openDelete.bind(this);
	}
	
	handleAdvertiserList()
	{
		sessionStorage.setValue(__CREATE_ADD_DSP_DSPID_FIELD, this.props.dspData.dspid);
		this.gotoPath('/createadvertiser/step2')
	}
	
	handleDeleteDsp()
	{
		this.getXHRHandler().launchDelete(__DELETE_DSP, {dspID: this.props.dspData.dspid}, (response) => {
			window.location.reload();
		}, (error) => {
			console.info(error);
		});
	}
	
	openDelete = () => {
		this.setState({openDeleteDialog: true});
	};
	
	closeDelete = () => {
		this.setState({openDeleteDialog: false});
	};
	
	render()
	{
		return (
			<Paper className="create-dsp-item">
				<Grid container alignItems={"center"} justify={"space-between"} className="height--full">
					<Grid className="flex flex--centered flex--item">
						<ImageUnavailableIcon classes="business__image"/>
						<Grid className="flex flex--column titles--margin flex--centered flex--top fix-width flex--item">
							<span className="title">{this.props.plataformName}</span>
							<span>{this.props.seatName}</span>
							<span>{this.props.seatId}</span>
						</Grid>
					</Grid>
					<Grid className="flex flex--column height--full flex--around flex--bottom flex--item">
						<span>{this.props.dspData.databuyer.businessname}</span>
						<Grid className="flex">
							<span className="title" onClick={this.handleAdvertiserList}>Advertiser List</span>
							<span className="title--separator">|</span>
							<span className="title" onClick={() => this.openDelete()}>Delete</span>
						</Grid>
					</Grid>
					{
						this.state.openDeleteDialog
						? <AroaDialog title="Do you want to delete this dsp?"
									  text=""
									  cancel={true}
									  accept={true}
									  acceptText="Delete"
									  acceptHandler={this.handleDeleteDsp}
									  cancelHandler={() => this.closeDelete()}
									  open={this.state.openDeleteDialog}
						/>
						: null
					}
					
				</Grid>
			</Paper>
		)
	}
}

export default DspStep2Active
