import React                 from 'react';
import styles                from '../../../components/AroaPage/aroatheme';
import {withStyles}          from '@material-ui/core/styles';
import AroaParentComponent   from "../../../components/AroaParentComponent";
import AroaStepper from "../../../atoms/AroaStepper";
import AroaPage              from "../../../components/AroaPage";
import Grid                  from "@material-ui/core/Grid";
import {Typography}          from "@material-ui/core";
import {Link}                from "react-router-dom";
import Fab                   from "@material-ui/core/Fab";
import SimpleAroaBar         from "../../../atoms/AroaSimpleBar";
import {SuccessIcon} from "../../../atoms/AroaIcons";

class CreateUserStep4Body extends React.Component
{
	
	render()
	{
		return (
			<Grid>
				<AroaStepper
					steps={['Create an account', 'Add users', 'Add DSP', 'Add advertiser']}
					activeState={1}
					optionals={[1]}
					completed={[0]}
				/>
				<Grid className="margin-top-50" >
					<SuccessIcon classes="message-screen__icon" active={true}/>
					<Typography  className="margin-top-30 welcome-title" >
						Invitation sent
					</Typography>
					<Typography className="margin-top-10 welcome-text" >
						We have sent an email to join your team!<br/>
						Do you want to continue joining members to your team?
					</Typography>
					<Grid container justify={'center'} direction={'row'}>
						<Grid className="margin-5">
							<Link to={"/createuser/step3"}>
								<Fab variant="extended" aria-label="LOG IN" className="margin-top-20 button--blue--border">
									YES, I´LL ASK SOMEONE ELSE
								</Fab>
							</Link>
						</Grid>
						<Grid className="margin-5" >
							<Link to={"/createuser/dsp/step1"}>
								<Fab variant="extended" aria-label="Dsp step1" className="margin-top-20 button-signup-login">
									NO, I'M DONE
								</Fab>
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)
	}
}

class CreateUserStep4 extends AroaParentComponent
{
	render()
	{
		if(this.isUserLogged()){
			return <AroaPage header={(new SimpleAroaBar(this.props).render())}
							 body={(new CreateUserStep4Body(this.props).render())}/>;
		}else{
			this.gotoPath('/');
			return '';
		}
	}
}

export default withStyles(styles)(CreateUserStep4);
