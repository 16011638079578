import Paper from "@material-ui/core/Paper";
import React, {Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import {Typography, withStyles} from "@material-ui/core";
import AroaCartTable from "../AroaCartTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import {__ACTION_BUTTON} from "../ButtonsStyles";
import {format} from "date-fns";
import Scrollbar from "react-scrollbars-custom";

const ColorButton = withStyles(() => (__ACTION_BUTTON))(Button);

const AroaActivityAudienceDetails = ({audience, onButtonClick}) => {

    return (
        <Grid
            container
            alignItems="flex-start"
            className="activity-audience__detail"
        >
            <Fragment>
                <Typography variant={"body2"} className="activity-audience__detail__title">SEGMENTS</Typography>
                <Grid container className="margin-bottom-20">
                    <Grid item xs={12}>
                        <Paper className="paper detail-scrollbar__table">
                            <Scrollbar
                                style={{width: 921, height: 150}}>
                            <AroaCartTable headers={['DATA PARTNER', 'TYPE', 'VALUE', 'Nº COOKIES']} tableClass="activity-table">
                                {audience.segments ? audience.segments.map(segment => (
                                    <TableRow key={segment.segmentid}>
                                        <TableCell>{segment.datapartner.name}</TableCell>
                                        <TableCell>{segment.type}</TableCell>
                                        <TableCell>{segment.value}</TableCell>
                                        <TableCell>{segment.ncookies}</TableCell>
                                    </TableRow>
                                )) : ""}
                            </AroaCartTable>
                            </Scrollbar>
                        </Paper>
                    </Grid>
                </Grid>
            </Fragment>
            <Fragment>
                <Grid container justify={"space-between"} spacing={2} className="margin-bottom-20 fix-height">
                    <Grid item xs={2} className="flex flex--column">
                        <Typography variant={"body2"} className="activity-audience__detail__title text--align--left">LAST UPDATE</Typography>
                        <Paper className="card">
                            <Grid container direction={"row"} alignItems={"center"}>
                                <Grid item xs={12}>
                                    <Typography variant={"body2"} className={"title"} display={"inline"}>DATE</Typography>
                                    <Typography variant={"body2"} display={"inline"}>{audience.laststateupdatedate ? format(new Date(audience.laststateupdatedate), 'yyyy-MM-dd') : ""}</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={10} className="detail__scrollbar__table ">
                        <Scrollbar
                            style={{width:780, height: 175}}>
                        <AroaCartTable headers={['STATES', 'DATE', 'USER']} tableClass="activity-table--reverse">
                            {audience.stateloghistory ? audience.stateloghistory.map((state, index) => (
                                <TableRow key={index}>
                                    <TableCell>{state.audiencestate.statename}</TableCell>
                                    <TableCell>{state.logtimestamp ? format(new Date(state.logtimestamp), 'yyyy-MM-dd') : ""}</TableCell>
                                    <TableCell>{state.user.name}</TableCell>
                                </TableRow>
                            )) : ""}
                        </AroaCartTable>
                        </Scrollbar>
                    </Grid>
                </Grid>
            </Fragment>
            <Grid container justify={"flex-end"} className="activity-audience__detail__footer">
                <ColorButton
                    onClick={onButtonClick}
                >
                    HIDE DETAILS
                </ColorButton>
            </Grid>
        </Grid>
    )
};

export default AroaActivityAudienceDetails;
