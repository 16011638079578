import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import "./assets/css/aroa-margins.css";
import "./assets/css/aroa.css";
import "./assets/css/aroa_cristo.css";
import "./assets/css/aroa_carlos.css";
import "./assets/css/aroa-margins_carlos.css";
import "./assets/css/aroa_javier.css";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import { withCookies } from "react-cookie";
import ForgotPassword from "./screens/ForgotPassword";
import Landing from "./screens/Landing";
import LogIn from "./screens/LogIn";
import MarketLandingPage from "./screens/MarketLandingPage";
import SignUp from "./screens/Signup";
import CreateUserStep1 from "./screens/CreateUser/CreateUserStep1";
import CreateUserStep2 from "./screens/CreateUser/CreateUserStep2";
import CreateUserStep3 from "./screens/CreateUser/CreateUserStep3";
import CreateUserStep4 from "./screens/CreateUser/CreateUserStep4";
import CreateDspStep1 from "./screens/CreateUser/CreateDspStep1";
import CreateDspStep2 from "./screens/CreateUser/CreateDspStep2";
import CreateDspStepFinish from "./screens/CreateUser/CreateDspStepFinish";
import CreateAdvertiserStep1 from "./screens/CreateUser/CreateAdvertiserStep1";
import CreateAdvertiserStep2 from "./screens/CreateUser/CreateAdvertiserStep2";
import CreateAdvertiserStep3 from "./screens/CreateUser/CreateAdvertiserStep3";
import CartStep5 from "./screens/Cart/CartStep5";
import CartStep5Error from "./screens/Cart/CartStep5Error";
import Activity from "./screens/Activity";
import CartStepContainer1 from "./redux/containers/CartScreen1";
import CartStepContainer2 from "./redux/containers/CartScreen2";
import CartStepContainer3 from "./redux/containers/CartScreen3";
import CartStepContainer4 from "./redux/containers/CartScreen4";

import PageNotFound from "./screens/404";
import ResetPassword from "./screens/ResetPassword";
import PasswordChangedSuccess from "./screens/PasswordChangedSuccess";
import PasswordMailSent from "./screens/PasswordMailSent";
import Costs from "./screens/Costs";
import CostsUploader from "./screens/Costs/Uploader";
import Billing from "./screens/Billing";
import DataPartnerPastView from "./screens/DataPartnerPastView";
import DataPartnerLive from "./screens/DataPartnerLive";
import DataPartnerLiveAudiences from "./screens/DataPartnerLiveAudiences";
import Consolidated from "./screens/Consolidated";

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      "-apple-system",
      '"Open Sans"',
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <React.Fragment>
            <CssBaseline />
            <Switch>
              <Route path="/" exact component={Landing} />
              <Route path="/login" exact component={LogIn} />
              <Route path="/signup" exact component={SignUp} />
              <Route path="/marketplace" exact component={MarketLandingPage} />
              <Route
                path="/createuser/step1"
                exact
                component={CreateUserStep1}
              />
              <Route
                path="/createuser/step2"
                exact
                component={CreateUserStep2}
              />
              <Route
                path="/createuser/step3"
                exact
                component={CreateUserStep3}
              />
              <Route
                path="/createuser/step4"
                exact
                component={CreateUserStep4}
              />
              <Route
                path="/createuser/dsp/step1"
                exact
                component={CreateDspStep1}
              />
              <Route path="/createdsp/step2" exact component={CreateDspStep2} />
              <Route
                path="/createdsp/stepfinish"
                exact
                component={CreateDspStepFinish}
              />
              <Route
                path="/createadvertiser/step1"
                exact
                component={CreateAdvertiserStep1}
              />
              <Route
                path="/createadvertiser/step2"
                exact
                component={CreateAdvertiserStep2}
              />
              <Route
                path="/createadvertiser/step3"
                exact
                component={CreateAdvertiserStep3}
              />
              <Route path="/cart/step1" exact component={CartStepContainer1} />
              <Route path="/cart/step2" exact component={CartStepContainer2} />
              <Route path="/cart/step3" exact component={CartStepContainer3} />
              <Route path="/cart/step4" exact component={CartStepContainer4} />
              <Route path="/cart/success" exact component={CartStep5} />
              <Route path="/cart/error" exact component={CartStep5Error} />
              <Route path="/activity" exact component={Activity} />
              <Route path="/spent" exact component={Costs} />
              <Route path="/costs/upload" exact component={CostsUploader} />
              <Route path="/dataPartner" exact component={DataPartnerLive} />
              <Route
                path="/dataPartner/live"
                exact
                component={DataPartnerLiveAudiences}
              />
              <Route path="/past" exact component={DataPartnerPastView} />
              <Route path="/billing" exact component={Billing} />
              <Route path="/consolidated" exact component={Consolidated} />
              <Route
                path="/password/remember"
                exact
                component={ForgotPassword}
              />
              <Route
                path="/password/reset/:token"
                exact
                component={ResetPassword}
              />
              <Route
                path="/password/mail/sent"
                exact
                component={PasswordMailSent}
              />
              <Route
                path="/password/changed"
                exact
                component={PasswordChangedSuccess}
              />
              <Route component={PageNotFound} />
            </Switch>
          </React.Fragment>
        </Router>
      </div>
    </MuiThemeProvider>
  );
}

export default withCookies(App);
