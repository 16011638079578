import React                                                              from 'react';
import Card                                                               from '@material-ui/core/Card';
import CardContent                                                        from '@material-ui/core/CardContent';
import Typography                                                         from '@material-ui/core/Typography';
import Grid                                                               from "@material-ui/core/Grid";
import AroaParentComponent                                                from "../../AroaParentComponent";
import {withStyles}                                                       from "@material-ui/core";
import {DesktopIcon, ExclamationIconOrange, MobileIcon, SegmentMoveIcon,} from "../../../atoms/AroaIcons";
import Fab                                                                from "@material-ui/core/Fab";
import styles                                                             from '../../../components/AroaPage/aroatheme';
import Paper                                                              from "@material-ui/core/Paper";
import {addSegmentToComposer, removeSegmentFromComposer}                  from "../../AroaSearchResults";

const getReadableNumberOfUniqueUsers = (number) => {
	let dec = number / 1000000;
	if(dec >= 1){
		return Math.round(number / 10000) / 100 + "M"
	}else
		if(dec >= 0.001 && dec < 1){
			return Math.round(number / 100) / 10 + "K"
		}else{
			if(dec === 0)
				return 0;
			else
				return "< 1000";
		}
};

class SegmentCard extends AroaParentComponent
{
	constructor(props)
	{
		super(props);
		this.state             = {expanded: false};
		this.handleExpandClick = this.handleExpandClick.bind(this);
	}
	
	handleExpandClick = () => {
		this.setState((state) => ({expanded: !state.expanded}));
	};
	
	render()
	{
		
		const {segment, hideActions, segmentIndex} = this.props;
		return (
			<Card className="Card-Base" elevation={0}>
				<CardContent>
					<Grid>
						<Grid className="flex">
							<Grid>
								<Paper className="flex">
									<SegmentMoveIcon/>
								</Paper>
							</Grid>
							<Grid className="flex margin-left-10">
								<Typography className="Manhattan-Mall-Times-Square">
									{segment.dataPartnerName}&nbsp;
								</Typography>
								<Typography className="Manhattan-Mall-Times-Square-gris">{segment.keyName}&#95;</Typography>
								<Typography className="Manhattan-Mall-Times-Square-gris" color={"inherit"}>
									{segment.segmentData.key}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid className="flex margin-left-30">
						<Grid className="flex flex--content-start ">
							<Grid className="flex flex--aligned">
								<Typography className={"segment-users-text-box greyText"}>
									Users : {getReadableNumberOfUniqueUsers(
									segment.segmentData.datmean_ids.value + segment.segmentData.mobile_ids.value)} &nbsp;
								</Typography>
							</Grid>
							<Grid className="flex flex--aligned ">
								<Typography className="greyText">
									Device
								</Typography>
								{segment.segmentData.datmean_ids.value > 0 ? <DesktopIcon height={25} width={25}/> : ''}
								{segment.segmentData.mobile_ids.value > 0 ? <MobileIcon height={25} width={25}/> : ''}
							</Grid>
							<Grid className="flex flex--aligned margin-left-10">
								<ExclamationIconOrange height={20} width={20} className="flex--align-self-baseline"/>
								<Typography className="greyText">
									Blacklist
								</Typography>
							</Grid>
						</Grid>
						<Grid className="flex--grow2">
							{hideActions !== true ?
								<Fab aria-label="ADD segment" className="base -Value"
									 onClick={() => addSegmentToComposer(segment, segmentIndex)}>
									ADD
								</Fab> : ""
							}
							{hideActions ?
								<Fab aria-label="Remoove segment" className="base--red -Value"
									 onClick={() => {removeSegmentFromComposer(segment, segmentIndex)
								}}>
									Remove
								</Fab> : ""
							}
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		)
	}
}

export function getReadableNumberOfUniqueUsersFn(number)
{
	return getReadableNumberOfUniqueUsers(number);
}

export default withStyles(styles)(SegmentCard);