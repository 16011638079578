import { Grid, Paper } from "@material-ui/core";
import React from "react";
import {
  __GET_DATAPARTNER_DATA_MONTH_GROUPED,
  __GET_DATAPARTNER_DATA_YEAR,
  __GET_DATAPARTNER_DATA_MONTH_RESUME,
} from "../../services/endpoint-manager";
import { showNotify } from "../../atoms/AroaNotifier";
import AroaParentComponent from "../../components/AroaParentComponent";
import DataPartnerViewGraph from "../../atoms/DataPartnerViewGraph";
import DataPartnerViewData from "../../atoms/DataPartnerViewData";
import AroaBar from "../../components/AroaBar";
import AroaPage from "../../components/AroaPage";
import { withStyles } from "@material-ui/styles";
import styles from "../../components/AroaPage/aroatheme";
import { __SELECTED_DATAPARTNER } from "../../services/storage-manager";

class DataPartnerBody extends AroaParentComponent {
  constructor() {
    super();

    this.state = {
      audienceData: [],
      monthData: [],
      resumeData: [],
    };

    this.dataPartner = sessionStorage.getItem(__SELECTED_DATAPARTNER);
    this.axiosHandler = this.getXHRHandler();
  }

  componentDidMount = () => {
    const month = new Date().getMonth();
    this.loadYearlyAudienceData();

    this.loadAudienceDataByMonth(month);
    this.loadResumeDataByMonth(month);
  };

  loadYearlyAudienceData = () => {
    this.axiosHandler.launchGet(
      __GET_DATAPARTNER_DATA_YEAR(this.dataPartner),
      (response) => {
        const audienceData = this.getXHRHandler().getSuccessData(response);
        this.setState({ audienceData });
      },
      (error) => {
        showNotify({
          message: this.getXHRHandler().getErrorDescription(error),
        });
      }
    );
  };

  loadAudienceDataByMonth = (month) => {
    this.axiosHandler.launchGet(
      __GET_DATAPARTNER_DATA_MONTH_GROUPED(month, this.dataPartner, "past"),
      (response) => {
        const monthData = this.getXHRHandler().getSuccessData(response);
        this.setState({ monthData });
      },
      (error) => {
        showNotify({
          message: this.getXHRHandler().getErrorDescription(error),
        });
      }
    );
  };

  loadResumeDataByMonth = (month) => {
    this.axiosHandler.launchGet(
      __GET_DATAPARTNER_DATA_MONTH_RESUME(month, this.dataPartner),
      (response) => {
        const resumeData = this.getXHRHandler().getSuccessData(response);
        this.setState({ resumeData });
      },
      (error) => {
        showNotify({
          message: this.getXHRHandler().getErrorDescription(error),
        });
      }
    );
  };

  onMonthChange = (month, monthIndex) => {
    console.log(month, monthIndex);
    this.setState({ selectedMonth: month });
    this.loadAudienceDataByMonth(monthIndex);
    this.loadResumeDataByMonth(monthIndex);
  };

  render() {
    const resume = this.state.resumeData.resume
      ? this.state.resumeData.resume[0]
      : {};

    const advertisers = this.state.resumeData.advertisers
      ? this.state.resumeData.advertisers
      : [];

    const databuyers = this.state.resumeData.databuyers
      ? this.state.resumeData.databuyers
      : [];

    return (
      <Grid>
        <Paper elevation={1} style={{ padding: 25 }}>
          <Grid className="flex flex__full flex--justify--spacebetween">
            <Grid className="flex flex--column">
              <span style={{ fontWeight: "bold" }}>Total</span>
              <span>{resume.all_time} </span>
            </Grid>
            <Grid className="flex flex--column">
              <span style={{ fontWeight: "bold" }}>YTD</span>
              <span>{resume.ytd} </span>
            </Grid>
            <Grid className="flex flex--column">
              <span style={{ fontWeight: "bold" }}>Selected month</span>
              <span>{resume.monthData} </span>
            </Grid>
          </Grid>
          {databuyers.length ? (
            <Grid
              className="flex flex__full flex--align-self-center"
              style={{ marginTop: 15 }}
            >
              <Grid
                className="text--align--left flex flex--column"
                style={{ width: "50%" }}
              >
                <span style={{ fontWeight: "bold" }}>Top Buyers</span>
                {databuyers.map((databuyer) => (
                  <span key={databuyer.dataBuyerName}>
                    {" "}
                    {databuyer.dataBuyerName}{" "}
                  </span>
                ))}
              </Grid>
              <Grid
                className="text--align--left flex flex--column"
                style={{ width: "50%" }}
              >
                <span style={{ fontWeight: "bold" }}>Earnings</span>
                {databuyers.map((databuyer) => (
                  <span key={databuyer.vendorRev}> {databuyer.vendorRev} </span>
                ))}
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {advertisers.length ? (
            <Grid
              className="flex flex__full flex--align-self-center"
              style={{ marginTop: 15 }}
            >
              <Grid
                className="text--align--left flex flex--column"
                style={{ width: "50%" }}
              >
                <span style={{ fontWeight: "bold" }}>Top Advertisers</span>
                {advertisers.map((advertiser) => (
                  <span key={advertiser.advertiserName}>
                    {" "}
                    {advertiser.advertiserName}{" "}
                  </span>
                ))}
              </Grid>
              <Grid
                className="text--align--left flex flex--column"
                style={{ width: "50%" }}
              >
                <span style={{ fontWeight: "bold" }}>Earnings</span>
                {advertisers.map((advertiser) => (
                  <span key={advertiser.vendorRev}>
                    {" "}
                    {advertiser.vendorRev}{" "}
                  </span>
                ))}
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Paper>

        <DataPartnerViewGraph
          style={{ marginTop: 10 }}
          data={this.state.audienceData}
          onMonthChange={this.onMonthChange}
        ></DataPartnerViewGraph>

        <DataPartnerViewData
          header={["Advertiser", "DataBuyer", "Month", "Revenue"]}
          columns={["advertiserName", "dataBuyerName", "month", "vendorRev"]}
          data={this.state.monthData}
        ></DataPartnerViewData>
      </Grid>
    );
  }
}

class DataPartnerPastView extends AroaParentComponent {
  render() {
    return (
      <AroaPage
        header={new AroaBar(this.props).render()}
        body={<DataPartnerBody></DataPartnerBody>}
      ></AroaPage>
    );
  }
}

export default withStyles(styles)(DataPartnerPastView);
