import { connect } from 'react-redux';
import {setAudiences, attributeChange} from '../../actions';
import AroaCartAudienceAttributeRow from '../../../components/AroaCartAudienceAttributeRow';

const getAudienceAttributes = (audienceAttributes) => {
    return audienceAttributes;
};

const mapStateToProps = (state) => {
    return {
        audienceAttributes: getAudienceAttributes(state.audienceAttributes)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAttributeChange: (id) => {
            dispatch(attributeChange(id))
        },
        setAudiences: (audiences) => {
            dispatch(setAudiences(audiences));
        }
    }
};

const CartAudiencesAttributes = connect(
    mapStateToProps,
    mapDispatchToProps
)(AroaCartAudienceAttributeRow);

export default CartAudiencesAttributes;
