import sessionManager, {
  __CURRENT_VIEW,
} from "./storage-manager";
import { tokenManager } from "./token-manager";

export const __ADMINISTRATOR_ROL_ = "1";
export const __APOLLO_ROL_ = "7";
export const __DATABUYER_ROL_ = "6";
export const __DATAPARTNER_ROL_ = "9";

const BILLING_USERS = [
  "salvatore.cospito@datmean.com",
  "ai-support@datmean.com",
];


export class userRoles {
  static loadView() {
    let view;
    if (userRoles.isOnlyDataPartner()) {
      view = "dataPartner";
    } else {
      const sessionView = sessionManager.getValue(__CURRENT_VIEW);
      view = sessionView !== "" ? sessionView : 'dataBuyer'
    }
    sessionManager.setValue(__CURRENT_VIEW, view);
  }

  static currentView() {
    return sessionManager.getValue(__CURRENT_VIEW);
  }
  
  static setCurrentView(view) {
    sessionManager.setValue(__CURRENT_VIEW, view);
    window.location.href = "/";
  }

  static hasMultipleRoles() {
    const roles = tokenManager.getUserRoles();
    return roles.length > 1;
  }

  static isBilling() {
    let tokenData = tokenManager.getTokenData();
    return BILLING_USERS.includes(tokenData["user_mail"]);
  }

  static isAdmin() {
    const roles = tokenManager.getUserRoles();
    let admin = roles.filter((rol) => rol.roleID === __ADMINISTRATOR_ROL_);

    return admin.length === 1;
  }

  static isDataPartner() {
    const roles = tokenManager.getUserRoles();
    let datapartner = roles.filter((rol) => rol.roleID === __DATAPARTNER_ROL_);

    return datapartner.length === 1;
  }

  static isOnlyDataPartner() {
    const roles = tokenManager.getUserRoles() || [];
    let datapartner = roles.filter((rol) => rol.roleID === __DATAPARTNER_ROL_);

    return datapartner.length === 1 && roles.length === 1;
  }

  static isDataBuyer() {
    const roles = tokenManager.getUserRoles();
    let admin = roles.filter((rol) => rol.roleID === __ADMINISTRATOR_ROL_);
    if (admin.length === 1) {
      return true;
    }
    let dataBuyer = roles.filter((rol) => rol.roleID === __DATABUYER_ROL_);
    if (dataBuyer.length === 1) {
      return true;
    } else {
      return false;
    }
  }

  static isNotDataPartnerOrAdmin() {
    const roles = tokenManager.getUserRoles();
    let normalRoles = roles.filter((rol) => [1, 9].includes(rol.roleID));
    return !(normalRoles.length > 0);
  }

  static isApollo() {
    return false;
  }
}
