export const __AROA_SEARCH_EVENT                  = 'AroaSearchResults';
export const _AROA_SEARCH_LOADING_EVENT           = 'AroaSearchLoading';
export const __AROA_SEARCH_SEGMENT_DESTRUCTURING  = 'AroaSearchSegmentDestructuring';
export const __AROA_SHOW_SEARCHCOMPO_ON_BAR       = 'ShowSearchCompoOnAroaBar';
export const __AROA_SELECT_BINARY_OPERATION       = 'AroaSelectBinaryOperation';
export const __AROA_SELECT_GROUP_BINARY_OPERATION = 'AroaSelectGroupBinaryOperation';
export const __AROA_AUDIENCE_NAME_INPUT           = 'AroaAudienceNameInput';
export const __AROA_DATABUYER_CHANGE_ON_PANEL     = 'AroaDatabuyerChangeOnPanel';
export const __AROA_DATABUYER_IS_APOLLO_CHANGE_ON_PANEL     = 'AroaDatabuyerIsApolloChangeOnPanel';

