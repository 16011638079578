import React, {Fragment} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";


class AroaCartAudienceResumeRow extends React.Component {
    
    constructor(props) {
        super(props);
        let audiencesAttributes = this.props.audienceAttributes;
        let audienceAttributes = audiencesAttributes.filter(audience => audience.audienceID === this.props.audience.audienceID);
        
        if (audienceAttributes.length > 0) {
            this.state = {
                platform: audienceAttributes[0].platform ? audienceAttributes[0].platform : false,
                device: audienceAttributes[0].device ? audienceAttributes[0].device : false,
                purchaseModel: 1,
                advertiser: audienceAttributes[0].advertiser ? audienceAttributes[0].advertiser : false,
                typeID: 2,
                expirationDate: audienceAttributes[0].expirationDate ? audienceAttributes[0].expirationDate : null,
                platformName: audienceAttributes[0].platformName ? audienceAttributes[0].platformName : "",
                deviceName: audienceAttributes[0].deviceName ? audienceAttributes[0].deviceName : "",
                purchaseModelName: "CPM",
                typeName: "LIVE",
                advertiserName: audienceAttributes[0].advertiserName ? audienceAttributes[0].advertiserName : "",
                audienceId: this.props.audience.audienceID
            };
            
        } else {
            this.state = {
                platform: false,
                device: false,
                purchaseModel: 1,
                advertiser: false,
                typeID: 2,
                expirationDate: null,
                audienceId: this.props.audience.audienceID
            };
        }
    }
    
    render() {
        const {audience} = this.props;
        
        return (
            <Fragment>
                <TableRow style={{height: 100}} className="aroaColor">
                    <TableCell rowSpan={2} style={{verticalAlign: 'top'}}>
                        <p>{audience.audienceName}</p>
                    </TableCell>
                    <TableCell style={{borderBottom: "none"}}>
                        <p className="MuiTableCell-head">PLATFORM</p>
                        <p>{this.state.platformName}</p>
                    </TableCell>
                    <TableCell style={{borderBottom: "none"}}>
                        <p className="MuiTableCell-head">DEVICE</p>
                        <p>{this.state.deviceName}</p>
                    </TableCell>
                    <TableCell style={{borderBottom: "none"}}>
                        <p className="MuiTableCell-head">CPM/CPU</p>
                        <p>{this.state.purchaseModelName}</p>
                    </TableCell>
                </TableRow>
                <TableRow style={{height: 100}} className="aroaColor">
                    <TableCell>
                        <p className="MuiTableCell-head">ADVERTISER</p>
                        <p>{this.state.advertiserName}</p>
                    </TableCell>
                    <TableCell>
                        <p className="MuiTableCell-head">TYPE</p>
                        <p>{this.state.typeName}</p>
                    </TableCell>
                    <TableCell>
                        <p className="MuiTableCell-head">END OF CAMPAIGN</p>
                        <p>{this.state.expirationDate}</p>
                    </TableCell>
                </TableRow>
            </Fragment>
        )
    }
}

export default AroaCartAudienceResumeRow;
