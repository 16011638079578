import React                                  from 'react';
import {withStyles}                           from '@material-ui/core/styles';
import Button                                 from '@material-ui/core/Button';
import Dialog                                 from '@material-ui/core/Dialog';
import MuiDialogContent                       from '@material-ui/core/DialogContent';
import List                                   from '@material-ui/core/List';
import ListItem                               from '@material-ui/core/ListItem';
import {booleanOperations}                    from '../../services/dnd-manager';
import PubSub                                 from "pubsub-js";
import {__AROA_SELECT_GROUP_BINARY_OPERATION} from "../../services/event-subscriber";

export const __AROA_GROUP_SEGMENT_COMPOSER = "segmentsG1G2Operation";

let openAroaMarketGroupOperationDialogFn;

const DialogContent = withStyles(theme => ({
	root: {
		width       : 240,
		borderRadius: 8
	},
}))(MuiDialogContent);

const OperationButton = withStyles(() => ({
	root: {
		color          : '#fff',
		backgroundColor: '#015783',
		borderRadius   : 20,
		height         : 27.2,
		width          : 98,
		fontWeight     : "bold",
		fontSize       : 10
	},
	
}))(Button);

class AroaMarketGroupOperationDialog extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			open: false,
		};
		
		this.data = {
			operation    : "",
			operationType: ""
		};
	}
	
	componentDidMount()
	{
		openAroaMarketGroupOperationDialogFn = this.handleClickOpen;
	}
	
	handleClickOpen = (props) => {
		const {segmentsG1G2Operation} = props;
		this.data.operationType       = segmentsG1G2Operation;
		this.setState({open: true});
	};
	
	handleClose = () => {
		this.setState({open: false});
		PubSub.publish(__AROA_SELECT_GROUP_BINARY_OPERATION, this.data);
	};
	
	handleOperation = (event) => {
		event.persist();
		this.data.operation = this.getOperationValue(event.target.innerText);
		this.handleClose();
	};
	
	getOperationValue = operation => {
		return booleanOperations[operation];
	};
	
	render()
	{
		const {open} = this.state;
		return (
			<div>
				<Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={open}
						disableEscapeKeyDown={true} disableBackdropClick={true}>
					<DialogContent>
						<span
							className={"text-prompt-operations"}>Which relations do this two groups of segments have?</span>
						<List>
							<ListItem key={0} className="container-buttons-segment-operation-dialog">
								<OperationButton
									color="primary"
									onClick={this.handleOperation}>{booleanOperations.AND}
								</OperationButton>
							</ListItem>
							<ListItem key={1} className="container-buttons-segment-operation-dialog">
								<OperationButton
									color="primary"
									onClick={this.handleOperation}>{booleanOperations.OR}
								</OperationButton>
							</ListItem>
							<ListItem key={2} className="container-buttons-segment-operation-dialog">
								<OperationButton
									color="primary"
									onClick={this.handleOperation}>{booleanOperations.NOT}
								</OperationButton>
							</ListItem>
						</List>
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}

export function openAroaMarketGroupOperationDialog({segmentsG1G2Operation})
{
	openAroaMarketGroupOperationDialogFn({segmentsG1G2Operation});
}

export default AroaMarketGroupOperationDialog;