import React from 'react';
import AroaParentComponent from "../AroaParentComponent";
import {__AROA_SEARCH_ENGINE_ADDR} from "../../services/endpoint-manager";
import Notifier, {showNotify} from "../../atoms/AroaNotifier";
import {IconButton, InputAdornment, TextField, withStyles} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import styles from "../AroaPage/aroatheme";
import AroaLogo from "../../atoms/AroaLogo";
import Grid from "@material-ui/core/Grid";
import {__AROA_SEARCH_EVENT} from "../../services/event-subscriber";
import PubSub from "pubsub-js";
import {HelpIcon} from "../../atoms/AroaIcons";
import AroaInfoDialog from "../../atoms/AroaInfoDialog";

class AroaSearchComponent extends AroaParentComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchTerms: "",
            openInfoDialog: false

        };

        this.fireSearch = this.fireSearch.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openInfoDialog = this.openInfoDialog.bind(this);
    }


    handleSubmit(event) {
        event.preventDefault();
        this.fireSearch();
    }

    handleChange(event) {
        this.setState({searchTerms: event.target.value});
    }

    fireSearch() {
        let self = this;
        if (this.state.searchTerms) {
            this.props.setSearchTerm(this.state.searchTerms);
            this.props.setSearchLoading(true);
            this.getXHRHandler().launchPost(__AROA_SEARCH_ENGINE_ADDR, this.state, (response) => {
                let results = self.getXHRHandler()
                    .getSuccessData(response).aggregations.dp.buckets;
                PubSub.publish(__AROA_SEARCH_EVENT, results);
                self.props.setSearchResults(results);
                self.props.setSearchLoading(false);

            }, (error) => {
                showNotify({message: this.getXHRHandler().getErrorDescription(error)});
            });
        } else {
            showNotify({message: "You must to provide search term"});
        }
    }

    openInfoDialog = () => {
        this.setState({openInfoDialog: true});
    }

    closeInfoDialog = () => {
        this.setState({openInfoDialog: false});
    }


    render() {
        const {classes} = this.props;
        let faqText = (
                <ul>
                    <span>You can use next operators to perform a more convenience search:</span>
                    <li className="margin-left-20">AND, OR, NOT.</li>
                    <li className="margin-left-20">You can also use parenthesis to perform your search:</li>
                    <span className="margin-left-20">(moto OR coche) AND seguro.</span>
                    <span> <br/><br/></span>
                    <span>*Be sure to use uppercase when typing AND,OR,NOT operators.</span>
                </ul>
            );

        if (this.props.searchResults.length === 0 && !this.props.searchTerms) {
            return (
                <React.Fragment>
                    <Grid className='margin-top-25vh'>
                        <AroaLogo/>
                    </Grid>
                    {/*<Grid className="flex flex--end">
						<IconButton  onClick={this.openInfoDialog}>
						    <Typography variant={"body2"}  className="title--faq">F.A.Q</Typography>
							<HelpIcon  active={true}/>
						</IconButton>

					</Grid>*/}
                    {this.state.openInfoDialog
                        ? <AroaInfoDialog title={"Frecuent Answers and Questions"}
                                          text={faqText}
                                          cancelHandler={() => this.closeInfoDialog()}
                                          open={this.state.openInfoDialog}
                        />
                        : null
                    }
                    <React.Fragment>
                        <Notifier/>
                        <form method={"post"} onSubmit={this.handleSubmit}>
                            <Grid className={classes.containerSearchMarketLanding}>
                                <TextField className={classes.aroaMarketLandingSearchAudience}
                                           id="searchTerms"
                                           type="text"
                                           name="searchTerms"
                                           placeholder={this.props.searchTerms}
                                           margin="normal"
                                           variant="outlined"
                                           autoComplete="off"
                                           fullWidth
                                           onChange={this.handleChange}
                                           value={this.state.searchTerms}
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment>
                                                       <IconButton type="submit" onSubmit={this.handleSubmit}>
                                                           <SearchIcon/>
                                                       </IconButton>
                                                       <IconButton onClick={this.openInfoDialog}>
                                                           <HelpIcon active={true}/>
                                                       </IconButton>
                                                   </InputAdornment>
                                               )
                                           }}
                                />
                            </Grid>
                        </form>
                    </React.Fragment>
                </React.Fragment>
            );
        } else {
            return "";
        }
    }
}

export default withStyles(styles)(AroaSearchComponent);
