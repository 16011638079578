export const __CREATE_USER_MAIL_FIELD = "create_user_step_1_mail_field";
export const __CREATE_USER_NAME_FIELD = "create_user_step_1_name_field";
export const __CREATE_USER_SURNAME_FIELD = "create_user_step_1_surname_field";
export const __CREATE_ADD_DSP_DSPID_FIELD = "create_dsp_step_1_dspID_field";
export const __API_TOKEN = "AroaMPApiToken";
export const __SELECTED_DATABUYER = "AroaSelectedDatabuyer";
export const __SELECTED_DATABUYER_IS_APOLLO = "AroaSelectedDatabuyerIsApollo";
export const __CURRENT_TRANSACTION = "AroaCurrentTransactionIdentifier";
export const __CURRENT_NUMBER_OF_AUDIENCES_IN_CART =
  "AroaCurrentNumberOfAudiencesInCart";
export const __COUNT_SUCCESS_BUY_AUDIENCE = "AroaSucessBuyNumberAudiences";
export const __AUDIENCENAME_ON_ACTIVITY_BUYAGAIN_ =
  "AroaAudienceNameBuyAgainONActivity";
export const __SELECTED_DATAPARTNER = "AroaSelectedDataparner";
export const __CURRENT_VIEW = "AroaCurrentView";

class sessionManager {
  static setValue(key, value) {
    sessionStorage.setItem(key, value);
  }

  static getValue(key) {
    if (!sessionManager.keyExists(key)) {
      return "";
    } else {
      return sessionStorage.getItem(key);
    }
  }

  static keyExists(key) {
    return (
      sessionStorage.getItem(key) !== null &&
      sessionStorage.getItem(key).length > 0
    );
  }

  static clearCartInfo() {
    sessionStorage.removeItem(__CURRENT_NUMBER_OF_AUDIENCES_IN_CART);
    sessionStorage.removeItem(__CURRENT_TRANSACTION);
  }
  static clearSuccessBuyInfo() {
    sessionStorage.removeItem(__COUNT_SUCCESS_BUY_AUDIENCE);
  }
  static clearSuccessActivityBuyAgainInfo() {
    sessionStorage.removeItem(__AUDIENCENAME_ON_ACTIVITY_BUYAGAIN_);
  }
}

export class localStorageManager {
  static setValue(key, value) {
    localStorage.setItem(key, value);
  }

  static getValue(key) {
    if (!localStorageManager.keyExists(key)) {
      return "";
    } else {
      return localStorage.getItem(key);
    }
  }

  static remove(key) {
    localStorage.removeItem(key);
  }

  static keyExists(key) {
    return (
      localStorage.getItem(key) !== null && localStorage.getItem(key).length > 0
    );
  }
}

export default sessionManager;
