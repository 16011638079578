import React from 'react';
import AroaParentComponent from "../../components/AroaParentComponent";
import Grid from "@material-ui/core/Grid";
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import {__CALENDAR} from "../../atoms/ButtonsStyles";

class AroaCalendar extends AroaParentComponent {
    constructor(props) {
        super(props);
        
        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");
        
        this.applyCallback = this.applyCallback.bind(this);
        this.state = {
            start: start,
            end: end
        };
    }
    
    applyCallback(startDate, endDate) {
        this.props.onApply(startDate, endDate);
        this.setState({
                start: startDate,
                end: endDate
            }
        )
    }
    
    render() {
        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        let end = moment(start);
        let ranges = {
            "Last 5 Days": [moment(start).subtract(5, "days"), moment(end)],
            "Last 30 Days": [moment(start).subtract(30, "days"), moment(end)],
            "Last 3 Months": [moment(start).subtract(3, "months"), moment(end)],
        };
        let local = {
            "format": "DD-MM-YYYY",
            "sundayFirst": false
        };
        let maxDate = moment(start);
        
        return (
            <Grid>
                <DateTimeRangeContainer
                    ranges={ranges}
                    start={this.state.start}
                    end={this.state.end}
                    style={__CALENDAR}
                    local={local}
                    maxDate={maxDate}
                    applyCallback={this.applyCallback}
                >
                    {this.props.children}
    
                    <FormControl
                        id="formControlsTextB"
                        type="text"
                        label="Text"
                        placeholder="Enter text"
                    />
                </DateTimeRangeContainer>
            </Grid>
        )
    }
}


export default AroaCalendar;
