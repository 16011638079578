import { Grid, Paper } from "@material-ui/core";
import React from "react";
import styles from "../../components/AroaPage/aroatheme";
import { withStyles } from "@material-ui/core/styles";
import { __GET_DATAPARTNER_AUDIENCES } from "../../services/endpoint-manager";
import AroaParentComponent from "../../components/AroaParentComponent";
import AroaPage from "../../components/AroaPage";
import AroaBar from "../../components/AroaBar";
import { __SELECTED_DATAPARTNER } from "../../services/storage-manager";

class DataPartnerLiveAudiences extends AroaParentComponent {
  constructor() {
    super();

    this.state = {
      audienceData: [],
    };

    this.axiosHandler = this.getXHRHandler();
    this.dataPartner = sessionStorage.getItem(__SELECTED_DATAPARTNER);
  }

  componentDidMount = () => {
    this.loadAudiences();
  };

  loadAudiences = () => {
    return new Promise((resolve, reject) => {
      this.axiosHandler.launchGet(
        __GET_DATAPARTNER_AUDIENCES(this.dataPartner),
        (response) => {
          this.setState(
            {
              audienceData: this.axiosHandler.getSuccessData(response),
            },
            () => resolve()
          );
        },
        (error) => reject(this.axiosHandler.getErrorDescription(error))
      );
    });
  };

  render() {
    return (
      <Grid className="flex flex--column">
        {this.state.audienceData.map((audience) => {
          const backgroundColor =
            audience.stateID === "4" ? "#5FDBA7" : "#ff4d4d";
          return (
            <Paper
              elevation={1}
              style={{
                padding: 10,
                marginBottom: 10,
                backgroundColor: backgroundColor,
              }}
              key={audience.segmentID}
              className="flex flex--justify--spacebetween"
            >
              <Grid className="flex flex--column text--align--left">
                <span>Advertiser: {audience.advertiserName}</span>
                <span>Databuyer: {audience.dataBuyerName}</span>
              </Grid>
              <Grid className="flex flex--column flex--justify--center">
                  <span>Audience: {audience.audienceName}</span>
              </Grid>
            </Paper>
          );
        })}
      </Grid>
    );
  }
}

class DataPartnerLiveAudiencesView extends AroaParentComponent {
  render() {
    return (
      <AroaPage
        header={new AroaBar(this.props).render()}
        body={<DataPartnerLiveAudiences></DataPartnerLiveAudiences>}
      ></AroaPage>
    );
  }
}

export default withStyles(styles)(DataPartnerLiveAudiencesView);
