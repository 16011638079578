import React, {Component} from "react";
import {ImageUnavailableIcon} from "../AroaIcons";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AroaDialog from "../AroaDialog";
import sessionManager, {__SELECTED_DATABUYER_IS_APOLLO} from "../../services/storage-manager";

class AroaDataBuyerListItem extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {openChangeDataBuyerDialog: false};
        this.isSelected = this.isSelected.bind(this);
        this.openChangeDataBuyer = this.openChangeDataBuyer.bind(this);
    }

    openChangeDataBuyer = () => {
        this.setState({openChangeDataBuyerDialog: true});
        this.isSelected();
    };
    closeChangeDataBuyer = () => {
        this.setState({openChangeDataBuyerDialog: false});
    };

    onClickDataBuyer = () => {
        this.props.onClickHandle(this.props.item);

        if (window.location.pathname === '/activity' )
        {window.location.pathname = '/activity';}
    };

    isSelected = () => {
        return this.props.item.databuyerid === this.props.selectedDataBuyer;
    };

    render()
    {
        const {item} = this.props;
        const {databuyername} = item;
        const selectedDataBuyerIsApollo = sessionManager.getValue(__SELECTED_DATABUYER_IS_APOLLO);

        return (
            <Paper className={this.isSelected() ? "databuyer--active" : "databuyer"} style={{width : 320}} onClick={ item.isapollo==="1" || selectedDataBuyerIsApollo==="1" ? this.openChangeDataBuyer : this.onClickDataBuyer}>
                <Grid container alignItems={"center"}>
                    <ImageUnavailableIcon/>
                    <span className="title">{databuyername}</span>
                </Grid>
                {this.state.openChangeDataBuyerDialog
                    ? <AroaDialog title="Do you want to change Databuyer?"
                                  text="if you continue, you could be lose information stored in your last databuyer Cart, are you sure?"
                                  cancel={true}
                                  accept={true}
                                  acceptText="Continue"
                                  acceptHandler={() => this.onClickDataBuyer()}
                                  cancelHandler={() => this.closeChangeDataBuyer}
                                  open={this.state.openChangeDataBuyerDialog}
                    />
                    : null
                }
            </Paper>
        );
    }
}

export default AroaDataBuyerListItem;