import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ImageUnavailableIcon, ProfileIcon } from "../AroaIcons";
import Divider from "@material-ui/core/Divider";
import { tokenManager } from "../../services/token-manager";
import xhrHandler from "../../services/xhr-handler";
import {
  __DATABUYER_LIST,
  __DATAPARTNER_LIST,
  __EMPTY_CART_APOLLO,
} from "../../services/endpoint-manager";
import showNotify from "../AroaNotifier";
import AroaDataBuyerListItem from "../AroaDataBuyerListItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import sessionManager, {
  __CURRENT_TRANSACTION,
  __SELECTED_DATABUYER,
  __SELECTED_DATABUYER_IS_APOLLO,
  __SELECTED_DATAPARTNER,
} from "../../services/storage-manager";
import UserPanel from "../../components/AroaSettingsPanel/UserPanel";
import Scrollbar from "react-scrollbars-custom";
import PubSub from "pubsub-js";
import {
  __AROA_DATABUYER_CHANGE_ON_PANEL,
  __AROA_DATABUYER_IS_APOLLO_CHANGE_ON_PANEL,
} from "../../services/event-subscriber";
import { userRoles } from "../../services/userRoles-manager";

const ProfilePanel = withStyles(() => ({
  root: {
    boxShadow: "0 15px 50px 0 rgba(0, 0, 0, 0.05)",
    objectFit: "contain",
    width: 400,
    position: "absolute",
    right: 40,
    top: 50,
    borderRadius: 5,
    maxHeight: 582,
    zIndex: 100,
  },
}))(Paper);

const BigDivider = withStyles(() => ({
  root: {
    marginTop: 10,
    marginBottom: 15,
    height: 2,
    border: "solid 1px #cad0d7",
  },
}))(Divider);

class AroaProfilePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      userEmail: "",
      userId: 0,
      dataBuyerList: [],
      dataPartnerList: [],
    };
  }

  componentDidMount() {
    let tokenData = tokenManager.getTokenData();
    this.setState({
      userId: tokenData["user_id"],
      userName: tokenData["user_nick"],
      userEmail: tokenData["user_mail"],
    }, () => {
      if (userRoles.currentView() === "dataBuyer") {
        this.getDatabuyerList();
      } else {
        this.getDataPartnerList();
      }
    });
    this.getSelectedDatabuyer();
    this.getSelectedDataPartner();
  }

  getSelectedDatabuyer() {
    const selectedDataBuyer = sessionManager.getValue(__SELECTED_DATABUYER);
    this.setState({ selectedDataBuyer: selectedDataBuyer });
  }

  getSelectedDataPartner() {
    const selectedDataPartner = sessionManager.getValue(__SELECTED_DATAPARTNER);
    this.setState({ selectedDataPartner: selectedDataPartner });
  }

  getDataPartnerList() {
    let _self = this;
    let axiosHandler = new xhrHandler();
    axiosHandler.launchGet(
      __DATAPARTNER_LIST + "?userID=" + this.state.userId,
      (response) => {
        _self.setState({
          dataPartnerList: axiosHandler.getSuccessData(response),
        });
      },
      (error) => {
        showNotify(axiosHandler.getErrorDescription(error));
      }
    );
  }

  getDatabuyerList() {
    let _self = this;
    let axiosHandler = new xhrHandler();
    axiosHandler.launchGet(
      __DATABUYER_LIST,
      (response) => {
        _self.setState({
          dataBuyerList: axiosHandler.getSuccessData(response),
        });
      },
      (error) => {
        showNotify(axiosHandler.getErrorDescription(error));
      }
    );
  }

  deleteTransactionForApolloDataBuyer(currentTransaction) {
    let axiosHandler = new xhrHandler();
    axiosHandler.launchDelete(
      __EMPTY_CART_APOLLO,
      { transactionID: currentTransaction },
      (response) => {},
      (error) => {
        console.info(error);
      }
    );
  }

  saveDataBuyer = (item) => {
    const selectedDataBuyerIsApollo = sessionManager.getValue(
      __SELECTED_DATABUYER_IS_APOLLO
    );
    const currentTransaction = sessionManager.getValue(__CURRENT_TRANSACTION);

    if (selectedDataBuyerIsApollo === "1" || item.isapollo === "1") {
      if (currentTransaction.length > 0) {
        this.deleteTransactionForApolloDataBuyer(currentTransaction);
      }
      sessionManager.clearCartInfo();
    }
    sessionManager.setValue(__SELECTED_DATABUYER, item.databuyerid);
    sessionManager.setValue(__SELECTED_DATABUYER_IS_APOLLO, item.isapollo);
    PubSub.publish(__AROA_DATABUYER_CHANGE_ON_PANEL, item.databuyerid);
    PubSub.publish(__AROA_DATABUYER_IS_APOLLO_CHANGE_ON_PANEL, item.isapollo);
    this.setState({
      selectedDataBuyer: item.databuyerid,
      selectedDataBuyerApollo: item.isapollo,
    });
  };

  onClickDataPartner = (id) => {
    sessionManager.setValue(__SELECTED_DATAPARTNER, id);
    this.setState({
      selectedDataPartner: id,
    }, () => window.location.reload());
  };

  isDataParnerSelected = (id) => {
    return id === this.state.selectedDataPartner;
  };

  render() {
    const loader = (
      <Grid container justify={"center"}>
        <CircularProgress />
      </Grid>
    );

    return (
      <ProfilePanel>
        <Grid>
          <Grid className="profile__card-base__header">
            <Grid container className="height--full">
              <Grid className="flex flex--aligned">
                <Grid className="icon-circle">
                  <ProfileIcon active={true} />
                </Grid>
              </Grid>
              <Grid className="flex flex--top flex--centered flex--column">
                <span className="title">{this.state.userName}</span>
                <span>{this.state.userEmail}</span>
              </Grid>
            </Grid>
          </Grid>
          {userRoles.currentView() === "dataBuyer" ? (
            <Grid className="profile__card-base__body">
              <span className="section-title">DATA BUYERS</span>
              <BigDivider />
              <Scrollbar className="margin-left-10" style={{ height: 110 }}>
                {this.state.dataBuyerList.length > 0
                  ? this.state.dataBuyerList.map((item) => (
                      <AroaDataBuyerListItem
                        className="margin-right-10"
                        item={item}
                        key={item.databuyerid}
                        selectedDataBuyer={this.state.selectedDataBuyer}
                        selectedDataBuyerApollo={
                          this.state.selectedDataBuyerApollo
                        }
                        onClickHandle={this.saveDataBuyer}
                      />
                    ))
                  : loader}
              </Scrollbar>
            </Grid>
          ) : (
            <Grid className="profile__card-base__body">
              <span className="section-title">DATA PARTNERS</span>
              <BigDivider />
              <Scrollbar className="margin-left-10" style={{ height: 110 }}>
                {this.state.dataPartnerList.length > 0
                  ? this.state.dataPartnerList.map((item) => (
                      <Paper
                        className={
                          this.isDataParnerSelected(item.id)
                            ? "databuyer--active margin-right-10"
                            : "databuyer margin-right-10"
                        }
                        key={item.id}
                        style={{ width: 320 }}
                        onClick={() =>
                          this.onClickDataPartner(item.id)
                        }
                      >
                        <Grid container alignItems={"center"}>
                          <ImageUnavailableIcon/>
                          <span className="title">{item.name}</span>
                        </Grid>
                      </Paper>
                    ))
                  : loader}
              </Scrollbar>
            </Grid>
          )}
          <Grid className="margin-top-10">
            <UserPanel />
          </Grid>
        </Grid>
      </ProfilePanel>
    );
  }
}

export default AroaProfilePanel;
