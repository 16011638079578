import React from "react";
import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";

class DataPartnerViewData extends React.Component {
  constructor() {
    super();

    this.months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  }

  render() {
    const { data = [], header = [], columns = [] } = this.props;

    return (
      <Grid>
        <Grid className="flex flex__full flex--justify--center">
          <Table>
            <TableHead>
              <TableRow>
                {header.length > 0 ? (
                  header.map((row) => (
                    <TableCell key={row} style={{ fontWeight: "bold" }}>
                      {row}
                    </TableCell>
                  ))
                ) : (
                  <TableRow></TableRow>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((row, index) => (
                  <TableRow key={index}>
                    {columns.length > 0
                      ? columns.map((column, columnIndex) => (
                          <TableCell key={index + "" + columnIndex}>
                            {column === "month"
                              ? this.months[row[column] - 1]
                              : row[column]}
                          </TableCell>
                        ))
                      : ""}
                  </TableRow>
                ))
              ) : (
                <TableRow></TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }
}

export default DataPartnerViewData;
