import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import AroaParentComponent from "../../components/AroaParentComponent";
import AroaDialog from "../AroaDialog";

class AroaAdvertiserListItem extends AroaParentComponent {
    
    constructor(props) {
        super(props);
        this.state = {openDeleteDialog: false};
        this.openDelete = this.openDelete.bind(this);
    }
    
    deleteAdvertiser = () => {
        this.props.onDelete(this.props.advertiser.advertiserid);
    };
    
    editAdvertiser = () => {
        this.props.onEdit(this.props.advertiser);
    };
    
    openDelete = () => {
        this.setState({openDeleteDialog: true});
    };
    
    closeDelete = () => {
        this.setState({openDeleteDialog: false});
    };
    
    render() {
        const {advertiser} = this.props;
        return (
            <Card className="modal-advertiser-card">
                <CardContent style={{display: "flex", justifyContent: "start",}}>
                    <Grid className="margin-left-10">
                        <Typography className="advertiser-card-title">
                            {advertiser.advertisername}
                        </Typography>
                    </Grid>
                </CardContent>
                <CardActions className="flex--justify--spacebetween">
                    <Grid className="margin-left-20">
                        <Typography className="School-of">
                            {advertiser.remoteadvertiserid}
                        </Typography>
                    </Grid>
                    <Grid className="margin-right-20">
                        <span className="title" onClick={() => this.editAdvertiser()}>Edit</span>
                        <span className="title--separator margin-right-10 margin-left-10">|</span>
                        <span className="title" onClick={() => this.openDelete()}>Delete</span>
                    </Grid>
                </CardActions>
                {this.state.openDeleteDialog
                    ? <AroaDialog title="Do you want to delete this advertiser?"
                                  text=""
                                  cancel={true}
                                  accept={true}
                                  acceptText="Delete"
                                  acceptHandler={() => this.deleteAdvertiser()}
                                  cancelHandler={() => this.closeDelete()}
                                  open={this.state.openDeleteDialog}
                    />
                    : null
                }
            </Card>
        );
    }
}

export default AroaAdvertiserListItem;
