import React                                   from 'react';
import styles                                  from '../../../components/AroaPage/aroatheme';
import {withStyles}                            from '@material-ui/core/styles';
import AroaParentComponent                     from "../../../components/AroaParentComponent";
import AroaStepper                             from "../../../atoms/AroaStepper";
import AroaBar                                 from "../../../components/AroaBar";
import AroaPage                                from "../../../components/AroaPage";
import Grid                                    from "@material-ui/core/Grid";
import Paper                                   from "@material-ui/core/Paper";
import AroaCartFooter                          from "../../../atoms/AroaCartFooter";
import AroaCartHeader                          from "../../../atoms/AroaCartHeader";
import sessionStorage, {
	__COUNT_SUCCESS_BUY_AUDIENCE,
	__CURRENT_NUMBER_OF_AUDIENCES_IN_CART,
	__CURRENT_TRANSACTION
} from "../../../services/storage-manager";
import sessionManager                          from "../../../services/storage-manager";
import xhrHandler                              from "../../../services/xhr-handler";
import {__CART_PREVIEW, __CART_TRANSACTION} from "../../../services/endpoint-manager";
import Notifier, {showNotify}                  from "../../../atoms/AroaNotifier";
import CircularProgress                        from "@material-ui/core/CircularProgress";
import AroaCartTable                           from "../../../atoms/AroaCartTable";
import CartAudiencesResume                     from "../../../redux/containers/CartAudiencesResume";

class CartStep4Body extends AroaParentComponent
{
	constructor(props)
	{
		super(props);
		this.state = {
			audienceList: [],
			loadingLoader : true
		};
		this.saveTransaction = this.saveTransaction.bind(this);
		this.getLoader= this.getLoader.bind(this);
	}
	
	componentDidMount()
	{
		this.getAudienceList();
	}
	
	getTransactionId = () => {
		return sessionStorage.getValue(__CURRENT_TRANSACTION);
	};
	
	getAudienceList()
	{
		let _self        = this;
		let axiosHandler = new xhrHandler();
		axiosHandler.launchPost(__CART_PREVIEW, {"transactionID": this.getTransactionId()}, (response) => {
			if (axiosHandler.getSuccessData(response).audiences.length === 0) {
				sessionManager.clearCartInfo();
				_self.setState({loadingLoader : false});
				showNotify({ message : 'Your Cart is empty, please lets us redirect you to Aroa Marketplace.'});
				setTimeout( this.goToMarketPlace,2000);
			}else {
				_self.setState({audienceList: axiosHandler.getSuccessData(response).audiences, loadingLoader : false}, function () {
					this.props.setAudiences(this.state.audienceList)
				});
			}
		}, (error) => {
			showNotify({message: axiosHandler.getErrorDescription(error)});
			_self.setState({loadingLoader : false});
			const regularExpresion = new RegExp('Transaction with ID\\s(.*)\\salready exists!');
			if (regularExpresion.exec(this.getXHRHandler().getErrorDescription(error)) !== null){
				return setTimeout(this.goToMarketPlace, 2000)
			}
		});
	}
	
	saveTransaction = () => {
		const axiosHandler = new xhrHandler();
		const buildDataTransaction = () => {
			
			let data = {
				transactionID: this.getTransactionId(),
				audiences    : []
			};
			
			if(this.props.audiences.length > 0){
				this.props.audiences.map(audience => {
					const currentDataRow = {
						audienceID     : audience.audienceID,
						advertiserID   : parseInt(audience.advertiser),
						platformID     : audience.platform,
						purchaseModelID: audience.purchaseModel,
						billingMapping : parseInt(audience.billingMapping),
						deviceID       : parseInt(audience.device),
						typeID         : audience.typeID,
						expirationDate : audience.expirationDate
					};
					data.audiences.push(currentDataRow);
					return true;
				});
			}
			
			return data;
		};
		
		return axiosHandler.launchPost(__CART_TRANSACTION, buildDataTransaction(), (response) => {
			let audiencesCount = sessionStorage.getValue(__CURRENT_NUMBER_OF_AUDIENCES_IN_CART);
			sessionStorage.setValue(__COUNT_SUCCESS_BUY_AUDIENCE, audiencesCount);
			sessionManager.clearCartInfo();
			this.props.history.push('/cart/success');
			return true;
		}, (error) => {
			showNotify({message: axiosHandler.getErrorDescription(error)});
			this.props.history.push('/cart/error');
			return false;
		});
	};

	getLoader(){
		const loader= (
			<Grid container justify={"center"}>
				<CircularProgress/>
			</Grid>
		);
		if (this.state.loadingLoader === true){
			return loader;
		} else {}
	}

	goToMarketPlace=()=>{
		let _self = this;
		_self.gotoPath('/marketplace');
	} ;

	renderAudienceTable()
	{
		const {audienceList} = this.state;

		return (
			audienceList.length > 0
				?
				<AroaCartTable
					headers={['AUDIENCE ID', ' ', '', '  ']}
					emptyLine={true}
					tableClass="cart-table">
						{
							audienceList.map(audience => (
								<CartAudiencesResume
									key={audience.audienceID}
									audience={audience}/>
							))}
				</AroaCartTable>
				: this.getLoader()
		);
	} ;
	
	render()
	{
		return (
			<Grid>
				<AroaStepper
					steps={['Check audiences', 'Audience attributes', 'Audience update', 'Confirm your audiences']}
					activeState={3}
					optionals={[]}
					completed={[0, 1, 2]}
				/>
				<Grid style={{marginTop: 10}}>
					<AroaCartHeader title="Data Cart" subtitle="Check your audiences"/>
					<Notifier/>
					<Grid container direction={'row'}>
						<Paper className="flex__full" elevation={0}>
							{this.renderAudienceTable()}
						</Paper>
						<AroaCartFooter
							nextLink="/cart/success"
							nextText="COMPLETE ORDER"
							prevLink="/cart/step3"
							prevText="BACK TO AUDIENCE UPDATE"
							onNextClick={() => this.saveTransaction}
						/>
					</Grid>
				</Grid>
			</Grid>
		)
	}
}

class CartStep4 extends AroaParentComponent
{
	constructor(props) {
		super(props);
		this.props.setCartStep(4);
	}
	
	render()
	{
		let props = {
			about   : false,
			platform: false,
			login   : false
		};
		return (
			
			<AroaPage header={(new AroaBar({...this.props, ...props}).render())}
					  body={<CartStep4Body
						  props={this.props}
						  setAudiences={this.props.setAudiences}
						  audiences={this.props.audienceAttributes}
						  history={this.props.history}
					  />}/>
		)
	}
}

export default withStyles(styles)(CartStep4);
