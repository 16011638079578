import React                                          from 'react';
import styles                                         from '../../../components/AroaPage/aroatheme';
import {withStyles}                                   from '@material-ui/core/styles';
import AroaParentComponent                            from "../../../components/AroaParentComponent";
import AroaStepper                          from "../../../atoms/AroaStepper";
import AroaPage                                       from "../../../components/AroaPage";
import Grid                                           from "@material-ui/core/Grid";
import {FontAwesomeIcon}                              from "@fortawesome/react-fontawesome";
import {faCheckCircle}                                from "@fortawesome/free-solid-svg-icons/";
import {Button, Typography} from "@material-ui/core";
import {Link}                                         from "react-router-dom";
import Fab                                            from "@material-ui/core/Fab";
import SimpleAroaBar                                  from "../../../atoms/AroaSimpleBar";
import sessionStorage, {__CREATE_ADD_DSP_DSPID_FIELD} from "../../../services/storage-manager";
import {SuccessIcon} from "../../../atoms/AroaIcons";
import {__ACTION_BUTTON, __SECONDARY_BUTTON} from "../../../atoms/ButtonsStyles";

const ColorButton = withStyles(() => (__ACTION_BUTTON))(Button);
const SecondaryButton = withStyles(() => (__SECONDARY_BUTTON))(Button);

class CreateDspStepFinishBody extends React.Component
{
	
	render()
	{
		const {classes} = this.props;
		const dspID     = sessionStorage.getValue(__CREATE_ADD_DSP_DSPID_FIELD);
		if(dspID.length === 0){
			return (
				<Grid>
					<AroaStepper
						steps={['Create an account', 'Add users', 'Add DSP', 'Add advertiser']}
						activeState={2}
						completed={[0, 1, 2]}
					/>
					<Grid className={classes.gridCreateUserStep2} style={{marginTop: 50}}>
						<FontAwesomeIcon icon={faCheckCircle} size='4x'/>
						<Typography style={{margin: 25}} variant={'h6'} color={"inherit"}>
							Done!! Enjoy you are ready to start using Aroa!
						</Typography>
						<Grid container justify={'center'} direction={'row'}>
							<Grid style={{margin: 15}}>
								<Link to={"/marketplace"}>
									<Fab variant="extended" aria-label="LOG IN" className={classes.aroaLongButtonWhite}>
										FINISH
									</Fab>
								</Link>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)
		}else{
			return (
				<Grid>
					<AroaStepper
						steps={['Create an account', 'Add users', 'Add DSP', 'Add advertiser']}
						activeState={2}
						completed={[0, 1, 2]}
					/>
					<Grid className="margin-top-50" >
						<SuccessIcon classes="message-screen__icon" active={true}/>
						<Typography  className="margin-top-30 welcome-title" >
							You are almost done!
						</Typography>
						<Typography className="margin-top-10 welcome-text" >
							Make an advertisers list so you can start using Aroa
						</Typography>
						<Grid container justify={'center'} direction={'row'}>
							<Grid className="margin-5">
								<Link to={"/marketplace"}>
									<SecondaryButton className="margin-top-20 button--big">
										SKIP THIS STEP
									</SecondaryButton>
								</Link>
							</Grid>
							<Grid className="margin-5" >
								<Link to={"/createadvertiser/step1"}>
									<ColorButton className="margin-top-20 button--big">
										GO TO ADVERTISERS LIST
									</ColorButton>
								</Link>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)
		}
	}
}

class CreateDspStepFinish extends AroaParentComponent
{
	
	render()
	{
		if(this.isUserLogged()){
			return <AroaPage header={(new SimpleAroaBar(this.props).render())}
							 body={(new CreateDspStepFinishBody(this.props).render())}/>;
		}else{
			this.gotoPath('/');
			return '';
		}
	}
}

export default withStyles(styles)(CreateDspStepFinish);
