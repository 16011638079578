import { connect } from 'react-redux';
import CartStep3 from "../../../screens/Cart/CartStep3";
import {setAudiences, changeCartStep} from "../../actions";

const mapDispatchToProps = (dispatch) => {
    return {
        setCartStep: (cartStep) => {
            dispatch(changeCartStep(cartStep))
        },
        setAudiences: (audiences) => {
            dispatch(setAudiences(audiences));
        }
    }
};

const CartStepContainer3 = connect(
    null,
    mapDispatchToProps
)(CartStep3);

export default CartStepContainer3;
