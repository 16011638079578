import environmentData from "../environment.json";

class environmentManager {
  constructor() {
    try {
      let environmentInfo = JSON.parse(JSON.stringify(environmentData));
      this.setEnvironment(
        environmentInfo.environments[environmentInfo.currentEnvironment]
      );
    } catch (e) {
      /** Si hubiere algun error cargando los entornos se carga por defecto la ruta local */
      this.setEnvironment({ host: "/" });
    }
  }

  getEnvironment() {
    return this._environment;
  }

  setEnvironment(enviroment) {
    this._environment = enviroment;
  }

  getBackendURL() {
    return this.getEnvironment().host;
  }
}

export default environmentManager;
