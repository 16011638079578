import React from "react";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {Button, withStyles} from "@material-ui/core";
import {__ACTION_BUTTON, __DISABLED_BUTTON} from "../ButtonsStyles";

const ColorButton = withStyles(() => (__ACTION_BUTTON))(Button);
const DisabledButton = withStyles(() => (__DISABLED_BUTTON))(Button);

const AroaCartFooter = ({nextLink, prevLink, nextText, prevText, onNextClick, isFormCompleted}) => {
    let prevLinkJSX;
    if (prevLink) {
        prevLinkJSX = (
            <Link to={prevLink}>
                {'<'} {prevText}
            </Link>
        );
    } else {
        prevLinkJSX = "";
    }
    
    let formComplete = true;
    
    if (typeof isFormCompleted !== 'undefined') {
        formComplete = isFormCompleted;
    }
    
    let disabledButton = (
        <DisabledButton aria-label="Next step" disabled>
            {nextText}
        </DisabledButton>
    );
    
    let nextButton;
    if (onNextClick) {
        nextButton = (
            <ColorButton aria-label="Next step" onClick={onNextClick()}>
                {nextText}
            </ColorButton>
        );
    } else {
        nextButton = (
            <Link to={nextLink}>
                <ColorButton aria-label="Next step">
                    {nextText}
                </ColorButton>
            </Link>
        );
    }

    return (
        <Grid style={{margin: 25}} container justify={prevLink ? 'space-between' : 'flex-end'} alignItems={'center'}>
            {prevLinkJSX}
            {
                formComplete
                    ? nextButton
                    : disabledButton
            }
            
        </Grid>
    )
};

export default AroaCartFooter;
