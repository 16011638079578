import React                                      from 'react';
import {Grid, withStyles}                         from "@material-ui/core";
import Paper                                      from "@material-ui/core/es/Paper/Paper";
import FormControl                                from "@material-ui/core/es/FormControl/FormControl";
import Typography                                 from "@material-ui/core/es/Typography/Typography";
import Fab                                        from "@material-ui/core/Fab";
import TextField                                  from '@material-ui/core/TextField';
import Notifier, {showNotify}                     from "../../../../atoms/AroaNotifier";
import AroaParentComponent                        from "../../../../components/AroaParentComponent";
import {__USER_ADD_PARTNERS}                      from '../../../../services/endpoint-manager';
import sessionStorage, {__CREATE_USER_MAIL_FIELD} from '../../../../services/storage-manager';
import {Link} from "react-router-dom";

const styles = theme => ({
	paper : {
		marginTop    : theme.spacing(1) * 8,
		flexDirection: 'column',
		maxWidth     : 900,
		alignItems   : 'center',
		padding      : `${theme.spacing(1) * 2}px ${theme.spacing(1) * 3}px ${theme.spacing(1) * 3}px`,
	},
	form  : {
		width    : 250, // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		marginTop: theme.spacing(1) * 3,
	},
	
});

class AddUserForm extends AroaParentComponent
{
	constructor(props)
	{
		super(props);
		this.state        = {
			userMail              : "",
			commaSeparatedMailList: "",
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	
	handleChange(event)
	{
		this.state[event.target.id] = event.target.value;
	}
	
	handleSubmit(event)
	{
		event.preventDefault();
		const userMail      = sessionStorage.getValue(__CREATE_USER_MAIL_FIELD);
		let self            = this;
		this.state.userMail = userMail;
		this.state.isLogged = false;
		if(this.state.commaSeparatedMailList){
			this.getXHRHandler().launchPost(__USER_ADD_PARTNERS, this.state, (response) => {
				self.gotoPath('/createuser/step4');
			}, (error) => {
				showNotify({message: this.getXHRHandler().getErrorDescription(error)});
			});
		}else{
			showNotify({message: "Please make sure you have fill all required fields"});
		}
	};
	
	render()
	{
		const {classes} = this.props;
		return (
			<React.Fragment>
				<Grid container justify={"center"}>
					<Notifier/>
					<form onSubmit={this.handleSubmit}>
						<Grid item>
							<div className={"margin-top-30"} style={{textAlign: "center"}}>
								<Paper className={classes.paper} elevation={0}>
									<Typography className="welcome-title  text--align--left">
										Add partners to your team
									</Typography>
									<FormControl margin="normal" required fullWidth>
										<TextField
											className="margin-top-30 margin-bottom-30"
											id="commaSeparatedMailList"
											name="commaSeparatedMailList"
											type={"text"}
											onChange={this.handleChange}
											label="EMAIL"
											autoComplete="email"
											variant="outlined"
											placeholder={"Eg.john@email.com, mary@email.com, scott@email.com"}
											multiline={true}
											rows={5}
											rowsMax={25}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</FormControl>
									<Link to={"/createuser/step4"}>
										<Fab aria-label="Step 3"
											 className="margin-top-20 button--blue--border"
											 style={{margin: 10}}
										>
											SKIP THIS STEP
										</Fab>
									</Link>
									<Fab type="submit" aria-label="Add User"
										 onClick={this.handleSubmit}
										 className="margin-top-20 button-signup-login"
										 style={{margin: 10}}>
										SEND INVITATION
									</Fab>
								</Paper>
							</div>
						</Grid>
					</form>
				</Grid>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(AddUserForm);