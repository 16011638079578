import { connect } from 'react-redux';
import AroaCartAudienceResumeRow from '../../../components/AroaCartAudienceResumeRow';

const getAudienceAttributes = (audienceAttributes) => {
    return audienceAttributes;
};

const mapStateToProps = (state) => {
    return {
        audienceAttributes: getAudienceAttributes(state.audienceAttributes)
    }
};

const CartAudiencesResume = connect(
    mapStateToProps
)(AroaCartAudienceResumeRow);

export default CartAudiencesResume;
