import React from 'react';
import styles from '../../components/AroaPage/aroatheme';
import {withStyles} from '@material-ui/core/styles';
import AroaParentComponent from "../../components/AroaParentComponent";
import AroaBar from "../../components/AroaBar";
import AroaPage from "../../components/AroaPage";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import xhrHandler from "../../services/xhr-handler";
import {__ACTIVITY_OVERVIEW, __ACTVITY_STATES} from "../../services/endpoint-manager";
import CircularProgress from "@material-ui/core/CircularProgress";
import sessionManager, {__SELECTED_DATABUYER} from "../../services/storage-manager";
import {showNotify} from "../../atoms/AroaNotifier";
import AroaActivityAudienceRow from "../../atoms/AroaActivityAudienceRow";
import AroaCalendar from "../../components/AroaCalendar";
import Button from "@material-ui/core/Button";
import {__ICON_BUTTON} from "../../atoms/ButtonsStyles";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import AroaMessageScreen from "../../atoms/AroaMessageScreen";
import Scrollbar from "react-scrollbars-custom";
import {NotFileIcon} from "../../atoms/AroaIcons";
import SearchIcon from '@material-ui/icons/Search';
import {IconButton, InputAdornment, TextField,} from "@material-ui/core";

const IconButtonFilter = withStyles(() => (__ICON_BUTTON))(Button);
const SearchAudienceInput = withStyles(() => ({
    root: {
        height: 32,
        width: 270,
        '& fieldset': {
            height: 45,
            borderColor: '#23AABF',
        },
        '& div':
            {
                height: 40,
            }
    },

}))(TextField);


class ActivityBody extends AroaParentComponent {
    constructor(props) {
        super(props);
        this.state = {
            audienceList: [],
            stateList: [],
            selectedState: false,
            selectedStartDate: false,
            selectedEndDate: false,
            loadingLoader: true,
            refreshAll: true,
            filterAudience: "",
            currentRawAudienceList: [],
        };
        this.getLoader = this.getLoader.bind(this);
        this.onDateSelect = this.onDateSelect.bind(this);
        this.onStateSelect = this.onStateSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitSearchAudience = this.handleSubmitSearchAudience.bind(this);
    }

    componentDidMount() {
        this.getAudienceList();
        this.getStateList();
    }

    getDatabuyerId = () => sessionManager.getValue(__SELECTED_DATABUYER);

    hasSomeFilter() {
        return this.hasDateFilter() || this.state.selectedState;
    }

    hasDateFilter() {
        return this.state.selectedStartDate || this.state.selectedEndDate;
    }

    getAudienceList() {
        if (this.state.refreshAll) this.setState({loadingLoader: true});

        let axiosHandler = new xhrHandler();
        let filter = {};
        let includeFilter = this.hasSomeFilter();
        if (this.state.selectedStartDate) filter = {...filter, "startDate": this.state.selectedStartDate};
        if (this.state.selectedEndDate) filter = {...filter, "endDate": this.state.selectedEndDate};
        if (this.state.selectedState) filter = {...filter, "stateID": parseInt(this.state.selectedState)};

        let data = includeFilter ? {
            "dataBuyerID": this.getDatabuyerId(),
            "filter": filter
        } : {"dataBuyerID": this.getDatabuyerId()};

        axiosHandler.launchPost(__ACTIVITY_OVERVIEW, data,
            (response) => {
                let audiences = axiosHandler.getSuccessData(response);
                this.setState({loadingLoader: false, audienceList: audiences, currentRawAudienceList : audiences});
            }, (error) => {
                showNotify({message: this.getXHRHandler().getErrorDescription(error)});
                this.setState({loadingLoader: false});
            });
    }

    getStateList() {
        let _self = this;
        let axiosHandler = new xhrHandler();
        axiosHandler.launchGet(__ACTVITY_STATES,
            (response) => {
                _self.setState({stateList: axiosHandler.getSuccessData(response)});
            }, (error) => {
                showNotify({message: this.getXHRHandler().getErrorDescription(error)});
            });
    }

    onDateSelect = (startDate, endDate) => {
        let _self = this;
        this.setState({
                selectedStartDate: startDate.format("YYYY-MM-DD"),
                selectedEndDate: endDate.format("YYYY-MM-DD"),
                refreshAll: true
            },
            function () {
                _self.getAudienceList();
            });
    };

    onStateSelect = (event) => {
        let _self = this;
        this.setState({selectedState: event.target.value, refreshAll: true},
            function () {
                _self.getAudienceList();
            }
        );
    };

    onAudienceStateChange() {
        let _self = this;
        this.setState({refreshAll: false},
            function () {
                _self.getAudienceList();
            }
        );
    };

    getLoader() {
        const loader = (
            <Grid container justify={"center"}>
                <CircularProgress/>
            </Grid>
        );

        if (this.state.loadingLoader === true) {
            return loader;
        }
    }

    getAudiences() {
        if (this.state.audienceList.length > 0) {
            return this.state.audienceList.map((audience) =>
                <AroaActivityAudienceRow
                    key={audience.audienceid}
                    audience={audience}
                    onStateChange={() => this.onAudienceStateChange()}
                />)
        } else {
            return (
                <AroaMessageScreen
                    icon={<NotFileIcon classes="message-screen__icon" active={true}/>}
                    buttonText="Marketplace"
                    linkTo="/"
                    title="No audiences found for this DataBuyer with current filters..."
                    message={"You can change DataBuyer in your user panel or buy a audience using marketplace"}
                />
            );
        }
    }

    getCurrentRawAudidienceList(){ this.setState({audienceList : this.state.currentRawAudienceList})}

    handleChange(event) {
        this.setState({filterAudience: event.target.value});
        if (event.target.value === '') {
            this.setState({filterAudience: ''}, this.getCurrentRawAudidienceList())
        }
    }

    handleSubmitSearchAudience(event) {
        event.preventDefault();

        let currentList = [];
        let newList = [];

        if (event.target.filterAudience.value !== "") {
            currentList = this.state.currentRawAudienceList;
            newList = currentList.filter(item => {
                const lowerCase = item.audiencenaming.toLowerCase();
                const filter = event.target.filterAudience.value.toLowerCase();
                return lowerCase.includes(filter);
            });
        } else {
            this.getCurrentRawAudidienceList();
            newList = this.state.currentRawAudienceList;
        }
        this.setState({
            audienceList: newList
        });
    }

    render() {
        return (
            <Grid>
                <Grid className="margin-top-10">
                    <Paper className="flex flex__full flex--justify--spacebetween" elevation={0}>
                        <Grid className="flex">
                            <Grid>
                                <AroaCalendar onApply={this.onDateSelect}>
                                    <IconButtonFilter variant="contained" color="secondary"
                                                      className={this.hasDateFilter() ? "activity__calendar--active" : ""}>
                                        <span className="icon icon-calendar white"/>
                                        Pick Date
                                    </IconButtonFilter>
                                </AroaCalendar>
                            </Grid>
                            <Grid className="margin-left-20 ">
                                <FormControl variant="outlined">
                                    <Select value={this.state.selectedState}
                                            input={<OutlinedInput labelWidth={0}/>}
                                            onChange={this.onStateSelect}
                                            name="state"
                                            className="activity__state-filter"
                                    >
                                        <MenuItem value={false}>All States</MenuItem>
                                        {
                                            this.state.stateList.map((state) =>
                                                <MenuItem value={state.stateid}
                                                          key={state.stateid}>{state.statename}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid className="margin-left-20 flex--end">
                            <form onSubmit={this.handleSubmitSearchAudience}>
                                <SearchAudienceInput
                                    id="filtererAudience"
                                    type="text"
                                    name="filterAudience"
                                    variant={"outlined"}
                                    placeholder={'Search an Audience'}
                                    autoComplete="off"
                                    onChange={this.handleChange}

                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton type="submit" onSubmit={this.handleSubmitSearchAudience}
                                                            className="icon-button">
                                                    <SearchIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}/>
                            </form>
                        </Grid>
                    </Paper>
                    <Grid container direction={'row'}>
                        <Paper className="flex__full margin-top-30 margin-bottom-30" elevation={0}>
                            <Scrollbar
                                style={{height: 730}}>
                                {
                                    this.state.loadingLoader ?
                                        this.getLoader() :
                                        this.getAudiences()
                                }
                            </Scrollbar>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

class Activity extends AroaParentComponent {

    render() {
        if (this.isUserLogged()) {
            return <AroaPage header={(new AroaBar(this.props).render())}
                             body={(<ActivityBody props={this.props}/>)}/>;
        } else {
            this.gotoPath('/');
            return '';
        }
    }
}

export default withStyles(styles)(Activity);
