import React                                                                   from 'react';
import styles                                                                  from '../../../components/AroaPage/aroatheme';
import {withStyles}                                                            from '@material-ui/core/styles';
import AroaParentComponent                                                     from "../../../components/AroaParentComponent";
import AroaStepper                                                             from "../../../atoms/AroaStepper";
import AroaPage                                                                from "../../../components/AroaPage";
import Grid                                                                    from "@material-ui/core/Grid";
import {Typography}                                                            from "@material-ui/core";
import {Link}                                                                  from "react-router-dom";
import sessionStorage, {__CREATE_USER_NAME_FIELD, __CREATE_USER_SURNAME_FIELD} from '../../../services/storage-manager';
import SimpleAroaBar                                                           from "../../../atoms/AroaSimpleBar";
import {SuccessIcon} from "../../../atoms/AroaIcons";
import Button from "@material-ui/core/Button";

class CreateUserStep2Body extends React.Component
{
	
	render()
	{
		const userName    = sessionStorage.getValue(__CREATE_USER_NAME_FIELD);
		const userSurname = sessionStorage.getValue(__CREATE_USER_SURNAME_FIELD);
		return (
			<Grid>
				<AroaStepper
					steps={['Create an account', 'Add users', 'Add DSP', 'Add advertiser']}
					activeState={0}
					optionals={[1]}
					completed={[0]}
				/>
				<Grid className="margin-top-50">
					<SuccessIcon classes="message-screen__icon" active={true}/>
					<Typography  className="margin-top-30 welcome-title" >
						Welcome {userName} {userSurname}!
					</Typography>
					<Typography  className="margin-top-30 welcome-text" >
						Lest's continue configuring your account,
						now you can either create a learn or start by configuring your DSP's list.
						What do you want to do?
					</Typography>
					<Grid container className="margin-top-20" justify={'center'} direction={'row'}>
						<Grid className="margin-5" >
							<Link to={"/createuser/dsp/step1"}>
								<Button  aria-label="Skip step" className="button--blue--border">
									SKIP THIS STEP
								</Button>
							</Link>
						</Grid>
						<Grid className="margin-5">
							<Link to={"/createuser/step3"}>
								<Button  aria-label="Add users" className="button-signup-login ">
									ADD USERS
								</Button>
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)
	}
}

class CreateUserStep2 extends AroaParentComponent
{
	
	render()
	{
		if(this.isUserLogged()){
			return <AroaPage header={(new SimpleAroaBar(this.props).render())}
							 body={(new CreateUserStep2Body(this.props).render())}/>;
		}else{
			this.gotoPath('/');
			return '';
		}
	}
}

export default withStyles(styles)(CreateUserStep2);
