import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
// We can inject some CSS into the DOM.
const styles = {
  root: {
    backgroundColor: "#229922",
    borderRadius: 3,
    border: 0,
    padding: "0 30px",
  },
};

let openSnackbarFn;

const closeDuration = 5000;

class Notifier extends React.Component {
  state = {
    open: false,
    message: "",
  };

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  handleSnackbarClose = () => {
    this.setState({
      open: false,
      message: "",
    });
  };

  openSnackbar = ({ message }) => {
    this.setState({ message });
    this.setState({ open: true });
  };

  render() {
    const { open } = this.state;
    const { classes, className } = this.props;
    const message = (
      <span
        id="snackbar-message-id"
        dangerouslySetInnerHTML={{ __html: this.state.message }}
      />
    );

    return (
      <Snackbar
        className={clsx(classes, className)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={message}
        autoHideDuration={closeDuration}
        onClose={this.handleSnackbarClose}
        open={open}
        ContentProps={{
          "aria-describedby": "alert message",
        }}
      />
    );
  }
}

export function showNotify({ message }) {
  openSnackbarFn({ message });
}

export default withStyles(styles)(Notifier);
