import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Typography, withStyles } from "@material-ui/core";
import { __ACTION_BUTTON, __SECONDARY_BUTTON } from "../../atoms/ButtonsStyles";
import AroaActivityAudienceDetails from "../AroaActivityAudienceDetails";
import Paper from "@material-ui/core/Paper";
import { PauseIcon, PlayIcon } from "../AroaIcons";
import AroaActivityAudienceGraphic from "../../components/AroaActivityAudienceGraphic";
import IconButton from "@material-ui/core/IconButton";
import xhrHandler from "../../services/xhr-handler";
import {
  __ACTIVITY_RESUME,
  __ACTIVITY_STOP,
  __REPURCHASE_AUDIENCE,
} from "../../services/endpoint-manager";
import { showNotify } from "../AroaNotifier";
import { format } from "date-fns";
import CircularProgress from "@material-ui/core/CircularProgress";
import AroaActivityCalendarDialog from "../AroaActivityCalendarDialog";
import sessionManager, {
  __AUDIENCENAME_ON_ACTIVITY_BUYAGAIN_,
  __CURRENT_TRANSACTION,
  __SELECTED_DATABUYER,
} from "../../services/storage-manager";
import AroaParentComponent from "../../components/AroaParentComponent";
import { setCartItemsCount } from "../../redux/actions";
import { connect } from "react-redux";
import AroaActivityAudienceNameDialog from "../AroaActivityAudienceNameDialog";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

const ColorButton = withStyles(() => __ACTION_BUTTON)(Button);
const SecondaryButton = withStyles(() => __SECONDARY_BUTTON)(Button);

class AroaActivityAudienceRow extends AroaParentComponent {
  constructor(props) {
    super(props);
    this.state = {
      detailIsOpen: false,
      loader: false,
      renderCalendar: false,
      openAudienceNameDialog: false,
    };
    this.pauseActivity = this.pauseActivity.bind(this);
    this.resumeActivity = this.resumeActivity.bind(this);
    this.renderCalendarDialog = this.renderCalendarDialog.bind(this);
    this.buyAgain = this.buyAgain.bind(this);
    this.openAudienceNameDialog = this.openAudienceNameDialog.bind(this);
  }

  detailPanelHandler(isOpen) {
    this.setState({ detailIsOpen: isOpen });
  }

  duplicateAudience() {
    const { audience } = this.props;
    const expirationDate = audience.expirationdate.split(' ')[0]
    const audienceDetails = `Name: ${audience.audiencenaming}
Advertiser: ${audience.advertiser.advertisername}
Cookies: ${audience.totalcookies}
Expiration: ${expirationDate}`
    navigator.clipboard.writeText(audienceDetails)
    showNotify({message: 'Copied!'});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.audience !== prevProps.audience) {
      this.setState({ loader: false });
    }
  }

  renderButtonOn() {
    return (
      <ColorButton onClick={() => this.detailPanelHandler(false)}>
        HIDE DETAILS
      </ColorButton>
    );
  }

  renderButtonOff() {
    return (
      <SecondaryButton onClick={() => this.detailPanelHandler(true)}>
        DETAILS
      </SecondaryButton>
    );
  }

  renderPanelOn(audience) {
    return (
      <AroaActivityAudienceDetails
        audience={audience}
        onButtonClick={() => this.detailPanelHandler(false)}
      />
    );
  }

  renderStateButton(stateId) {
    if (this.isActivatedState(stateId)) {
      return (
        <IconButton
          className="icon-button"
          size={"small"}
          onClick={() => this.pauseActivity()}
        >
          <PauseIcon classes="auto-space" />
        </IconButton>
      );
    } else if (this.isPauseState(stateId)) {
      return (
        <IconButton
          className="icon-button"
          size={"small"}
          onClick={() => this.resumeActivity()}
        >
          <PlayIcon classes="auto-space" />
        </IconButton>
      );
    } else if (this.isExpiredState(stateId)) {
      return (
        <IconButton
          className="icon-button"
          size={"small"}
          onClick={() => this.setState({ renderCalendar: true })}
        >
          <PlayIcon classes="auto-space" />
        </IconButton>
      );
    }
  }

  renderCalendarDialog() {
    return (
      <AroaActivityCalendarDialog
        open={true}
        onSelectExpirationDate={this.resumeActivity}
      />
    );
  }

  pauseActivity() {
    this.setState({ loader: true });
    let axiosHandler = new xhrHandler();
    axiosHandler.launchPost(
      __ACTIVITY_STOP,
      {
        transactionID: this.props.audience.transactionid,
        audienceID: this.props.audience.audienceid,
      },
      (response) => {
        this.props.onStateChange();
      },
      (error) => {
        this.setState({ loader: false });
        showNotify({
          message: this.getXHRHandler().getErrorDescription(error),
        });
      }
    );
  }

  resumeActivity(expirationDate) {
    this.setState({ loader: true });
    if (expirationDate) this.setState({ renderCalendar: false });
    let data = expirationDate
      ? {
          transactionID: this.props.audience.transactionid,
          audienceID: this.props.audience.audienceid,
          expirationDate: expirationDate,
        }
      : {
          transactionID: this.props.audience.transactionid,
          audienceID: this.props.audience.audienceid,
        };

    let axiosHandler = new xhrHandler();
    axiosHandler.launchPost(
      __ACTIVITY_RESUME,
      data,
      (response) => {
        this.props.onStateChange();
      },
      (error) => {
        this.setState({ loader: false });
        showNotify({ message: axiosHandler.getErrorDescription(error) });
      }
    );
  }

  isPauseState = (stateId) => {
    return stateId === "9";
  };

  isActivatedState = (stateId) => {
    return stateId === "4";
  };

  isExpiredState = (stateId) => {
    return stateId === "6";
  };

  buyAgain(audience) {
    const actualDatabuyer = sessionManager.getValue(__SELECTED_DATABUYER);

    if (!actualDatabuyer)
      showNotify({
        message: "Please select first a databuyer in your profile",
      });
    else {
      const data = {
        audienceID: audience.audienceid,
        dataBuyerID: sessionManager.getValue(__SELECTED_DATABUYER),
        audienceName: sessionManager.getValue(
          __AUDIENCENAME_ON_ACTIVITY_BUYAGAIN_
        ),
      };

      if (sessionManager.keyExists(__CURRENT_TRANSACTION)) {
        data.transactionID = sessionManager.getValue(__CURRENT_TRANSACTION);
      } else {
        this.props.setCartItemsCount(0);
      }

      try {
        this.getXHRHandler().launchPost(
          __REPURCHASE_AUDIENCE,
          data,
          (response) => {
            const transactionData = this.getXHRHandler().getSuccessData(
              response
            );
            sessionManager.setValue(
              __CURRENT_TRANSACTION,
              transactionData.transactionID
            );
            this.props.setCartItemsCount(transactionData.audiences.length);
            sessionManager.clearSuccessActivityBuyAgainInfo();
            showNotify({
              message:
                "if you wish you can check your repurchased audience on your cart",
            });
          },
          (error) => {
            showNotify({
              message: this.getXHRHandler().getErrorDescription(error),
            });
          }
        );
      } catch (e) {
        //showNotify({message: 'There is no segments on this audience!'});
        console.info("there is no segments on this audience yet");
      }
    }
  }

  openAudienceNameDialog() {
    this.setState({ openAudienceNameDialog: true });
  }

  closeAudienceNameDialog() {
    this.setState({ openAudienceNameDialog: false });
  }

  render() {
    const { audience } = this.props;
    let detailButton = this.state.detailIsOpen
      ? this.renderButtonOn()
      : this.renderButtonOff();
    let detailPanel = this.state.detailIsOpen
      ? this.renderPanelOn(audience)
      : null;
    let calendarDialog = this.state.renderCalendar
      ? this.renderCalendarDialog()
      : null;

    let buyButton = (
      <SecondaryButton onClick={() => this.openAudienceNameDialog(audience)}>
        Buy Again
      </SecondaryButton>
    );

    const loader = (
      <Paper spacing={2} className="activity__row activity__row--loader">
        <Grid
          container
          justify={"center"}
          alignItems={"center"}
          className="height--full"
        >
          <CircularProgress />
        </Grid>
      </Paper>
    );

    if (this.state.loader) return loader;

    return (
      <Paper spacing={2} className="activity__row">
        <Grid className="activity__header flex flex--spaced">
          <Grid className="activity__header__details flex flex--evenly flex--top flex--column">
            <Grid>
              <Typography
                variant={"body2"}
                display={"inline"}
                style={{ marginRight: 10 }}
              >
                AUDIENCE ID
              </Typography>
              <Typography
                variant={"body2"}
                display={"inline"}
                style={{ fontWeight: "bold" }}
              >
                {audience.audiencenaming}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant={"body2"}
                display={"inline"}
                style={{ marginRight: 10 }}
              >
                DATE RANGE
              </Typography>
              <Typography
                variant={"body2"}
                display={"inline"}
                className="title--date"
              >
                {format(new Date(audience.creationdate), "yyyy-MM-dd")} -{" "}
                {format(new Date(audience.expirationdate), "yyyy-MM-dd")}
              </Typography>
            </Grid>
          </Grid>
          <Grid className="flex flex--column flex--centered">
            <IconButton
              className="icon-button"
              size={"small"}
              onClick={() => this.duplicateAudience()}
              style={{ color: "white" }}
            >
              <FileCopyOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid className="activity__body flex flex--column">
          <Grid container alignItems={"center"}>
            <Grid item xs={3} className="flex flex--centered">
              <Paper className="card" elevation={0}>
                <Grid className="flex flex--column flex--top flex--evenly">
                  <Grid>
                    <Typography
                      variant={"body2"}
                      className={"title"}
                      display={"inline"}
                    >
                      LAST UPDATED COOKIES
                    </Typography>
                    <Typography variant={"body2"} display={"inline"}>
                      {audience.lastcookies ? audience.lastcookies : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      variant={"body2"}
                      className={"title"}
                      display={"inline"}
                    >
                      LAST UPDATED DATE
                    </Typography>
                    <Typography variant={"body2"} display={"inline"}>
                      {audience.lastupdateddate
                        ? format(
                            new Date(audience.lastupdateddate),
                            "yyyy-MM-dd"
                          )
                        : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      variant={"body2"}
                      className={"title"}
                      display={"inline"}
                    >
                      TOTAL COOKIES
                    </Typography>
                    <Typography variant={"body2"} display={"inline"}>
                      {audience.totalcookies ? audience.totalcookies : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      variant={"body2"}
                      className={"title"}
                      display={"inline"}
                    >
                      REMAINING DAYS
                    </Typography>
                    <Typography variant={"body2"} display={"inline"}>
                      {audience.remainingdays} Days
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3} className="flex flex--centered">
              <Paper className="card" elevation={0}>
                <Grid className="flex flex--column flex--top flex--evenly">
                  <Grid>
                    <Typography
                      variant={"body2"}
                      className={"title"}
                      display={"inline"}
                    >
                      EXPIRATION DATE
                    </Typography>
                    <Typography variant={"body2"} display={"inline"}>
                      {audience.expirationdate
                        ? format(
                            new Date(audience.expirationdate),
                            "yyyy-MM-dd"
                          )
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      variant={"body2"}
                      className={"title"}
                      display={"inline"}
                    >
                      DSP
                    </Typography>
                    <Typography variant={"body2"} display={"inline"}>
                      {audience.dsp.seatname}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      variant={"body2"}
                      className={"title"}
                      display={"inline"}
                    >
                      ADVERTISER
                    </Typography>
                    <Typography variant={"body2"} display={"inline"}>
                      {audience.advertiser.advertisername}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3} className="flex flex--centered">
              <Paper className="card" elevation={0}>
                <AroaActivityAudienceGraphic audienceID={audience.audienceid} />
              </Paper>
            </Grid>
            <Grid item xs={3} className="activity__body__buttons">
              {buyButton}
              {detailButton}
              {this.state.openAudienceNameDialog ? (
                <AroaActivityAudienceNameDialog
                  cancel={true}
                  accept={true}
                  acceptHandler={() => this.buyAgain(audience)}
                  cancelHandler={() => this.closeAudienceNameDialog()}
                  open={this.state.openAudienceNameDialog}
                />
              ) : null}
            </Grid>
          </Grid>
          {detailPanel}
        </Grid>
        <Grid
          className={
            "flex flex--aligned flex--spaced activity__footer activity__footer--" +
            audience.audiencestate.stateid
          }
        >
          <Typography variant={"body2"}>
            {audience.audiencestate.statename}
          </Typography>
          {this.renderStateButton(audience.audiencestate.stateid)}
        </Grid>
        {calendarDialog}
      </Paper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCartItemsCount: (cartItemCount) => {
      dispatch(setCartItemsCount(cartItemCount));
    },
  };
};
export default connect(null, mapDispatchToProps)(AroaActivityAudienceRow);
