import React               from 'react';
import styles              from '../../components/AroaPage/aroatheme';
import {withStyles}        from '@material-ui/core/styles';
import AroaParentComponent from "../../components/AroaParentComponent";
import AroaBar             from "../../components/AroaBar";
import AroaPage            from "../../components/AroaPage";
import Grid                from "@material-ui/core/Grid";
import AroaMessageScreen   from "../../atoms/AroaMessageScreen";
import {SuccessIcon}       from "../../atoms/AroaIcons";

class PasswordMailSentBody extends React.Component
{
	handleClick()
	{
		window.location.href = '/';
	}
	
	render()
	{
		return (
			<Grid>
				<AroaMessageScreen
					buttonText="Back"
					showButton={true}
					linkTo="/"
					icon={<SuccessIcon classes="message-screen__icon" active={true}/>}
					title="Success!"
					message={"Please check your mail inbox and follow the instructions"}
					onButtonClick={this.handleClick}
				/>
			</Grid>
		)
	}
}

class PasswordMailSent extends AroaParentComponent
{
	render()
	{
		let props = {
			about   : false,
			platform: false,
			login   : false
		};
		return (
			
			<AroaPage header={(new AroaBar({...this.props, ...props}).render())}
					  body={(new PasswordMailSentBody(this.props).render())}/>
		)
	}
}

export default withStyles(styles)(PasswordMailSent);
