import { Grid } from "@material-ui/core";
import React from "react";
import AroaParentComponent from "../AroaParentComponent";

import CostUploaderRow from "../../atoms/CostUploaderRow";

class CostsUploaderData extends AroaParentComponent {
  render() {
    const { data, onAudienceChange } = this.props;

    return (
      <Grid className="margin-top-10">
        {Object.entries(data.dataset).map(([audKey, audArray]) => (
          <CostUploaderRow
            key={audKey}
            checkedAudiences={data.checkedAudiences}
            onCheckChange={onAudienceChange}
            audKey={audKey}
            audArray={audArray}
          ></CostUploaderRow>
        ))}
      </Grid>
    );
  }
}

export default CostsUploaderData;
