import { connect } from 'react-redux';
import {setSearchLoading, setSearchResults, setSearchTerm} from "../../actions";

const getSearchTerm = (searchTerms) => {
    return searchTerms;
};

const getSearchResults = (searchResults) => {
    return searchResults;
};

const getSearchLoading = (searchLoading) => {
    return searchLoading;
};

const mapStateToProps = (state) => {
    return {
        searchTerms: getSearchTerm(state.searchTerms),
        searchLoading: getSearchLoading(state.searchLoading),
        searchResults: getSearchResults(state.searchResults)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSearchTerm: (searchTerms) => {
            dispatch(setSearchTerm(searchTerms))
        },
        setSearchLoading: (searchLoading) => {
            dispatch(setSearchLoading(searchLoading))
        },
        setSearchResults: (searchResults) => {
            dispatch(setSearchResults(searchResults))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps);
