import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { showNotify } from "../../atoms/AroaNotifier";
import { __ADD_ADVERTISERS_LIST } from "../../services/endpoint-manager";
import xhrHandler from "../../services/xhr-handler";

class AroaCartAudienceAttributeRow extends React.Component {
  constructor(props) {
    super(props);
    this.handlePlatformChange = this.handlePlatformChange.bind(this);
    this.handleAdvertiserChange = this.handleAdvertiserChange.bind(this);
    this.handleDeviceChange = this.handleDeviceChange.bind(this);
    this.handleBillingMappingChange = this.handleBillingMappingChange.bind(this);
    let audiencesAttributes = this.props.audienceAttributes;
    let audienceAttributes = audiencesAttributes.filter(
      (audience) => audience.audienceID === this.props.audience.audienceID
    );
    if (audienceAttributes.length > 0) {
      this.state = {
        platform: audienceAttributes[0].platform
          ? audienceAttributes[0].platform
          : false,
        device: audienceAttributes[0].device
          ? audienceAttributes[0].device
          : false,
        billingMapping: audienceAttributes[0].billingMapping
          ? audienceAttributes[0].billingMapping
          : false,
        purchaseModel: 1,
        advertiser: audienceAttributes[0].advertiser
          ? audienceAttributes[0].advertiser
          : false,
        audienceId: this.props.audience.audienceID,
        platformName: audienceAttributes[0].platformName
          ? audienceAttributes[0].platformName
          : "",
        deviceName: audienceAttributes[0].deviceName
          ? audienceAttributes[0].deviceName
          : "",
        purchaseModelName: "CPM",
        advertiserName: audienceAttributes[0].advertiserName
          ? audienceAttributes[0].advertiserName
          : "",
        advertiserList: [],
      };

      if (audienceAttributes[0].platform)
        this.getAdvertiserList(audienceAttributes[0].platform);
    } else {
      this.state = {
        platform: false,
        device: false,
        purchaseModel: 1,
        advertiser: false,
        audienceId: this.props.audience.audienceID,
        advertiserList: [],
      };
    }
  }

  handlePlatformChange(event) {
    this.getAdvertiserList(event.target.value);
    let platformName = this.props.platforms.filter(
      (platform) => platform.id === event.target.value
    );

    let platform = platformName[0].name.split("/")[0].trim();
    this.setState({ showBillingMapping: platform === "Appnexus" });

    this.setState(
      {
        [event.target.name]: event.target.value,
        advertiser: false,
        platformName: platformName[0].name,
      },
      function () {
        this.props.onAttributeChange(this.state);
      }.bind(this)
    );
  }

  handleDeviceChange(event) {
    let deviceName = this.props.devices.filter(
      (device) => device.deviceID === event.target.value
    );

    this.setState(
      {
        [event.target.name]: event.target.value,
        deviceName: deviceName[0].device,
      },
      function () {
        this.props.onAttributeChange(this.state);
      }.bind(this)
    );
  }

  handleAdvertiserChange(event) {
    let advertiserName = this.state.advertiserList.filter(
      (advertiser) => advertiser.advertiserid === event.target.value
    );

    if (advertiserName.length !== 0) {
      this.setState(
        {
          [event.target.name]: event.target.value,
          advertiserName: advertiserName[0].advertisername,
        },
        function () {
          this.props.onAttributeChange(this.state);
        }.bind(this)
      );
    } else {
      showNotify({ message: "No exist advertisers to this DSP" });
    }
  }

  handleBillingMappingChange(event) {
    this.setState(
      {
        [event.target.name]: event.target.value,
        billingMapping: event.target.value,
      },
      function () {
        this.props.onAttributeChange(this.state);
      }.bind(this)
    );
  }

  getAdvertiserList(platform) {
    let _self = this;
    let axiosHandler = new xhrHandler();
    axiosHandler.launchPost(
      __ADD_ADVERTISERS_LIST,
      { dspID: parseInt(platform) },
      (response) => {
        _self.setState({
          advertiserList: axiosHandler.getSuccessData(response),
        });
      },
      (error) => {
        console.log(axiosHandler.getErrorDescription(error));
        _self.setState({ advertiserList: [] });
      }
    );
  }

  render() {
    const {
      audience,
      platforms,
      devices,
      purchases,
      billingMappings,
    } = this.props;

    return (
      <TableRow style={{ height: 100 }}>
        <TableCell className="aroaColor">{audience.audienceName}</TableCell>
        <TableCell>
          <FormControl variant="outlined">
            <Select
              value={this.state.platform}
              input={<OutlinedInput labelWidth={0} />}
              onChange={this.handlePlatformChange}
              name="platform"
            >
              <MenuItem disabled value={false}>
                Platform
              </MenuItem>
              {platforms.map((platform) => (
                <MenuItem value={platform.id} key={platform.id}>
                  {platform.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        {this.state.showBillingMapping ? (
          <TableCell>
            <FormControl variant="outlined">
              <Select
                value={this.state.billingMapping}
                input={<OutlinedInput labelWidth={0} />}
                onChange={this.handleBillingMappingChange}
                name="billingMapping"
              >
                <MenuItem disabled value={false}>
                  BillingMapping
                </MenuItem>
                {billingMappings.map((mapping) => (
                  <MenuItem value={mapping.categoryID} key={mapping.categoryID}>
                    {mapping.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableCell>
        ) : (
          <TableCell> </TableCell>
        )}
        <TableCell>
          <FormControl variant="outlined">
            <Select
              value={this.state.device}
              input={<OutlinedInput labelWidth={0} />}
              onChange={this.handleDeviceChange}
              name="device"
            >
              <MenuItem disabled value={false}>
                Device
              </MenuItem>
              {devices.map((device) => (
                <MenuItem value={device.deviceID} key={device.deviceID}>
                  {device.device}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <FormControl variant="outlined" disabled>
            <Select
              value={this.state.purchaseModel}
              input={<OutlinedInput labelWidth={0} />}
              name="purchaseModel"
            >
              {purchases.length > 0 ? (
                <MenuItem value={purchases[0].purchaseModelID}>
                  {purchases[0].purchaseModel}
                </MenuItem>
              ) : (
                <MenuItem value={false}>CPM</MenuItem>
              )}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <FormControl variant="outlined">
            <Select
              value={this.state.advertiser}
              input={<OutlinedInput labelWidth={0} />}
              onChange={this.handleAdvertiserChange}
              name="advertiser"
            >
              <MenuItem disabled value={false}>
                Advertiser
              </MenuItem>
              {this.state.advertiserList.map((advertiser) => (
                <MenuItem
                  value={advertiser.advertiserid}
                  key={advertiser.advertiserid}
                >
                  {advertiser.advertisername}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
    );
  }
}

export default AroaCartAudienceAttributeRow;
