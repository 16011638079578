import React from "react";
import { Polar } from "react-chartjs-2";

class DataPartnerLiveGraph extends React.Component {
  transformData = (data, group) => {
    const colors = [
      "rgb(255, 99, 132)",
      "rgb(75, 192, 192)",
      "rgb(255, 205, 86)",
      "rgb(201, 203, 207)",
      "rgb(54, 162, 235)",
    ];
    if (!data) return [];

    const ret = {
      labels: [],
      datasets: [{ label: "Data", data: [], backgroundColor: [] }],
    };

    Object.values(data).forEach((item, index) => {
      ret.labels.push(item[group]);
      ret.datasets[0].data.push(item.vendorRev);
      ret.datasets[0].backgroundColor.push(colors[index % 6]);
    });
    return ret;
  };

  render() {
    const { data, group } = this.props;
    return <Polar data={this.transformData(data, group)}></Polar>;
  }
}

export default DataPartnerLiveGraph;
