import {
	ATTRIBUTE_CHANGE,
	CHANGE_CART_STEP,
	DELETE_AUDIENCE,
	SET_AUDIENCES,
	SET_CART_ITEMS_COUNT,
	SET_SEARCH_LOADING,
	SET_SEARCH_RESULTS,
	SET_SEARCH_TERM,
	SET_TAXONOMY_INFO
} from "../constants/action-types";

export function attributeChange(payload)
{
	return {type: ATTRIBUTE_CHANGE, payload}
}

export function setAudiences(payload)
{
	return {type: SET_AUDIENCES, payload}
}

export function deleteAudience(payload)
{
	return {type: DELETE_AUDIENCE, payload}
}

export function changeCartStep(payload)
{
	return {type: CHANGE_CART_STEP, payload}
}

export function setCartItemsCount(payload)
{
	return {type: SET_CART_ITEMS_COUNT, payload}
}

export function setSearchTerm(payload)
{
	return {type: SET_SEARCH_TERM, payload}
}

export function setSearchResults(payload)
{
	return {type: SET_SEARCH_RESULTS, payload}
}

export function setSearchLoading(payload)
{
	return {type: SET_SEARCH_LOADING, payload}
}

export function setTaxonomyInfo(payload)
{
	return {type: SET_TAXONOMY_INFO, payload}
}
